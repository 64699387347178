import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

export default function DateSelect({
  name = "",
  label = "",
  value = "",
  placeholder = "",
  disabled = false,
  onChange = () => {},
  className = "",
  time = false,
  timeOnly = false,
}) {

  const currentYear = (new Date()).getFullYear();
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

  const years = range(currentYear + 6, currentYear - 12, -1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  
  return (
    <div className={className ? className : ""}>
      <label
        htmlFor={name}
        className={`block text-sm font-medium text-gray-700`}
      >
        {label}
      </label>
      <div className="mt-1">
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {"<"}
              </button>
              <select
                value={(new Date(date)).getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
    
              <select
                value={months[(new Date(date)).getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
    
              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {">"}
              </button>
            </div>
          )}
          disabled={disabled}
          placeholderText={placeholder}
          onChange={onChange}
          selected={value ? new Date(value) : ""}
          showPopperArrow={false}
          shouldCloseOnSelect={true}
          dateFormat={`${!timeOnly ? "dd/MM/yyyy" : ""} ${time || timeOnly ? "h:mm a" : ""}`}
          peekNextMonth
          showTimeSelect={time || timeOnly}
          showTimeSelectOnly={timeOnly}
          className={`p-2 shadow-sm block w-full sm:text-sm rounded-md bg-gray-200`}
        />
      </div>
    </div>
  );
}

DateSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
