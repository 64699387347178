import React from "react";
import { Switch, Route } from "react-router-dom";
import view from "./view";
import add from "./add";
import addTask from "./addTask";
import list from "./list";
import edit from "./forms/edit";
import NotFound from "../../components/NotFound";
import { Navbar } from "./_components";
import MatterProvider from "../../contexts/matter_context/Matter_Provider";
import api from "../../api";
import TaskRoutes from "./tasks";
import DebtorRoutes from "./debtors";
import DebtsRoutes from "./debts";
import FeesRoutes from "./fees";
import ActivityRoutes from "./activities";
import WorkflowPage from "./workflow";
import shortcodes from "./shortcodes";

const routePrefix = "/matters";

export default function MatterPages() {
  return (
    <Switch>
      <Route exact path={`${routePrefix}`} component={list} />
      <Route path={`${routePrefix}/:matter_id`} component={ViewMatterRoutes} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

function ViewMatterRoutes({ history, match }) {
  return (
    <>
      <Navbar history={history} match={match} />
      <Switch>
        <Route path={`${routePrefix}/add`} component={add} />
        <Route path={`${routePrefix}/addTask`} component={addTask} />
        <MatterProvider api={api}>
          <Route
            path={`${routePrefix}/:matter_id/debtors`}
            component={DebtorRoutes}
          />
          <Route
            path={`${routePrefix}/:matter_id/debts`}
            component={DebtsRoutes}
          />
          <Route
            path={`${routePrefix}/:matter_id/fees`}
            component={FeesRoutes}
          />
          <Route
            path={`${routePrefix}/:matter_id/activities`}
            component={ActivityRoutes}
          />
          <Route
            path={`${routePrefix}/:matter_id/tasks`}
            component={TaskRoutes}
          />
          <Route
            exact
            path={`${routePrefix}/:matter_id/workflow`}
            component={WorkflowPage}
          />
          <Route
            exact
            path={`${routePrefix}/:matter_id/edit`}
            component={edit}
          />
          <Route
            exact
            path={`${routePrefix}/:matter_id/shortcodes`}
            component={shortcodes}
          />
          <Route exact path={`${routePrefix}/:matter_id`} component={view} />
        </MatterProvider>

        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
}
