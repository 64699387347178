import toast from "react-hot-toast";
import dayjs from "dayjs";

import styled, { createGlobalStyle } from 'styled-components';

class CommonFunctions {
  handleError(error) {
    toast.error(
      error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : "An unexpected error has occured.",
      {}
    );
  }

  toFormattedFixed(value, fixed = 2) {
    return value ? value.toLocaleString('en-GB', {
      minimumFractionDigits: fixed,
      maximumFractionDigits: fixed
    }) : 0;
  }

  calculateDebt(interestRate, transactionHistory = []) {
    let debt = [];

    for (let index = 0; index < transactionHistory.length; index++) {
      const item = transactionHistory[index];
      const startDate = index === 0 ? dayjs(item.dateStarting).toDate() : dayjs(item.dateStarting).add(1, "day").toDate();
      const endDate = transactionHistory[index + 1] ? dayjs(transactionHistory[index + 1].dateStarting).toDate() : dayjs().subtract(1, "day").startOf("day").toDate();

      let daysAtRate = dayjs(endDate).diff(startDate, "days");
      daysAtRate = daysAtRate + 1;

      const amountPerDay = ((interestRate / 100) * item.amount) / 365;

      debt.push(daysAtRate * amountPerDay);
    }

    return {
      debt,
      totalDebt: debt.length > 0 ? debt.reduce((a, b) => a + b) : 0,
    };
  }

  generateDailyDebtPayments__old(interestRate, transactionHistory) {
    let dailyDebtRepayments = [];

    if (!transactionHistory[0]) {
      return dailyDebtRepayments;
    }

    const startingDate = dayjs(transactionHistory[0].dateStarting);
    const endDate = dayjs();

    let currentPaymentBracket = transactionHistory[transactionHistory.length - 1];
    let nextPaymentBracket = transactionHistory[1] ? transactionHistory[1] : {};

    let paymentBracketIndex = 1;
    let index = 0;

    while (startingDate.add(index, "days").isBefore(endDate, "day")) {
      const day = startingDate.add(index, "days");

      const amount =
        ((interestRate / 100) * currentPaymentBracket.amount) / 365;

      dailyDebtRepayments.push({ day: day.format("DD-MM-YYYY"), amount });

      if (
        nextPaymentBracket &&
        dayjs(nextPaymentBracket.dateStarting).isSame(day, "day")
      ) {
        currentPaymentBracket = nextPaymentBracket;
        nextPaymentBracket = transactionHistory[paymentBracketIndex + 1];

        paymentBracketIndex = paymentBracketIndex + 1;
      }

      index++;
    }

    return dailyDebtRepayments;
  }

  generateDailyDebtPayments(interestRate, transactionHistory, debt) {
    console.log("🚀 generateDailyDebtPayments ~ debt:", debt)
    console.log("🚀 generateDailyDebtPayments ~ transactionHistory:", transactionHistory)
    let dailyDebtRepayments = [];

    if (!transactionHistory[0]) {
        return dailyDebtRepayments;
    }

    const startingDate = dayjs(transactionHistory[0].dateStarting);
    const endDate = dayjs(transactionHistory[0].endDate); // Adjust this as needed

    let outstandingAmount = transactionHistory[0].amount;
    const interestPerDay = (interestRate / 100) / 365;

    let index = 0;

    // Sort transactions by date
    transactionHistory.sort((a, b) => dayjs(a.dateStarting).isAfter(dayjs(b.dateStarting)) ? 1 : -1);

    // Initialize payment index
    let paymentIndex = 1;

    while (startingDate.add(index, "days").isBefore(endDate, "day")) {
        const day = startingDate.add(index, "days");

        // Calculate the interest accrued for the current day
        const interestAccrued = outstandingAmount * interestPerDay;
        dailyDebtRepayments.push({ day: day.format("DD-MM-YYYY"), amount: interestAccrued.toFixed(2), interestPerDay, interestRate, outstandingAmount });

        console.log(`generateDailyDebtPayments | Date: ${day.format("DD-MM-YYYY")}, Outstanding Amount: ${outstandingAmount}, Interest Accrued: ${interestAccrued}`);

        // Check if there's a payment on the current day and adjust the outstanding amount
        while (paymentIndex < transactionHistory.length && day.isSame(dayjs(transactionHistory[paymentIndex].dateStarting), 'day')) {
            // outstandingAmount -= transactionHistory[paymentIndex].amount;
            outstandingAmount = transactionHistory[paymentIndex].amount;
            // Ensure the outstanding amount never goes negative
            if (outstandingAmount < 0) {
                outstandingAmount = 0;
            }
            paymentIndex++;
        }

        index++;
    }

    return dailyDebtRepayments;
  }

  old_whitelabeling(organisation){

    const createCSS = (className, v) => {
      return `
      .text-${className} {
        color: ${v} !important;
      }
      .border-${className} {
        border-color: ${v} !important;
      }
      .bg-${className}{
        background-color: ${v} !important;
      }
      .hover\\:bg-${className}:hover{
        background-color: ${v} !important;
      }
      .hover\\:text-${className}:hover{
        color: ${v} !important;
      }

      .focus\\:ring-${className}:focus,
      .ring-${className}{
        --tw-ring-color: ${v} !important
      }


    `
    }

     //.text-textColor {
      let textColor = organisation.whitelabeling && organisation.whitelabeling.textColor ? `
      .text-gray-900, .text-gray-800, .text-gray-700, .text-gray-600  {
        color: ${organisation.whitelabeling.textColor} !important;
      }

      .text-white, .hover\\:text-white{
        color: #FFFFFF !important;
      }
    ` : '';

    let primary = organisation.whitelabeling && organisation.whitelabeling.primary ? createCSS("primary", organisation.whitelabeling.primary) : "";
    let secondary = organisation.whitelabeling && organisation.whitelabeling.secondary ? createCSS("secondary", organisation.whitelabeling.secondary) : "";
    
    let sidemenuPrimary = organisation.whitelabeling && organisation.whitelabeling.sidemenuPrimary ? createCSS("sidemenuPrimary", organisation.whitelabeling.sidemenuPrimary) : "";
    let sidemenuHover = organisation.whitelabeling && organisation.whitelabeling.sidemenuHover ? createCSS("sidemenuHover", organisation.whitelabeling.sidemenuHover) : "";
    let sidemenuActive = organisation.whitelabeling && organisation.whitelabeling.sidemenuActive ? createCSS("sidemenuActive", organisation.whitelabeling.sidemenuActive) : "";
    let sidemenuPrimaryText = organisation.whitelabeling && organisation.whitelabeling.sidemenuPrimaryText ? createCSS("sidemenuPrimaryText", organisation.whitelabeling.sidemenuPrimaryText) : "";
    let sidemenuPrimaryTitle = organisation.whitelabeling && organisation.whitelabeling.sidemenuPrimaryTitle ? createCSS("sidemenuPrimaryTitle", organisation.whitelabeling.sidemenuPrimaryTitle) : "";
    let sidemenuHoverText = organisation.whitelabeling && organisation.whitelabeling.sidemenuHoverText ? createCSS("sidemenuHoverText", organisation.whitelabeling.sidemenuHoverText) : "";

    let sidemenuBadge = organisation.whitelabeling && organisation.whitelabeling.sidemenuBadge ? createCSS("sidemenuBadge", organisation.whitelabeling.sidemenuBadge) : "";
    let sidemenuBadgeZero = organisation.whitelabeling && organisation.whitelabeling.sidemenuBadgeZero ? createCSS("sidemenuBadgeZero", organisation.whitelabeling.sidemenuBadgeZero) : "";
    let sidemenuBadgeText = organisation.whitelabeling && organisation.whitelabeling.sidemenuBadgeText ? createCSS("sidemenuBadgeText", organisation.whitelabeling.sidemenuBadgeText) : "";
    let sidemenuBadgeZeroText = organisation.whitelabeling && organisation.whitelabeling.sidemenuBadgeZeroText ? createCSS("sidemenuBadgeZeroText", organisation.whitelabeling.sidemenuBadgeZeroText) : "";

    let menuPrimary = organisation.whitelabeling && organisation.whitelabeling.menuPrimary ? createCSS("menuPrimary", organisation.whitelabeling.menuPrimary) : "";
    let menuHover = organisation.whitelabeling && organisation.whitelabeling.menuHover ? createCSS("menuHover", organisation.whitelabeling.menuHover) : "";
    let menuActive = organisation.whitelabeling && organisation.whitelabeling.menuActive ? createCSS("menuActive", organisation.whitelabeling.menuActive) : "";
    let menuPrimaryText = organisation.whitelabeling && organisation.whitelabeling.menuPrimaryText ? createCSS("menuPrimaryText", organisation.whitelabeling.menuPrimaryText) : "";
    let menuPrimaryTitle = organisation.whitelabeling && organisation.whitelabeling.menuPrimaryTitle ? createCSS("menuPrimaryTitle", organisation.whitelabeling.menuPrimaryTitle) : "";
    let menuHoverText = organisation.whitelabeling && organisation.whitelabeling.menuHoverText ? createCSS("menuHoverText", organisation.whitelabeling.menuHoverText) : "";

    let subMenuPrimary = organisation.whitelabeling && organisation.whitelabeling.subMenuPrimary ? createCSS("subMenuPrimary", organisation.whitelabeling.subMenuPrimary) : "";
    let subMenuHover = organisation.whitelabeling && organisation.whitelabeling.subMenuHover ? createCSS("subMenuHover", organisation.whitelabeling.subMenuHover) : "";
    let subMenuActive = organisation.whitelabeling && organisation.whitelabeling.subMenuActive ? createCSS("subMenuActive", organisation.whitelabeling.subMenuActive) : "";
    let subMenuPrimaryText = organisation.whitelabeling && organisation.whitelabeling.subMenuPrimaryText ? createCSS("subMenuPrimaryText", organisation.whitelabeling.subMenuPrimaryText) : "";
    let subMenuPrimaryTitle = organisation.whitelabeling && organisation.whitelabeling.subMenuPrimaryTitle ? createCSS("subMenuPrimaryTitle", organisation.whitelabeling.subMenuPrimaryTitle) : "";
    let subMenuHoverText = organisation.whitelabeling && organisation.whitelabeling.subMenuHoverText ? createCSS("subMenuHoverText", organisation.whitelabeling.subMenuHoverText) : "";

    //Buttons
    let actionButton = organisation.whitelabeling && organisation.whitelabeling.actionButton ? createCSS("action", organisation.whitelabeling.actionButton) : "";
    let actionButtonText = organisation.whitelabeling && organisation.whitelabeling.actionButtonText ? createCSS("actionText", organisation.whitelabeling.actionButtonText) : "";
    let buttonActionHover = organisation.whitelabeling && organisation.whitelabeling.actionButtonHover ? createCSS("actionHover", organisation.whitelabeling.actionButtonHover) : "";
    let actionButtonTextHover = organisation.whitelabeling && organisation.whitelabeling.actionButtonTextHover ? createCSS("actionTextHover", organisation.whitelabeling.actionButtonTextHover) : "";

    let positiveButton = organisation.whitelabeling && organisation.whitelabeling.positiveButton ? createCSS("positive", organisation.whitelabeling.positiveButton) : "";
    let positiveButtonText = organisation.whitelabeling && organisation.whitelabeling.positiveButtonText ? createCSS("positiveText", organisation.whitelabeling.positiveButtonText) : "";
    let positiveActionHover = organisation.whitelabeling && organisation.whitelabeling.positiveButtonHover ? createCSS("positiveHover", organisation.whitelabeling.positiveButtonHover) : "";
    let positiveButtonTextHover = organisation.whitelabeling && organisation.whitelabeling.positiveButtonTextHover ? createCSS("positiveTextHover", organisation.whitelabeling.positiveButtonTextHover) : "";

    let warningButton = organisation.whitelabeling && organisation.whitelabeling.warningButton ? createCSS("warning", organisation.whitelabeling.warningButton) : "";
    let warningButtonText = organisation.whitelabeling && organisation.whitelabeling.warningButtonText ? createCSS("warningText", organisation.whitelabeling.warningButtonText) : "";
    let warningActionHover = organisation.whitelabeling && organisation.whitelabeling.warningButtonHover ? createCSS("warningHover", organisation.whitelabeling.warningButtonHover) : "";
    let warningButtonTextHover = organisation.whitelabeling && organisation.whitelabeling.warningButtonTextHover ? createCSS("warningTextHover", organisation.whitelabeling.warningButtonTextHover) : "";

    let alertButton = organisation.whitelabeling && organisation.whitelabeling.alertButton ? createCSS("alert", organisation.whitelabeling.alertButton) : "";
    let alertButtonText = organisation.whitelabeling && organisation.whitelabeling.alertButtonText ? createCSS("alertText", organisation.whitelabeling.alertButtonText) : "";
    let alertActionHover = organisation.whitelabeling && organisation.whitelabeling.alertButtonHover ? createCSS("alertHover", organisation.whitelabeling.alertButtonHover) : "";
    let alertButtonTextHover = organisation.whitelabeling && organisation.whitelabeling.alertButtonTextHover ? createCSS("alertTextHover", organisation.whitelabeling.alertButtonTextHover) : "";

    let activityButton = organisation.whitelabeling && organisation.whitelabeling.activityButton ? createCSS("activity", organisation.whitelabeling.activityButton) : "";
    let activityButtonText = organisation.whitelabeling && organisation.whitelabeling.activityButtonText ? createCSS("activityText", organisation.whitelabeling.activityButtonText) : "";
    let activityActionHover = organisation.whitelabeling && organisation.whitelabeling.activityButtonHover ? createCSS("activityHover", organisation.whitelabeling.activityButtonHover) : "";
    let activityButtonTextHover = organisation.whitelabeling && organisation.whitelabeling.activityButtonTextHover ? createCSS("activityTextHover", organisation.whitelabeling.activityButtonTextHover) : "";

    //Sidebar timer buttons
    let sidebarTimerButton = organisation.whitelabeling && organisation.whitelabeling.sidebarTimerButton ? createCSS("sidebarTimer", organisation.whitelabeling.sidebarTimerButton) : "";
    let sidebarTimerButtonText = organisation.whitelabeling && organisation.whitelabeling.sidebarTimerButtonText ? createCSS("sidebarTimerText", organisation.whitelabeling.sidebarTimerButtonText) : "";
    let sidebarTimerButtonHover = organisation.whitelabeling && organisation.whitelabeling.sidebarTimerButtonHover ? createCSS("sidebarTimerHover", organisation.whitelabeling.sidebarTimerButtonHover) : "";
    let sidebarTimerButtonTextHover = organisation.whitelabeling && organisation.whitelabeling.sidebarTimerButtonTextHover ? createCSS("sidebarTimerTextHover", organisation.whitelabeling.sidebarTimerButtonTextHover) : "";

    let sidebarTimerActiveButton = organisation.whitelabeling && organisation.whitelabeling.sidebarTimerActiveButton ? createCSS("sidebarTimerActive", organisation.whitelabeling.sidebarTimerActiveButton) : "";
    let sidebarTimerActiveButtonText = organisation.whitelabeling && organisation.whitelabeling.sidebarTimerActiveButtonText ? createCSS("sidebarTimerActiveText", organisation.whitelabeling.sidebarTimerActiveButtonText) : "";
    let sidebarTimerActiveButtonHover = organisation.whitelabeling && organisation.whitelabeling.sidebarTimerActiveButtonHover ? createCSS("sidebarTimerActiveHover", organisation.whitelabeling.sidebarTimerActiveButtonHover) : "";
    let sidebarTimerActiveButtonTextHover = organisation.whitelabeling && organisation.whitelabeling.sidebarTimerActiveButtonTextHover ? createCSS("sidebarTimerActiveTextHover", organisation.whitelabeling.sidebarTimerActiveButtonTextHover) : "";
    
    let sidebarTimerViewAllButton = organisation.whitelabeling && organisation.whitelabeling.sidebarTimerViewAllButton ? createCSS("sidebarTimerViewAll", organisation.whitelabeling.sidebarTimerViewAllButton) : "";
    let sidebarTimerViewAllButtonText = organisation.whitelabeling && organisation.whitelabeling.sidebarTimerViewAllButtonText ? createCSS("sidebarTimerViewAllText", organisation.whitelabeling.sidebarTimerViewAllButtonText) : "";
    let sidebarTimerViewAllButtonHover = organisation.whitelabeling && organisation.whitelabeling.sidebarTimerViewAllButtonHover ? createCSS("sidebarTimerViewAllHover", organisation.whitelabeling.sidebarTimerViewAllButtonHover) : "";
    let sidebarTimerViewAllButtonTextHover = organisation.whitelabeling && organisation.whitelabeling.sidebarTimerViewAllButtonTextHover ? createCSS("sidebarTimerViewAllTextHover", organisation.whitelabeling.sidebarTimerViewAllButtonTextHover) : "";


      return  createGlobalStyle`
            ${textColor}
            ${primary}
            ${secondary}

            ${sidemenuPrimary}
            ${sidemenuHover}
            ${sidemenuActive}
            ${sidemenuPrimaryText}
            ${sidemenuPrimaryTitle}
            ${sidemenuHoverText}

            ${sidemenuBadge}
            ${sidemenuBadgeZero}
            ${sidemenuBadgeText}
            ${sidemenuBadgeZeroText}

            ${menuPrimary}
            ${menuHover}
            ${menuActive}
            ${menuPrimaryText}
            ${menuPrimaryTitle}
            ${menuHoverText}

            ${subMenuPrimary}
            ${subMenuHover}
            ${subMenuActive}
            ${subMenuPrimaryText}
            ${subMenuPrimaryTitle}
            ${subMenuHoverText}

            ${actionButton}
            ${actionButtonText}
            ${buttonActionHover}
            ${actionButtonTextHover}

            ${positiveButton}
            ${positiveButtonText}
            ${positiveActionHover}
            ${positiveButtonTextHover}

            ${warningButton}
            ${warningButtonText}
            ${warningActionHover}
            ${warningButtonTextHover}

            ${alertButton}
            ${alertButtonText}
            ${alertActionHover}
            ${alertButtonTextHover}

            ${activityButton}
            ${activityButtonText}
            ${activityActionHover}
            ${activityButtonTextHover}

            ${sidebarTimerButton}
            ${sidebarTimerButtonText}
            ${sidebarTimerButtonHover}
            ${sidebarTimerButtonTextHover}
            ${sidebarTimerActiveButton}
            ${sidebarTimerActiveButtonText}
            ${sidebarTimerActiveButtonHover}
            ${sidebarTimerActiveButtonTextHover}
            ${sidebarTimerViewAllButton}
            ${sidebarTimerViewAllButtonText}
            ${sidebarTimerViewAllButtonHover}
            ${sidebarTimerViewAllButtonTextHover}
          `;
  }


  whitelabeling(organisation){

    const createCSS = (className, color) => `
    .text-${className} {
      color: ${color} !important;
    }
    .border-${className} {
      border-color: ${color} !important;
    }
    .bg-${className} {
      background-color: ${color} !important;
    }
    .hover\\:bg-${className}:hover {
      background-color: ${color} !important;
    }
    .hover\\:text-${className}:hover {
      color: ${color} !important;
    }
    .focus\\:ring-${className}:focus,
    .ring-${className}{
      --tw-ring-color: ${color} !important;
    }
    `;

    const generateStyles = (organisation, styleMap) => {
      if (!organisation.whitelabeling) return '';
    
      return Object.entries(styleMap)
        .map(([key, className]) => organisation.whitelabeling[key] ? createCSS(className, organisation.whitelabeling[key]) : '')
        .join('');
    };

    const old_generateStyles = (organisation) => {
      if (!organisation.whitelabeling) return '';

      const whitelabeling = organisation.whitelabeling;
      const styleKeys = [
        'primary', 'secondary', 'sidemenuPrimary', 'sidemenuHover', 'sidemenuActive',
        'sidemenuPrimaryText', 'sidemenuPrimaryTitle', 'sidemenuHoverText', 'sidemenuBadge',
        'sidemenuBadgeZero', 'sidemenuBadgeText', 'sidemenuBadgeZeroText', 'menuPrimary', 'menuHover',
        'menuActive', 'menuPrimaryText', 'menuPrimaryTitle', 'menuHoverText', 'subMenuPrimary',
        'subMenuHover', 'subMenuActive', 'subMenuPrimaryText', 'subMenuPrimaryTitle', 'subMenuHoverText',
        'actionButton', 'actionButtonText', 'actionButtonHover', 'actionButtonTextHover', 'positiveButton',
        'positiveButtonText', 'positiveButtonHover', 'positiveButtonTextHover', 'warningButton',
        'warningButtonText', 'warningButtonHover', 'warningButtonTextHover', 'alertButton', 'alertButtonText',
        'alertButtonHover', 'alertButtonTextHover', 'activityButton', 'activityButtonText', 'activityButtonHover',
        'activityButtonTextHover', 'sidebarTimerButton', 'sidebarTimerButtonText', 'sidebarTimerButtonHover',
        'sidebarTimerButtonTextHover', 'sidebarTimerActiveButton', 'sidebarTimerActiveButtonText',
        'sidebarTimerActiveButtonHover', 'sidebarTimerActiveButtonTextHover', 'sidebarTimerViewAllButton',
        'sidebarTimerViewAllButtonText', 'sidebarTimerViewAllButtonHover', 'sidebarTimerViewAllButtonTextHover'
      ];

      

      return styleKeys
        .map(key => whitelabeling[key] ? createCSS(key, whitelabeling[key]) : '')
        .join('');
    };

    const generateTextColorStyles = (textColor) => textColor ? `
      .text-gray-900, .text-gray-800, .text-gray-700, .text-gray-600 {
        color: ${textColor} !important;
      }

      .text-white, .hover\\:text-white:hover{
        color: #FFFFFF !important;
      }
    ` : '';

    const styleMap = {
      primary: 'primary',
      secondary: 'secondary',
      sidemenuPrimary: 'sidemenuPrimary',
      sidemenuHover: 'sidemenuHover',
      sidemenuActive: 'sidemenuActive',
      sidemenuPrimaryText: 'sidemenuPrimaryText',
      sidemenuPrimaryTitle: 'sidemenuPrimaryTitle',
      sidemenuHoverText: 'sidemenuHoverText',
      sidemenuBadge: 'sidemenuBadge',
      sidemenuBadgeZero: 'sidemenuBadgeZero',
      sidemenuBadgeText: 'sidemenuBadgeText',
      sidemenuBadgeZeroText: 'sidemenuBadgeZeroText',
      menuPrimary: 'menuPrimary',
      menuHover: 'menuHover',
      menuActive: 'menuActive',
      menuPrimaryText: 'menuPrimaryText',
      menuPrimaryTitle: 'menuPrimaryTitle',
      menuHoverText: 'menuHoverText',
      subMenuPrimary: 'subMenuPrimary',
      subMenuHover: 'subMenuHover',
      subMenuActive: 'subMenuActive',
      subMenuPrimaryText: 'subMenuPrimaryText',
      subMenuPrimaryTitle: 'subMenuPrimaryTitle',
      subMenuHoverText: 'subMenuHoverText',
      actionButton: 'action',
      actionButtonText: 'actionText',
      actionButtonHover: 'actionHover',
      actionButtonTextHover: 'actionTextHover',
      positiveButton: 'positive',
      positiveButtonText: 'positiveText',
      positiveButtonHover: 'positiveHover',
      positiveButtonTextHover: 'positiveTextHover',
      warningButton: 'warning',
      warningButtonText: 'warningText',
      warningButtonHover: 'warningHover',
      warningButtonTextHover: 'warningTextHover',
      alertButton: 'alert',
      alertButtonText: 'alertText',
      alertButtonHover: 'alertHover',
      alertButtonTextHover: 'alertTextHover',
      activityButton: 'activity',
      activityButtonText: 'activityText',
      activityButtonHover: 'activityHover',
      activityButtonTextHover: 'activityTextHover',
      sidebarTimerButton: 'sidebarTimer',
      sidebarTimerButtonText: 'sidebarTimerText',
      sidebarTimerButtonHover: 'sidebarTimerHover',
      sidebarTimerButtonTextHover: 'sidebarTimerTextHover',
      sidebarTimerActiveButton: 'sidebarTimerActive',
      sidebarTimerActiveButtonText: 'sidebarTimerActiveText',
      sidebarTimerActiveButtonHover: 'sidebarTimerActiveHover',
      sidebarTimerActiveButtonTextHover: 'sidebarTimerActiveTextHover',
      sidebarTimerViewAllButton: 'sidebarTimerViewAll',
      sidebarTimerViewAllButtonText: 'sidebarTimerViewAllText',
      sidebarTimerViewAllButtonHover: 'sidebarTimerViewAllHover',
      sidebarTimerViewAllButtonTextHover: 'sidebarTimerViewAllTextHover',
    }

    const styles = generateStyles(organisation, styleMap);
    const textColorStyles = generateTextColorStyles(organisation.whitelabeling?.textColor);

    return createGlobalStyle`
        ${textColorStyles}
        ${styles}
      `;

  }

}

export default new CommonFunctions();
