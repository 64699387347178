import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import { Button} from "../../../../components/Forms";
import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import Segment from "../../../../components/Segment";

export default function AddGroup({ history }) {

  const params = useParams();

  const [data, setData] = useState({});
  // const [itemData, setItemData] = useState(initialState);

  const [editing, setEditing] = useState(false);
  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mount && params.id) {
      setMount(false);
      setLoading(true);
      setEditing(true);

      API.settings
        .customFieldGroups()
        .get(params.id)
        .then((res) =>
          setData({
            name: res.name,
            shortcode: res.shortcode,
            description: res.description,
          })
        )
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setLoading(false));
    }
  }, [mount, loading]);

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Segment>
      <div>
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Matter Group Settings
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Enter Name of matter group
              </p>
            </div>
          </div>
          <div class="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div class="shadow sm:overflow-hidden sm:rounded-md">
                <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Template
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={data.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="description"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={data.description}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Shortcode
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="shortcode"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={data.shortcode}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <Button
                    className={`inline-flex justify-center rounded-md border border-transparent ${editing ? "bg-orange-600 hover:bg-orange-700 focus:ring-orange-500" : "bg-green-600 hover:bg-green-700 focus:ring-green-500"} py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                    icon={editing ? PencilIcon : PlusIcon}
                    label={editing ? "Save" : "Add"}
                    colour={editing ? "warning" : "positive"}
                    onClick={() => {
                      let sendData = {
                        name: data.name,
                        description: data.description,
                        shortcode: data.shortcode,
                        org_id: params.org_id ? params.org_id : undefined,
                      };

                      if (editing) {
                        API.settings
                          .customFieldGroups()
                          .edit(params.id, sendData)
                          .then((res) =>
                            history.push(params.org_id ? `/admin/organisations/${params.org_id}/matter_custom_fields/groups` : "/settings/matter_custom_fields/groups")
                          )
                          .catch((e) => CommonFunctions.handleError(e));
                      } else {
                        API.settings
                          .customFieldGroups()
                          .add(sendData)
                          .then((res) =>
                            history.push(params.org_id ? `/admin/organisations/${params.org_id}/matter_custom_fields/groups` : "/settings/matter_custom_fields/groups")
                          )
                          .catch((e) => CommonFunctions.handleError(e));
                      }
                    }}
                  />
                </div>
            </form>
          </div>
        </div>
      </div>
    </Segment>
  );
}
