import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Segment from "../../../../components/Segment";
import {
  Button,
  Group,
  TextArea,
  Dropdown,
  DateSelect,
  Input,
  Toggle,
} from "../../../../components/Forms";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import Loader from "../../../../components/Loader";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import MatterBreadcrumbs from "../../_components/MatterBreadcrumbs";

export default function Edit() {
  const history = useHistory();
  const params = useParams();

  const [userList, setUserList] = useState([]);
  const [matter, setMatter] = useState(null);
  const [data, setData] = useState({
    description: "",
    assigned: "",
    due_date: "",
  });

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {

      if(!matter){
        API.matters.get(params.matter_id).then((res) => {
          setMatter(res);
        })
      }

      Promise.all([
        API.users.getAll().catch((e) => CommonFunctions.handleError(e)),
        API.matters.tasks
          .get(params.matter_id, params.task_id)
          .catch((e) => CommonFunctions.handleError(e)),
      ])
        .then((res) => {
          if (res[0]) {
            setUserList(
              res[0].users.map((a) => {
                return {
                  text: a.name,
                  value: a._id,
                };
              })
            );
          }

          if (res[1]) {
            setData(res[1]);
          }
        })
        .finally(() => setMountLoading(false));
    }
  }, [params, mountLoading]);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onDateChange = (date) => {
    setData({
      ...data,
      due_date: date,
    });
  };

  return (
    <div className="">
      {matter &&
      <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}`}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number}`, href: `/matters/${matter._id}`, current: false },
        { name: "Tasks", href: `/matters/${matter._id}/tasks`, current: false },
        { name: `Task - #${data.description}`, href: `/matters/${matter._id}/tasks/${params.task_id}`, current: false },
        { name: `Edit`, href: '#', current: true },
      ]} />}
      <Segment className="mt-4">
        <div className="">
          <h3 className="text-xl leading-6 font-medium text-gray-900">
            Edit Task
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          {mountLoading ? (
            <Loader />
          ) : (
            <>
              <Group className="py-2">
                <TextArea
                  label="Description"
                  name="description"
                  value={data.description}
                  onChange={onChange}
                />
              </Group>
              <Group className="py-2">
                <Input
                  type="number"
                  incrementBy="6"
                  label="Time"
                  name="time"
                  value={data.time}
                  onChange={onChange}
                />
                <Dropdown
                  label="Fee Earner"
                  name="assigned"
                  value={data.assigned}
                  onChange={onChange}
                  options={userList}
                />
                <DateSelect
                  label="Due Date"
                  name="due_date"
                  value={data.due_date}
                  onChange={onDateChange}
                />
              </Group>
              <Toggle
                className="my-2"
                label="Is Billable?"
                enabled={data.isBillable}
                onChange={() =>
                  setData({
                    ...data,
                    isBillable: data.isBillable ? !data.isBillable : true,
                  })
                }
              />
              <div className="pt-2 flex justify-between">
                <Button
                  colour="alert"
                  label="Cancel"
                  onClick={() =>
                    history.push(
                      `/matters/${params.matter_id}/tasks/${params.task_id}`
                    )
                  }
                  icon={XIcon}
                />
                <Button
                  colour="positive"
                  label="Update"
                  onClick={() => {
                    API.matters.tasks
                      .update(params.matter_id, params.task_id, data)
                      .then(() =>
                        history.push(
                          `/matters/${params.matter_id}/tasks/${params.task_id}`
                        )
                      )
                      .catch((e) => CommonFunctions.handleError(e));
                  }}
                  icon={CheckIcon}
                />
              </div>
            </>
          )}
        </div>
      </Segment>
    </div>
  );
}
