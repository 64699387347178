import Segment from "../../../components/Segment";
import List from "./list-licences";

export default function LicensingContent({logout, purchase_redirect}) {
    return (
        <Segment>
        <div className="text-left">
            
            <a onClick={() => logout()} className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer">
              &larr; Logout<span aria-hidden="true"></span>
            </a>

        </div>
      <main className="grid h-full place-items-center bg-white px-4 py-3">
          <div className="text-center">
            <p className="text-base font-semibold text-primary">Pro Legal Flow</p>
            <h1 className="mt-1 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Welcome to the Pro Legal Flow System.</h1>
            <p className="mt-6 text-base leading-7 text-gray-600">To access the Pro Legal Flow System you need to have an active Licence, please purchase an <strong>ADMIN</strong> licnece to assign to this user.</p>
            <div className="mt-6 text-base leading-7 text-gray-600">
                <List buy={true} admin={true} />
            </div>
          </div>
        </main>
        
        
        
        </Segment>);
}