import React, { Fragment, useState } from "react";

import { NavLink } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, XIcon, CheckIcon } from "@heroicons/react/outline";
import Organisation_Receiver from "../../../../contexts/organisation_context/Organisation_Receiver";
import ChainedContext from "../../../../contexts/chainedContext";
import { Button } from "../../../../components/Forms";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import { ConfirmModal } from "../../../../components/Modals";

function Navbar({ org, match, history }) {
  const [convertLoading, setConvertLoading] = useState(false);
  const navItems = [
    {
      name: "Dash",
      description: "dash",
      href: `${match.url}`,
      exact: true,
    },
    /*
    {
      name: "Edit",
      description: "edit",
      href: `${match.url}/edit`,
      exact: true,
    },*/
    {
      name: "Checklists",
      description: "checklists",
      href: `${match.url}/checklists`,
      exact: false,
    },
    {
      name: "Services",
      description: "services",
      href: `${match.url}/services`,
      exact: false,
    },
    {
      name: "Templates",
      description: "templates",
      href: `${match.url}/email_templates`,
      exact: false,
    },
    {
      name: "Categories",
      description: "categories",
      href: `${match.url}/activity_categories`,
      exact: false,
    },
    {
      name: "Users",
      description: "users",
      href: `${match.url}/users`,
      exact: false,
    },
    {
      name: "Tables",
      description: "base_rates",
      href: `${match.url}/base_rates`,
      exact: false,
    },
    {
      name: "CustomFields",
      description: "client_custom_fields",
      href: `${match.url}/client_custom_fields`,
      exact: false,
    },
  ];

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <ConfirmModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleSubmit={() => {
          if (convertLoading === false) {
            setConvertLoading(true);
            API.organisations
              .update(org._id, {isActive: !org.isActive})
              .then((res) => {
                setConvertLoading(false);
                setModalOpen(false);
                history.push(`/admin/organisations/${org._id}`);
              })
              .catch((e) => {
                setConvertLoading(false);
                CommonFunctions.handleError(e);
              });
          }
        }}
        header="Are you sure?"
        content={
          `Switch this Organisation to ${org.isActive ? "De-Active" : "Active"}`
        }
      />
      <Disclosure as="nav" className="bg-subMenuPrimary sticky-item-sub-nav">
        {({ open }) => (
          <>
            <div className=" mx-auto  px-2 border-b border-subMenuPrimary">
              <div className="flex items-center justify-between h-12">
                <div className="flex items-center">
                  <div className="hidden md:block">
                    <div className=" flex items-center justify-center text-center space-x-4">
                      <div className="hidden cursor-pointer font-medium lg:flex gap-2 antialiased text-md px-2 py-2 justify-center items-center text-subMenuPrimaryTitle tracking-wider">
                        <span>{org.name}</span>
                      </div>
                      {navItems
                        .filter((s) => {
                          return s.hide !== true;
                        })
                        .map((item, itemIdx) => (
                          <Fragment key={itemIdx}>
                            {item.external ? (
                              <a
                                href={item.href}
                                target="_blank"
                                rel="noreferrer"
                                alt={item.description}
                              >
                                {item.name}
                              </a>
                            ) : (
                              <NavLink
                                exact={item.exact}
                                to={item.href}
                                activeClassName="bg-subMenuActive underline text-subMenuPrimaryText"
                                className="px-2 py-2 text-subMenuPrimaryText text-lg hover:bg-subMenuHover hover:text-subMenuHoverText tracking-wider"
                                aria-current={item.current ? "page" : undefined}
                              >
                                {item.name}
                              </NavLink>
                            )}
                          </Fragment>
                        ))}
                    </div>
                  </div>
                </div>
                  <Button
                    className="float-right text-sm"
                    label={org.isActive ? "Deactivate" : "Activate"}
                    icon={org.isActive ? XIcon : CheckIcon}
                    colour={org.isActive ? "alert" : "positive"}
                    onClick={() => {
                      setModalOpen(true);
                    }}
                    disabled={convertLoading}
                  />
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="bg-gray-200 inline-flex items-center justify-between p-2 rounded-md text-primary hover:text-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-white">
                    <div className=" flex gap-2">
                      <span className="text-black">Organisation: </span>
                    </div>
                    <span className="sr-only text-black">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6 text-black" aria-hidden="true" />
                    ) : (
                      <ChevronDownIcon
                        className="block h-6 w-6 text-black"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {navItems
                  .filter((s) => {
                    return s.hide !== true;
                  })
                  .map((item, itemIdx) => (
                    <Fragment key={itemIdx}>
                      <NavLink
                        exact
                        to={item.href}
                        activeClassName="bg-gray-50 "
                        className="text-black hover:bg-gray-300  block px-3 py-2 rounded-md text-base font-medium"
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </NavLink>
                    </Fragment>
                  ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}

export default ChainedContext(Navbar, [
  [
    Organisation_Receiver,
    (org) => {
      return { org };
    },
  ],
]);
