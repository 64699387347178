import React, { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { UserIcon, PhoneIcon } from "@heroicons/react/solid";
import { GiBasket, GiCheckMark, GiHouse } from "react-icons/gi";
import Segment from "../../components/Segment";
import { Button } from "../../components/Forms";
import QtySelector from "../../components/Forms/QtySelector";
import API from "../../api";

import { useParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Purchase_License(){
  const [licence, setLicence] = useState({
    licence: "",
    QTY: 0
  });
  const [licenceTypes, setLicenceTypes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [mount, setMount] = useState(true);
  const [org, setOrg] = useState({});
  const [myOrg, setMyOrg] = useState(null);
  const [purchaseError, setPurchaseError] = useState("");
  const [existingSubscriptions, setExistingSubscriptions] = useState([]);
  const [newQty, setNewQty] = useState(0);
  const [showUpgradeSuccess, setShowUpgradeSuccess] = useState(false);
  const [showDowngradeSuccess, setShowDowngradeSuccess] = useState(false);

  let _params = useParams();

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if(org._id !== undefined){
      API.organisations.getExistingSubscriptions(org._id).then(res => {
        setExistingSubscriptions(res)
      }).catch(err => {

      })
    }
  }, [org])

  const handleBeginPurchase = (licence) => {
    setPurchaseError("");
    API.organisations.addLicence(org._id, {
      licence: licence.value,
      qty: licence.QTY
    }).then(res => {
      if(res.success){
        if(res.redirect_required !== undefined && res.redirect_required){
          window.location = res.url;
        }
      } else {
        setPurchaseError(res.message);
      }
    }).catch(err => {
      setPurchaseError(err.message);
    });
  }
  const handleEditPurchase = () => {
    setPurchaseError("");
    setShowUpgradeSuccess(false);
    setShowDowngradeSuccess(false);


    API.organisations.modifyLicence(org._id, {
      licenceId: licence.value,
      qty: newQty
    }).then(res => {
      if(res.success){
        if(res.redirect_required !== undefined && res.redirect_required){
          window.location = res.url;
        } else if(res.isUpgrade) {
          setShowUpgradeSuccess(true);
          getData();
          setIsOpen(false);

        } else {
          setShowDowngradeSuccess(true);
          getData();
          setIsOpen(false);

        }
      } else {
        setPurchaseError(res.message);
      }
    }).catch(err => {
      setPurchaseError(err.message);
    });
  }
  const handleSelectLicence = (l) => {
    setPurchaseError("");

    const existing = existingSubscriptions.find(a => a.package === l.value);
    if(existing){
      setIsOpen(true);
      setLicence({...l, QTY: existing._totalAssigned, adjustQty: existing.adjustQty});
      setNewQty(existing.adjustQty ? existing.nextQty : existing.qty);
      setPurchaseError("");
      setShowUpgradeSuccess(false);
      setShowDowngradeSuccess(false);
      return;
    }

    setIsOpen(true);
    setLicence({...l, QTY: 1});
  }
  const cancelLicencePopup = () => {
    setIsOpen(false);
    setNewQty(0);
    setLicence({QTY: 1});
  }

  function getData(){

    API.organisations.getMyOrg().then((organisation) => {

      setMyOrg(organisation._id);

      if(_params.org_id){
        API.organisations.get(_params.org_id).then((organisation) => setOrg(organisation)).finally(() => setMount(false));
      }else{
        setOrg(organisation)
      }  

    }).finally(() => setMount(false));  
    
    API.licences.getAll().then(res => {
        let arr = res.licences.map((l, index) => {
          return ({
            key: index,
            value: l._id,
            text: l.description,
            name: l.name,
            price: l.price,
            seats: l.seats,
            user: l.user,
            payment: l.payment,
          })
        });
        setLicenceTypes(arr)
    });
  }

  const renderPurchaseDialog = () => {
    const existing = existingSubscriptions.find(a => a.package === licence.value);

    return (
      <Transition
        show={isOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          className="fixed z-10 inset-0 overflow-y-auto"
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span className="inline-block h-screen align-middle" aria-hidden="true">&#8203;</span>
            <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">

              {existing === undefined ? <>
                <div className="text-xl">
                  <Dialog.Title>
                    Purchase Licence
                  </Dialog.Title>
                </div>
                <div className="px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                  {purchaseError && <div className={"mb-2 p-2 text-red-600 border rounded border-red-400"}>{purchaseError}</div>}
                  <div>
                    <h3 className={'text-gray-900 text-lg font-semibold leading-8'}>
                      {licence && licence.name}
                    </h3>
                    <p className="mt-1 text-sm leading-6 text-gray-600">{licence && licence.text}</p>
                    <p className="mt-1 flex items-baseline gap-x-1">
                      <span className="text-4xl font-bold tracking-tight text-gray-900">£{licence && licence.price}</span>
                      <span className="text-sm font-semibold leading-6 text-gray-600">{licence.payment !== "single" ? "/" + licence.payment : ""}</span>
                    </p>
                    <QtySelector className={"mt-5"} min={1} onChange={(e) => setLicence({...licence, QTY: e})} value={licence && licence.QTY} />
                  </div>
                </div>
                <div className="flex justify-between mt-4 border-t border-gray-300 pt-4">
                  <Button
                    colour="alert"
                    label="Cancel"
                    onClick={cancelLicencePopup}
                    icon={XIcon}
                  />
                  <Button
                    colour="positive"
                    label="Purchase"
                    onClick={() => handleBeginPurchase(licence)}
                    icon={GiBasket}
                  />
                </div>
              </> : <>
                <div className="text-xl">
                  <Dialog.Title>
                    Manage Licence
                  </Dialog.Title>
                </div>
                <div className="px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                  {purchaseError && <div className={"mb-2 p-2 text-red-600 border rounded border-red-400"}>{purchaseError}</div>}
                  <div>
                    <h3 className={'text-gray-900 text-lg font-semibold leading-8'}>
                      {licence && licence.name}
                    </h3>
                    <p className="mt-1 text-sm leading-6 text-gray-600">{licence && licence.text}</p>
                    <p className="mt-1 flex items-baseline gap-x-1">
                      <span className="text-4xl font-bold tracking-tight text-gray-900">£{licence && licence.price}</span>
                      <span className="text-sm font-semibold leading-6 text-gray-600">{licence.payment !== "single" ? "/" + licence.payment : ""}</span>
                    </p>
                    {licence.adjustQty && <div className={"p-2 m-2 border rounded border-red-300 text-red-800"}>You have a pending licence count downgrade, making adjustments will cancel this.</div>}
                    <QtySelector className={"mt-5"} min={licence.QTY} onChange={setNewQty} value={newQty} />
                  </div>
                </div>
                <div className="flex justify-between mt-4 border-t border-gray-300 pt-4">
                  <Button
                    colour="alert"
                    label="Cancel"
                    onClick={cancelLicencePopup}
                    icon={XIcon}
                  />
                  <Button
                    colour="positive"
                    label="Adjust"
                    onClick={() => handleEditPurchase()}
                    icon={GiBasket}
                  />
                </div>
              </>}

            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  if(mount){
    return "Loading..";
  }
  return (
    <>
      {renderPurchaseDialog()}
         <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
        <Segment>
          <main className="">
            {/* Page header */}
            <div className="mb-2 mx-auto px-4 md:flex md:items-center md:justify-between md:space-x-5 lg:px-4 border-b pb-2">
              <div className="flex items-center space-x-5">
                <div>
                  <h1 className="text-2xl font-bold text-primary">{org.name}</h1>
                  <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <GiHouse
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {org.addresses &&
                      org.addresses.find((item) => item.primary === true)
                        ? `${
                          org.addresses.find((item) => item.primary === true)
                            .postal_code
                        } (${
                          org.addresses.find((item) => item.primary === true).name
                        })`
                        : "N/A"}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                      <PhoneIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {org.contactNumber}
                    </div>
                    <div className="mt-2 flex items-center text-sm text-gray-500">
                    <UserIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {org.contact}
                  </div>
                </div>
              </div>
            </div>
          </div>
            {/* Main Content */}
            {showUpgradeSuccess && <div className={"p-2 m-2 border rounded text-green-700 border-green-400"}>Successfully added new licences to subscription, this may take a few minutes to appear.</div>}
            {showDowngradeSuccess && <div className={"p-2 m-2 border rounded text-green-700 border-green-400"}>Successfully marked the subscription to downgrade licence count at the next renewal.</div>}

            <div className="mt-4 mx-auto md:grid md:grid-cols-1">
                  <div className="col">
                    <div className="bg-white py-24 sm:py-32">
                      <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <div className="mx-auto max-w-4xl text-center">
                          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                            Account Licences
                          </p>
                        </div>
                        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                            Purchase Account Licences for your users to access the system.
                        </p>
                      {(_params.org_id && myOrg) && _params.org_id !== myOrg && <p className="mx-auto mt-2 text-center text-lg leading-8 text-primary">
                          <i><strong>PLF ADMIN Note:</strong> You have navigated to the account purchase page for another organisation, licences purchased will be assigned to this organisation: <br/><strong>{org.name}</strong></i>
                      </p>}
                        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
                          {licenceTypes && licenceTypes.map((l, index) => (
                            <div key={index} className={'ring-1 ring-gray-200 rounded-3xl p-8'}>
                              <h3 className={'text-gray-900 text-lg font-semibold leading-8'}>{l.name}</h3>
                              <p className="mt-4 text-sm leading-6 text-gray-600">{l.text}</p>
                              <p className="mt-6 flex items-baseline gap-x-1">
                                <span className="text-4xl font-bold tracking-tight text-gray-900">£{l.price}</span>
                                <span className="text-sm font-semibold leading-6 text-gray-600">{l.payment !== "single" ? "/" + l.payment : ""}</span>
                              </p>
                              <a
                                onClick={() => handleSelectLicence(l)}
                                className={classNames(
                                  'text-gray-900 ring-1 ring-inset ring-primary hover:bg-primary hover:text-white cursor-pointer',
                                  'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary',
                                )}
                              >
                                {l.payment === "single" ? "Buy License" : "Subscribe"}
                              </a>
                              <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                                  <li className="flex gap-x-3">
                                    <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                                      {/* License for {l.seats} {l.user}s */}
                                      1 {l.user} Licence
                                  </li>
                                  <li className="flex gap-x-3">
                                    <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                                      Access to Pro Legal Flow System
                                  </li>
                                  <li className="flex gap-x-3">
                                    <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                                      User Role (Clients, Matters, Tasks, Activities, Postbox ...)
                                  </li>
                                  <li className="flex gap-x-3">
                                    <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                                      Postbox Authoriser Role (User with Postbox sending ability)
                                  </li>
                                  {l.user == "admin" && <li className="flex gap-x-3">
                                    <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                                    Admin Role (Settings, Users, Global Postbox)
                                  </li>}

                              </ul>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              <div>
            </div>
          </main>
        </Segment>
      </div>
    </>
  );
}
export default Purchase_License;