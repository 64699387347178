import React, { useState, useEffect } from "react";
import Header from "../../components/UserInfoHeader";
import TabView from "../../components/TabView";
import TaskTable from "./taskTable";
import CommonFunctions from "../../CommonFunctions";
import API from "../../api";
import dayjs from "dayjs";
import { Authentication } from "../../plugins/clio_auth";
import Loader from "../../components/Loader";

export default function Home({ history }) {
  const [user, setUser] = useState({});
  const [
    isContactSyncing,
    // setContactSyncing
  ] = useState(false);
  const [tasksMine, setTasksMine] = useState([]);
  const [tasksTeam, setTasksTeam] = useState([]);
  const [tasksOverdue, setTasksOverdue] = useState([]);
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [timers, setTimers] = useState(0);

  useEffect(() => {
    let data = Authentication.getUserData();
    setUser(data);
  }, []);
  

  useEffect(() => {
    if (isContactSyncing === false) {
      Promise.all([
        API.dashboard.getMyTasks().catch((e) => CommonFunctions.handleError(e)),
        // API.dashboard.getTeamTasks().catch((e) => CommonFunctions.handleError(e)),
        // API.dashboard.getOverdueTasks().catch((e) => CommonFunctions.handleError(e)),
        API.timeEntry.list().catch((e) => CommonFunctions.handleError(e)),
      ]).then((res) => {
        // let team = res[0].filter(t => t.assigned == user._clioId);
        let team = res[0].filter(t => t.assigned == user._id);
        let overdue = res[0].filter(e => dayjs(e.due_at) < dayjs());

        setTasksMine(res[0]);
        setTasksTeam(team);
        setTasksOverdue(overdue);

        setTimers(res[1].filter(e => e.complete !== true).length);
        setLoadingTasks(false);
      });
        
    }
  }, [isContactSyncing]);

  return (
    <div className="w-full h-full">
      <main className="flex-1 pb-8">
        <Header history={history} />
        {/* <div className="grid sm:grid-cols-3 gap-4 p-4"> */}
        {/* <div className="col-span-2"> */}
        <div className="mb-5 mt-5">
          <div className="relative">
            <div className="absolute inset-0 h-1/2" />
            <div className=" px-4 sm:px-6 lg:px-8">
              <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-4">
                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                  <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                    Your Tasks
                  </dt>
                  <dd className="order-1 text-5xl font-bold tracking-tight text-primary">
                    {tasksMine.length}
                  </dd>
                </div>
                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                    Team Tasks
                  </dt>
                  <dd className="order-1 text-5xl font-bold tracking-tight text-primary">
                    {tasksTeam.length}
                  </dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                    Overdue Tasks
                  </dt>
                  <dd className="order-1 text-5xl font-bold tracking-tight text-red-600">
                    {tasksOverdue.length}
                  </dd>
                </div>
                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg font-medium leading-6 text-gray-500">
                    Timers
                  </dt>
                  <dd className="order-1 text-5xl font-bold tracking-tight text-primary">
                    {timers}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>

        <div className=" px-4">
          {/* <Button
            className="mb-4"
            colour={isContactSyncing ? "blue" : "green"}
            label={isContactSyncing ? "Please Wait" : "Sync Contacts & Matters"}
            onClick={syncContacts}
            disabled={isContactSyncing ? true : false}
            icon={RefreshIcon}
          /> */}
          {loadingTasks ? 
          <Loader>Loading Tasks...</Loader> :
          <TabView
            tabs={[
              {
                name: "Your Tasks",
                render: () => <TaskTable history={history} tasks={tasksMine} />,
              },
              {
                name: "Team Tasks",
                render: () => (
                  <TaskTable history={history} isTeam tasks={tasksTeam} />
                ),
              },
              {
                name: "Overdue Only",
                render: () => (
                  <TaskTable history={history} isTeam tasks={tasksOverdue} />
                ),
              },
            ]}
          />}
        </div>
        {/* </div> */}
      </main>
    </div>
  );
}
