import React, { useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TabView = ({ tabs = [] }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <React.Fragment>
      <nav
        className="relative z-0 rounded-lg shadow flex divide-x divide-gray-300"
        aria-label="Tabs"
      >
        {tabs.map((tab, tabIdx) => {
          const isCurrent = tabIdx === selectedTab;
          return (
            <button
              key={tab.name}
              onClick={() => setSelectedTab(tabIdx)}
              aria-current={isCurrent ? "page" : undefined}
              className={classNames(
                isCurrent
                  ? "text-gray-900"
                  : "text-gray-500 hover:text-gray-700",
                tabIdx === 0 ? "rounded-l-lg" : "",
                tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
              )}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  isCurrent ? "bg-secondary" : "bg-transparent",
                  "absolute inset-x-0 bottom-0 h-0.5"
                )}
              />
            </button>
          );
        })}
      </nav>
      {tabs.map((tab, tabIdx) => {
        const isCurrent = tabIdx === selectedTab;
        const TabRender = tab.render;
        return (
          <div
            key={tabIdx}
            className={classNames(isCurrent ? "block" : "hidden", "py-2")}
          >
            <TabRender />
          </div>
        );
      })}
    </React.Fragment>
  );
};
export default TabView;
