import React, { useState, useEffect, Fragment } from "react";
import { toast } from "react-hot-toast";
import Segment from "../../../components/Segment";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';

import ChainedContext from "../../../contexts/chainedContext";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
// import DebtorBar from "../_components/DebtorBar";
import API from "../../../api";
import { Button, DateSelect, Input, SelectTwo } from "../../../components/Forms";
import CommonFunctions from "../../../CommonFunctions";
import { useParams } from "react-router-dom";
import { XIcon } from "@heroicons/react/solid";
import { ConfirmModal } from "../../../components/Modals";
import { BiTrash } from "react-icons/bi";

function Fee_Dash({ history, matter, match }) {

  dayjs.extend(customParseFormat);

  const params = useParams();
  const [fee, setFee] = useState({
    transactions: [],
  });

  const [currentTab, setCurrentTab] = useState("overview");
  const [editTransaction, setEditTransaction] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deletePayment, setDeletePayment] = useState(null);

  const [data, setData] = useState({
    number: undefined,
    issueDate: new Date(),
    reference: undefined,
    dueDate: new Date(),
    paidDate: new Date(),
    amount: undefined,
    paymentType: "invoice"
  });

  useEffect(() => {
    if (matter._id && matter.contact._id) {
      getFee();
    }
  }, [matter]);

  const getFee = () => {
      API.matters.fees
        .getOne(matter._id, match.params.fee_id)
        .catch((e) => CommonFunctions.handleError(e))
        .then((res) => {
            setFee({
              ...fee,
              ...res
            });
        });
  };

  const onChange = (e) => {

    if(e.target.name === "amount"){
      if(e.target.value < 0){
        return false;
      }

      let total_balance = 0;

      let amount = e.target.value;

      let theDebts = [fee];

      theDebts.forEach(debt => {

        // let amount = debt.amount ? parseFloat(debt.amount) +  parseFloat(debt.amount * 0.2) : 0; 
        let amount = debt.amount ? parseFloat(debt.amount) : 0; // Already has VAT
        // let amountPaid = debt.amount ? parseFloat(debt.amountPaid) +  parseFloat(debt.amountPaid * 0.2) : 0;
        let amountPaid = debt.amount ? parseFloat(debt.amountPaid) : 0; // Already has VAT

        let balance = amount - amountPaid;
        console.log("🚀 ~ onChange ~ balance:", balance)

        total_balance = total_balance + balance;

        let balanceAfter = balance - amount;

        if(balanceAfter < 0){
          debt.paymentAmount = balance;
        }else{
          debt.paymentAmount = amount;
        }

        amount = balanceAfter < 0 ? Math.abs(balanceAfter) : 0;

      });

      if(parseFloat(e.target.value) > parseFloat(total_balance).toFixed(2)){
        toast.error(`£${e.target.value} can't be entered as this is above the total, invoices total is £${parseFloat(total_balance) ? parseFloat(total_balance).toFixed(2) : total_balance}`)
        setData({ ...data, [e.target.name]: "" });
        return false;
      }

    }

    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleAdd = () => {
    let formData = {};
    formData.number = data.number;
    formData.issueDate = data.issueDate;
    formData.reference = data.reference;
    formData.dueDate = data.dueDate;
    formData.paidDate = data.paidDate;
    formData.amount = parseFloat(data.amount);
    formData.paymentType = data.paymentType;
    formData.amountVAT = parseFloat(data.amount) * 0.2;

    formData._fee_id = match.params.fee_id;

    API.matters.fees.addTransaction(matter._id, {...formData}).then((res) => {
      if(res.success){
        toast.success(`Fee Payment Added Successfully`);
        getFee();
        setData({
          number: undefined,
          issueDate: new Date(),
          reference: undefined,
          dueDate: new Date(),
          paidDate: new Date(),
          amount: undefined,
          paymentType: "invoice"
        })
      }else{
        toast.error(`Fee Payment Error - ${res.message}`);
      }
    }).catch((e) => CommonFunctions.handleError(e));

    return;

    API.matters.debtors.debt
      .addTransaction(matter._id, fee._id, formData)
      .then((res) => {
        if(res.success){
          toast.success("Transaction Added");
          setData({ 
            datePaid: new Date(),
            dateDue: new Date(),
            paymentType: "",
            credit: 0,
            reference: ""
          });
          getFee();
          setCurrentTab("overview");
        }else{
          toast.error(res.message);
        }
      });
  };
  const makeEdit = (d) => {
    setData({ ...data, ...d });
    setEditTransaction(d._id);
  };
  const handleEdit = () => {
    let formData = {};
    formData.credit = parseFloat(data.credit);
    formData.transactionDate = data.datePaid;
    formData.reference = data.reference;
    formData.dateDue = data.dateDue;
    formData.paymentType = data.paymentType;

    API.matters.debtors.fees
      .editTransaction(matter._id, fee._id, formData, editTransaction)
      .then((res) => {

        if(res.success){
          toast.success("Transaction edited");
          setData({ 
            datePaid: new Date(),
            dateDue: new Date(),
            paymentType: "",
            credit: 0,
            reference: ""
          })
          getFee();
          setCurrentTab("overview");
        }else{
          toast.error(res.message);
        }
 
      });
  };

  const removePayment = () => {
    setDeletePayment(null);
    API.matters.fees.deleteTransaction(matter._id, fee._id, deletePayment).then((res) => {
      if(res.success){
        toast.success(res.message);
        getFee();
        setCurrentTab("overview");
      }
    })
  }

  const handleDeleteSubmit = () => {
    API.matters.fees.delete(matter._id, fee._id)
      .then((res) => {

        if(res.success){
          toast.success(`PLF: ${res.message} | Xero: ${res.xeroMessage}`);
          history.push(`/matters/${matter._id}/fees`)
        }else{
          toast.error(res.message);
        }
 
      });
  }

  const tabs = [
    { name: 'Fee Overview', tab: 'overview', current: currentTab === 'overview' },
    { name: 'Payments', tab: 'payments', current: currentTab === 'payments' },
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


  return (
    <Segment>
      <ConfirmModal
          handleClose={() => setShowDelete(false)}
          open={showDelete}
          header={"Are you sure?"}
          content={
            "Are you sure you want to delete this Fee?"
          }
          handleSubmit={handleDeleteSubmit}
        />
      {/* <DebtorBar debtor={debtor} contact={matter.contact} /> */}
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.current).name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
          {/* <h3>#{fee.invoice_num} | Amount Left: £{(parseFloat(fee.amount) ? CommonFunctions.toFormattedFixed((parseFloat(fee.amount) + parseFloat(fee.amount * 0.2)) - parseFloat(fee.amountPaid ? fee.amountPaid : 0)) : 0.00)}</h3> */}
          <h3>#{fee.invoice_num} | Amount Left: £{(parseFloat(fee.amount) ? CommonFunctions.toFormattedFixed((parseFloat(fee.amount)) - parseFloat(fee.amountPaid ? fee.amountPaid : 0)) : 0.00)}</h3> {/* Already has VAT included */}
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  onClick={() => setCurrentTab(tab.tab)}
                  className={classNames(
                    tab.current
                      ? 'border-primary text-primary'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium cursor-pointer'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className={`grid sm:grid-cols-${currentTab === 'overview' ? "2" : "1"} gap-4`}>
        {currentTab === 'overview' &&
          <>
            <Segment>
              <div className="p-2 text-xl text-center text-primary">
                The Fee Details {" "}

                <Button 
                  style={{display: "inline-block"}}
                  label={"Edit"}
                  onClick={() => history.push(`/matters/${params.matter_id}/fees/${params.fee_id}/edit`)}
                  colour={"action"} />
                <Button 
                  disabled={!fee.xeroInvoiceID}
                  className="ml-3"
                  style={{display: "inline-block"}}
                  label={"Xero View"}
                  // onClick={() =>  window.open("https://go.xero.com/AccountsReceivable/Edit.aspx?InvoiceID="+fee.xeroInvoiceID, '_blank').focus()}
                  onClick={() =>  window.open(fee.xeroInvoiceURL, '_blank').focus()}
                  colour={"action"} />
                <Button 
                  disabled={!fee.xeroInvoiceID}
                  className="ml-3"
                  style={{display: "inline-block"}}
                  label={"Email Xero Invoice"}
                  onClick={() => API.matters.fees.sendXeroEmail(matter._id, match.params.fee_id).then(res => res.success ? toast.success(res.message) : toast.error(res.message))}
                  colour={"action"} />
                <Button 
                  disabled={fee.transactions.length > 0}
                  className="ml-3"
                  style={{display: "inline-block"}}
                  label={"Delete"}
                  onClick={() => setShowDelete(true)}
                  // onClick={() => API.matters.fees.sendXeroEmail(matter._id, match.params.fee_id).then(res => res.success ? toast.success(res.message) : toast.error(res.message))}
                  colour={"alert"} />

              </div>
              <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      PLF Invoice Number (Xero Invoice Number)
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">{fee.invoice_num} ({fee.xeroInvoiceNumber})</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Reference
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">{fee.reference}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Issue Date
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {fee.issueDate && dayjs(fee.issueDate).format("DD-MM-YYYY")}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Due Date
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {fee.dueDate && dayjs(fee.dueDate).format("DD-MM-YYYY")}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Paid Date
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {fee.paidDate && dayjs(fee.paidDate).format("DD-MM-YYYY")}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Status</dt>
                    <dd className="mt-1 text-sm text-gray-900">{fee.status}</dd>
                  </div>
                </dl>
              </div>
            </Segment>
            <Segment>
              <div className="px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Amount Due (inc. VAT)
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      £{parseFloat(fee.amount) ? CommonFunctions.toFormattedFixed(parseFloat(fee.amount)) : 0.00}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Amount VAT
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      £{parseFloat(fee.amount) ? CommonFunctions.toFormattedFixed(parseFloat(fee.amount * 0.2)) : 0.00}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Amount Due (ex VAT)
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      £{parseFloat(fee.amount) ? CommonFunctions.toFormattedFixed(parseFloat(fee.amount) - parseFloat(fee.amount * 0.2)) : 0.00}
                    </dd>
                  </div>
                  {/*  
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Amount Fee
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      £{(parseFloat(fee.feeAmount) ? CommonFunctions.toFormattedFixed(parseFloat(fee.feeAmount)) : 0.00)}
                    </dd>
                  </div>*/}
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Amount Paid
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      £{parseFloat(fee.amountPaid) ? CommonFunctions.toFormattedFixed(parseFloat(fee.amountPaid)) : 0.00}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Amount Outstanding
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {/* £{(parseFloat(fee.amount) ? CommonFunctions.toFormattedFixed((parseFloat(fee.amount) + parseFloat(fee.amount * 0.2)) - parseFloat(fee.amountPaid ? fee.amountPaid : 0)) : 0.00)} */}
                      £{(parseFloat(fee.amount) ? CommonFunctions.toFormattedFixed((parseFloat(fee.amount)) - parseFloat(fee.amountPaid ? fee.amountPaid : 0)) : 0.00)} {/* Including VAT already */}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Days not Paid
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {fee.status !== "paid" ? dayjs().diff(dayjs(fee.issueDate), "day") : "Paid off"}
                    </dd>
                  </div>
                </dl>
              </div>

            </Segment>
          </>
        }

        {currentTab === 'payments' &&
          <>
            <Segment>
              {/*  
              <div className="float-right">
                <Button 
                  label="Import"
                  colour="action"
                  onClick={() => setImportModal(true)}
                />
              </div>*/}
              <div className="p-2 text-xl text-center text-primary">
                The Payment List
              </div>
              <div className="flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              ID
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Xero
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              number
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              issueDate
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              reference
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              dueDate
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              paidDate
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              amount
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                             VAT
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              paymentType
                            </th>
                            <th>&nbsp;</th>
                          </tr>
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            ></th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              &nbsp;
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <Input
                                name="number"
                                onChange={onChange}
                                className="w-full"
                                value={data.number}
                                error={!data.number}
                              />
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              <DateSelect
                                onChange={(date) =>
                                  setData({
                                    ...data,
                                    issueDate: date,
                                  })
                                }
                                value={
                                  data.issueDate ? new Date(data.issueDate) : ""
                                }
                                name="issueDate"
                                className={`relative w-full `}
                              />
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <Input
                                name="reference"
                                onChange={onChange}
                                className="w-full"
                                value={data.reference}
                                error={!data.reference}
                              />
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              <DateSelect
                                onChange={(date) =>
                                  setData({
                                    ...data,
                                    dueDate: date,
                                  })
                                }
                                value={
                                  data.dueDate ? new Date(data.dueDate) : ""
                                }
                                name="dueDate"
                                className={`relative w-full`}
                              />
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              <DateSelect
                                onChange={(date) =>
                                  setData({
                                    ...data,
                                    paidDate: date,
                                  })
                                }
                                value={
                                  data.paidDate ? new Date(data.paidDate) : ""
                                }
                                name="paidDate"
                                className={`relative w-full p-2`}
                              />
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <Input
                                type="number"
                                name="amount"
                                onChange={onChange}
                                className="w-full"
                                value={data.amount}
                                error={!editTransaction && (!data.amount || !data.number || !data.amount > 0 || (data.amount > fee.Outstanding))}
                              />
                            </th>
                            <th>
                              20%: {data.amount && `£${ CommonFunctions.toFormattedFixed(parseFloat(data.amount * 0.2))}`}
                            </th>
                            <th
                             scope="col"
                             className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                            <SelectTwo
                              placeholder="Select Method"
                              field="paymentType"
                              options={[
                                {text: "Invoice", value: "invoice"},
                                {text: "Sales Credit", value: "sales_credit"},
                              ]}
                              className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              value={data.paymentType}
                              onChange={onChange}
                            />
                            </th>
                            <th>
                              <Button
                                disabled={!editTransaction && (!data.amount || !data.reference || !data.amount > 0)}
                                label={editTransaction ? "Edit" :"Add"}
                                colour={editTransaction ? "warning" :"positive"}
                                onClick={editTransaction ? handleEdit : handleAdd}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {fee.transactions.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1).map((d, i) => {
                            return (
                            <tr key={i}>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {d.uuid}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {d._xeroPaymentId ? "On Xero" : "Not On Xero"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {d.number}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {d.issueDate
                                  ? dayjs(d.issueDate).format("DD-MM-YYYY")
                                  : "--"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                #{d.reference}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {d.dueDate
                                  ? dayjs(d.dueDate).format("DD-MM-YYYY")
                                  : "--"}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {d.paidDate
                                  ? dayjs(d.paidDate).format("DD-MM-YYYY")
                                  : "--"}
                              </td>
                              {/*  
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {d.dateDue
                                  ? dayjs(d.dateDue).format("DD-MM-YYYY")
                                  : "--"}
                              </td>*/}
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                £{parseFloat(d.amount) ? CommonFunctions.toFormattedFixed(parseFloat(d.amount)) : 0.00}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                £{parseFloat(d.amountVAT) ? CommonFunctions.toFormattedFixed(parseFloat(d.amountVAT)) : 0.00}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {d.paymentType}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {data._id !== d._id ?
                              <Button
                                // disabled={d._xeroPaymentId}
                                label="Edit"
                                colour="warning"
                                onClick={() => {
                                  if(d._xeroPaymentId){
                                    toast.error("Payment can't be edited as Payment is on Xero!")
                                  }else{
                                    makeEdit(d)
                                  }
                                }}
                              /> : 
                              <Button 
                                colour="alert" 
                                icon={XIcon}
                                onClick={() => {
                                  setData({ 
                                    datePaid: new Date(),
                                    dateDue: new Date(),
                                    credit: 0,
                                    paymentType: "",
                                    reference: fee.transactions ? fee.transactions.length + 1 : null
                                  })
                                  getFee();
                                }} />}

                                <ConfirmModal
                                  handleClose={() => setDeletePayment(null)}
                                  open={deletePayment !== null}
                                  header={"Remove Payment?"}
                                  content={
                                    "Are you sure you want to delete this Payment? If this Payment is in Xero this will mark it as Deleted!"
                                  }
                                  handleSubmit={removePayment}
                                />

                                <Button
                                // disabled={d._xeroPaymentId}
                                icon={BiTrash}
                                colour="alert"
                                onClick={() => setDeletePayment(d._id)}
                              /> 
                              </td>
                            </tr>
                          )})}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Segment>
          </>
        }
      </div>
    </Segment>
  );
}
export default ChainedContext(Fee_Dash, [
  [MatterReceiver, "matter"],
]);
