import React, { useEffect, useState } from "react";
import Segment from "../../../components/Segment";
import Loader from "../../../components/Loader";
import API from "../../../api";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Button} from "../../../components/Forms";
import CommonFunctions from "../../../CommonFunctions";
import { PencilIcon, PlusIcon, TrashIcon, UploadIcon } from "@heroicons/react/solid";
import _appConfig from "../../../_appConfig";
import {ChromePicker} from "react-color-v2";
import { GiSave } from "react-icons/gi";


const WhitelabelingButtons = () => {
  const [globalError, setGlobalError] = useState(null);

  const [mount, setMount] = useState(true);
  const [styleUpdate, setStyleUpdate] = useState(false);
  const [org, setOrg] = useState({});

  const [data, setData] = useState({})

  const getData = () => {
    setGlobalError(null);
    API.organisations.getMyOrg().then((res) => {

      let whitelabeling = res.whitelabeling ? res.whitelabeling : {};

      setOrg({_id: res._id});
      setData(whitelabeling);
      
    }).finally(() => setMount(false));
  };

  useEffect(() => {
    getData();
  }, mount);

  if (mount) {
    return <Loader>Retrieving data from server...</Loader>;
  }
  if (styleUpdate) {
    return <Loader>Loading Sytle, the browser will refresh...</Loader>;
  }
  if (globalError) {
    return <div className={"m-4 p-2 bg-red-600 text-white"}>{globalError}</div>;
  }

  // console.log("org", org)
  console.log("data", data)

  let buttons = [
    {title: "Action", subtext: "Buttons to allow the user to do actions such as Edit and View buttons", field: "action"},
    {title: "Positive", subtext: "Buttons to inform the user of something being added such as Add / Create buttons", field: "positive"},
    {title: "Warning", subtext: "Buttons to warn the user such as Update / Save", field: "warning"},
    {title: "Alert", subtext: "Buttons to alert the user such as Archive / Delete", field: "alert"},
    {title: "Activity", subtext: "Buttons on right of menu for matters for starting an activity", field: "activity"},
    {title: "Sidebar Timer", subtext: "Button on the sidebar for the timer", field: "sidebarTimer"},
    {title: "Sidebar Timer Active", subtext: "Button on the sidebar for the timer, switched to this when the timer is actively running", field: "sidebarTimerActive"},
    {title: "Sidebar View All Timers", subtext: "Buttons on the sidebar to view the list of all timers", field: "sidebarTimerViewAll"},
  ];

  return (
    <React.Fragment>
      <Segment>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Whitelabeling - Buttons
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Whitelabeing options for Buttons on the system for your organization.
              </p>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                
              <div>
                <div className="mt-6 border-t border-gray-100">
                  {buttons.map((button, index) => 
                    <dl className="divide-y divide-gray-100">
                    <div className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} px-4 py-6 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-3`}>
                      <dt className="text-sm font-medium leading-6 text-gray-900">{button.title}<br/>
                      <i>{button.subtext}</i>
                      <Button color={button.field} label={button.title} /> <br/>
                      <Button color={button.field} label={"Disabled "+button.title} disabled={true} />
                      </dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700">
                        Background: <br/>
                        <ChromePicker color={data[`${button.field}Button`] ? data[`${button.field}Button`] : "#000"} onChange={(color) => setData({...data, [`${button.field}Button`]: color.hex})}/> <br/>
                        Hover: <br/>
                        <ChromePicker color={data[`${button.field}ButtonHover`] ? data[`${button.field}ButtonHover`] : "#000"} onChange={(color) => setData({...data, [`${button.field}ButtonHover`]: color.hex})}/>
                      </dd>
                      <dd className="mt-1 text-sm leading-6 text-gray-700">
                        Text: <br/>
                        <ChromePicker color={data[`${button.field}ButtonText`] ? data[`${button.field}ButtonText`] : "#000"} onChange={(color) => setData({...data, [`${button.field}ButtonText`]: color.hex})}/> <br/>
                        Hover: <br/>
                        <ChromePicker color={data[`${button.field}ButtonTextHover`] ? data[`${button.field}ButtonTextHover`] : "#000"} onChange={(color) => setData({...data, [`${button.field}ButtonTextHover`]: color.hex})}/>
                      </dd>
                    </div>
                  </dl>
                  )}
                  

                </div>
                <Button
                    className={`inline-flex justify-center rounded-md border bg-orange-600 hover:bg-orange-700 focus:ring-orange-500 border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                    colour={"warning"}
                    icon={GiSave}
                    label={"Save "}
                    onClick={() => {
                      let formData = new FormData();
                      formData.append("data", JSON.stringify(data));
                      
                      API.organisations.updateWhitelabeling(org._id, formData)
                      .then((res) => {
                        setStyleUpdate(true);
                        window.location.reload();
                      })
                        .catch((e) => CommonFunctions.handleError(e));
                    }}
                  />
              </div>

              </div>
            </div>
          </div>
        </div>
      </Segment>
    </React.Fragment>
  );
};
export default WhitelabelingButtons;
