import React, { useState, useEffect } from "react";
import { ThemeTable } from "../../../../components/Tables";
import { Button } from "../../../../components/Forms";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import API from "../../../../api";
import Segment from "../../../../components/Segment";
import CommonFunctions from "../../../../CommonFunctions";
import Loader from "../../../../components/Loader";
import { PlusIcon } from "@heroicons/react/solid";
import DebtorBar from "../_components/DebtorBar";
import ChainedContext from "../../../../contexts/chainedContext";
import MatterReceiver from "../../../../contexts/matter_context/Matter_Receiver";
import DebtorReceiver from "../../../../contexts/debtor_context/Debtor_Receiver";
function ActivitiesList({ matter, debtor }) {
  const params = useParams();
  const history = useHistory();

  const [contactActivities, setContactActivities] = useState([]);
  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    API.matters.debtors.activities
      .list(params.matter_id, params.debtor_id)
      .then((res) => {
        setContactActivities(res);
      })
      .catch((e) => CommonFunctions.handleError(e))
      .finally(() => {
        setMountLoading(false);
      });
  }, [params.matter_id, params.debtor_id]);

  return (
    <>
      <div className="">
        <Segment>
          <DebtorBar debtor={debtor} contact={matter.contact} />
          <div>
            <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
              <span className="my-auto text-xl">Activities</span>
              <Button
                colour="positive"
                label="Create"
                onClick={() =>
                  history.push(
                    `/matters/${params.matter_id}/debtors/${params.debtor_id}/activities/add`
                  )
                }
                className="ml-auto"
                icon={PlusIcon}
              />
            </h3>
            <div className="w-full border-t border-gray-300 my-2" />
          </div>
          {mountLoading ? (
            <Loader />
          ) : (
            <ThemeTable
              header={[
                { text: "Activity", value: "description" },
                { text: "Fee Earner", value: "assigned" },
                { text: "Due Date", value: "due_date" },
                { text: "Time", value: "time" },
                { text: "Cost", value: "cost" },
                { text: "Billable", value: "isBillable" },
                { text: "Status", value: "status" },
              ]}
              body={contactActivities.map((item) => ({
                ...item,
                isBillable: item.isBillable === true ? "Yes" : "No",
                matter_display_number:
                  item.matter && item.matter !== null
                    ? item.matter.display_number
                    : "--",
                assignee_name: item.assignee ? item.assignee.name : "--",
                due_date: item.due_date
                  ? dayjs(item.due_date).format("DD/MM/YYYY")
                  : "",
                status: item.complete ? "Complete" : "In Progress",
                extra_row_css: item.complete ? "text-gray-400" : "text-gray-900",
                cost: `
                  £${(
                    (item.time ? item.time : 0) *
                    (item.currentRate ? item.currentRate / 60 : 0)
                  ).toFixed(2)}`,
              }))}
              action={(item) =>
                history.push(
                  `/matters/${params.matter_id}/debtors/${params.debtor_id}/activities/${item._id}`
                )
              }
              noDataMessage="No Activities..."
            />
          )}
        </Segment>
      </div>
    </>
  );
}
export default ChainedContext(ActivitiesList, [
  [MatterReceiver, "matter"],
  [DebtorReceiver, "debtor"],
]);
