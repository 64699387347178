import React, { useState, useEffect } from "react";
import Segment from "../../../../components/Segment";

import ChainedContext from "../../../../contexts/chainedContext";
import MatterReceiver from "../../../../contexts/matter_context/Matter_Receiver";
import DebtorReceiver from "../../../../contexts/debtor_context/Debtor_Receiver";
import DebtorBar from "../_components/DebtorBar";
import DebtSchedule from "../_components/DebtSchedule";
import API from "../../../../api";
import { Button } from "../../../../components/Forms";

function Debtor_Debt_Dash({ history, debtor, matter, match }) {
  const [debts, setDebts] = useState([]);

  useEffect(() => {
    if (matter._id && matter.contact._id) {
      getDebt();
    }
  }, [matter]);
  const getDebt = () => {
    API.matters.debtors.debt.getAll(matter._id, debtor._id).then((res) => {
      setDebts(res);
    });
  };
  const handleEdit = (data) => {
    alert("bang!");
  };
  return (
    <Segment>
      <DebtorBar debtor={debtor} contact={matter.contact} />
      <div className="flex justify-between p-2 ">
        <div className="text-xl">The Debt List</div>
        <div>
          <Button
            colour="positive"
            label="Add Invoice"
            onClick={() => history.push(`${match.url}/add`)}
          />
          <Button
            style={{float: 'right'}}
            colour="action"
            label="Add Payment"
            onClick={() => history.push(`/matters/${matter._id}/debts/payment`)}
            disabled={!debts.length > 0}
          />
        </div> 
        
      </div>

      {/* <Segment> */}
      <DebtSchedule
        debts={debts}
        editData={handleEdit}
        match={match}
        history={history}
      />
      {/* </Segment> */}
    </Segment>
  );
}
export default ChainedContext(Debtor_Debt_Dash, [
  [MatterReceiver, "matter"],
  [DebtorReceiver, "debtor"],
]);
