import { ApiRequest } from "../../plugins/clio_auth";
import AppConfig from "../../_appConfig";

export default {
  normal: {
    create: (data) =>
      ApiRequest.post(
        `${AppConfig.api_server}/api/settings/base_rates/normal`,
        data
      ),

    delete: (rateId) =>
      ApiRequest.delete(
        `${AppConfig.api_server}/api/settings/base_rates/normal/${rateId}`
      ),
  },
  create: (data) => 
    ApiRequest.post(
      `${AppConfig.api_server}/api/settings/area_tasks/create`,
      data),
  update: (area_id, data) =>
    ApiRequest.patch(
      `${AppConfig.api_server}/api/settings/area_tasks/update/${area_id}`,
      data
    ),
  updateTask: (task_id, data) =>
    ApiRequest.patch(
      `${AppConfig.api_server}/api/settings/area_tasks/updateTask/${task_id}`,
      data
    ),
  updateStep: (task_id, data) =>
    ApiRequest.patch(
      `${AppConfig.api_server}/api/settings/area_tasks/updateStep/${task_id}`,
      data
    ),
  addStep: (task_id) =>
    ApiRequest.post(
      `${AppConfig.api_server}/api/settings/area_tasks/addStep/${task_id}`,
      {}
    ),
  addTask: (area_id) =>
    ApiRequest.post(
      `${AppConfig.api_server}/api/settings/area_tasks/addTask/${area_id}`,
      {}
    ),
  updateStepLinks: (area_id, step_id, data) =>
    ApiRequest.patch(
      `${AppConfig.api_server}/api/settings/area_tasks/update/${area_id}/stepLinks/${step_id}`,
      data
    ),
  updateTaskAutomations: (area_id, task_id, data) =>
    ApiRequest.patch(
      `${AppConfig.api_server}/api/settings/area_tasks/update/${area_id}/updateAutomations/${task_id}`,
      data
    ),
  getAll: (org) =>
    ApiRequest.get(`${AppConfig.api_server}/api/settings/area_tasks/getAll${org ? `?org_id=${org}` : ''}`),
  get: (id) =>
    ApiRequest.get(`${AppConfig.api_server}/api/settings/area_tasks/get/${id}`),
  getTask: (id) =>
    ApiRequest.get(`${AppConfig.api_server}/api/settings/area_tasks/getTask/${id}`),
  getStep: (id) =>
    ApiRequest.get(`${AppConfig.api_server}/api/settings/area_tasks/getStep/${id}`),
  rateTester: (data) =>
    ApiRequest.post(
      `${AppConfig.api_server}/api/settings/base_rates/tester`,
      data
    ),
};
