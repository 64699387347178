import React, { useState, useEffect } from "react";
import ContactReceiver from "../../../contexts/contact_context/Contact_Receiver";
import ChainedContext from "../../../contexts/chainedContext";
import Loader from "../../../components/Loader";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import { Button } from "../../../components/Forms";
import { ThemeTable } from "../../../components/Tables";
import Segment from "../../../components/Segment";
import dayjs from "dayjs";
import { PlusIcon } from "@heroicons/react/solid";
import ContactBar from "../components/ContactBar";
import ClientBreadcrumbs from "../components/ClientBreadcrumbs";

function Index({ match, history, contact }) {
  const [matters, setMatters] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([API.contacts.matters.list(match.params.contact_id)])
      .then((res) => {
        setMatters(res[0]);
      })
      .catch((e) => CommonFunctions.handleError(e))
      .finally(() => {
        setLoading(false);
      });
  }, [match.params.id]);

  return (
    <div className="">
      <ClientBreadcrumbs panes={[
        { name: `Client - ${contact && `${contact.name ? contact.name : `${contact.first_name} ${contact.last_name}`} ${contact.suffix ? contact.suffix : ""}`}`, href: `/contacts/${contact._id}`, current: false },
        { name: "Matters", current: true}
      ]} />
      <Segment>
        <ContactBar contact={contact} />
        <div className="">
          <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
            <span className="my-auto text-xl">Matter List</span>
            <Button
              colour="positive"
              label="Create"
              onClick={() => history.push(`${match.url}/create`)}
              className="ml-auto"
              icon={PlusIcon}
            />
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
        </div>
        {loading ? (
          <Loader />
        ) : (
          <ThemeTable
            header={[
              { text: "Display Number (Clio)", value: "display_number" },
              { text: "Debtors", value: "debtors" },
              { text: "Date", value: "open_date" },
              { text: "Practice Area", value: "practice_area_name" },
              { text: "Dealing", value: "name" },
            ]}
            body={matters.map((item) => {
              return{
              ...item,
              debtors: item.debtors.length > 0 ? item.debtors.join(", ") : "No Debtors",
              display_number: item.display_number ? item.display_number : item.client_reference,
              name: item.originating_attorney
                ? item.originating_attorney.name
                : "--",
              practice_area_name: item.practice_area
                ? item.practice_area.name
                : "--",
              open_date: dayjs(item.open_date).format("DD/MM/YYYY HH:mm"),
            }})}
            action={(data) => history.push(`/matters/${data._id}`)}
            noDataMessage="No Matters..."
          />
        )}
      </Segment>
    </div>
  );
}
export default ChainedContext(Index, [
  [
    ContactReceiver,
    (contact) => {
      return { contact };
    },
  ],
]);
