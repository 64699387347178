import { ApiRequest } from "../plugins/clio_auth";
import AppConfig from "../_appConfig";
import settings from "./settings";

const quickObjectToQueryString = (query) => {
  return (
    "?" +
    Object.keys(query)
      .map((key) =>
        key === "filters"
          ? key + "=" + JSON.stringify(query[key])
          : key + "=" + query[key]
      )
      .join("&")
  );
};

const API = {
  dashboard: {
    getMyTasks: () =>
      ApiRequest.get(AppConfig.api_server + "/api/dashboard/tasks/mine"),
    getTeamTasks: () =>
      ApiRequest.get(AppConfig.api_server + "/api/dashboard/tasks/team"),
    getOverdueTasks: () =>
      ApiRequest.get(AppConfig.api_server + "/api/dashboard/tasks/overdue"),
  },
  licences: {
    getAll: () => ApiRequest.get(AppConfig.api_server + "/api/licences"),
    add: (data) => ApiRequest.post(AppConfig.api_server + "/api/licences", data),
    get: (id) => ApiRequest.get(AppConfig.api_server + `/api/licences/${id}`),
    update: (id, data) => ApiRequest.patch(AppConfig.api_server + `/api/licences/${id}`, data),
  },
  organisations: {
    create: (data) =>
      ApiRequest.post(AppConfig.api_server + "/api/organisations", data),
    connectXero: () =>
      ApiRequest.get(AppConfig.api_server + "/api/organisations/connectXero"),
    update: (id, data) =>
      ApiRequest.patch(AppConfig.api_server + `/api/organisations/${id}`, data),
    updateWhitelabeling: (id, data) =>
      ApiRequest.patch(AppConfig.api_server + `/api/organisations/${id}/whitelabeling`, data),
    addLicence: (id, data) =>
      ApiRequest.post(AppConfig.api_server + `/api/organisations/${id}/addLicence`, data),
    modifyLicence: (id, data) =>
      ApiRequest.patch(AppConfig.api_server + `/api/organisations/${id}/modifyLicence`, data),
    renewLicence: (id, lid) =>
      ApiRequest.post(AppConfig.api_server + `/api/organisations/${id}/renewLicence/${lid}`, {}),
    getExistingSubscriptions: (id) => ApiRequest.get(`${AppConfig.api_server}/api/organisations/${id}/subscriptions/current`),
    getAllListed: () =>
      ApiRequest.get(AppConfig.api_server + "/api/organisations/getAllListed"),
    getAll: (searchTerm = "", grouped = true) =>
      ApiRequest.get(AppConfig.api_server + "/api/organisations/", {
        params: {
          search: searchTerm ? searchTerm : undefined,
          grouped: grouped ? "1" : undefined,
        },
      }),
    get: (id) => ApiRequest.get(AppConfig.api_server + "/api/organisations/" + id),
    getMyOrg: () => ApiRequest.get(AppConfig.api_server + "/api/organisations/getMyOrg"),
  },
  postbox: {
    getAll: () => ApiRequest.get(AppConfig.api_server + "/api/postbox"),
    get: (id) => ApiRequest.get(AppConfig.api_server + `/api/postbox/${id}`),
    update: (id, data) => ApiRequest.patch(AppConfig.api_server + `/api/postbox/${id}`, data),
    approveEmail: (id) => ApiRequest.post(AppConfig.api_server + `/api/postbox/${id}/approveEmail`, {}),
    approvePrint: (id) => ApiRequest.post(AppConfig.api_server + `/api/postbox/${id}/approvePrint`, {}),
    unapproveEmail: (id) => ApiRequest.post(AppConfig.api_server + `/api/postbox/${id}/unapproveEmail`, {}),
    switchPostboxType: (id) => ApiRequest.post(AppConfig.api_server + `/api/postbox/${id}/switchPostboxType`, {}),
  },
  notifications: {
    getAll: () => ApiRequest.get(AppConfig.api_server + "/api/notifications"),
    add: (data) => ApiRequest.post(AppConfig.api_server + "/api/notifications", data),
    get: (id) => ApiRequest.get(AppConfig.api_server + `/api/notifications/${id}`),
  },
  users: {
    sync: (org) => ApiRequest.get(AppConfig.api_server + `/api/users/sync${org ? "?org_id="+org : ""}`),
    getAll: (org) => ApiRequest.get(AppConfig.api_server + `/api/users${org ? "?org_id="+org : ""}`),
    get: (id) => ApiRequest.get(AppConfig.api_server + `/api/users/${id}`),
    update: (id, data) => ApiRequest.patch(AppConfig.api_server + `/api/users/${id}`, data),
  },
  search: {
    global: (searchTerm, resultsLimit = 20) =>
      ApiRequest.get(AppConfig.api_server + "/api/search/global", {
        params: {
          search: searchTerm,
          limit: resultsLimit,
        },
      }),
  },
  prospective_contact: {
    create: (data) =>
      ApiRequest.post(AppConfig.api_server + "/api/prospective_contact", data),
    update: (id, data) =>
      ApiRequest.patch(
        AppConfig.api_server + `/api/prospective_contact/${id}`,
        data
      ),
    convert: (id) =>
      ApiRequest.patch(
        AppConfig.api_server + `/api/prospective_contact/${id}/convert`
      ),
    getAll: (searchTerm = "", clients_only = true, grouped = true) =>
      ApiRequest.get(AppConfig.api_server + "/api/prospective_contact/", {
        params: {
          search: searchTerm ? searchTerm : undefined,
          grouped: grouped ? "1" : undefined,
          clients_only: clients_only ? "1" : undefined,
        },
      }),
    get: (id) =>
      ApiRequest.get(AppConfig.api_server + "/api/prospective_contact/" + id),
    updateSettings: (id, data) =>
      ApiRequest.patch(
        AppConfig.api_server + "/api/prospective_contact/" + id + "/settings",
        data
      ),
    credit_checks: {
      all: (id) =>
        ApiRequest.get(
          AppConfig.api_server +
            "/api/prospective_contact/" +
            id +
            "/credit_checks"
        ),
      newReport: (id, reportType, creditId) =>
        ApiRequest.get(
          AppConfig.api_server +
            "/api/prospective_contact/" +
            id +
            "/credit_checks/new/" +
            reportType +
            "/" +
            creditId
        ),
      report: (contactId, reportId) =>
        ApiRequest.get(
          AppConfig.api_server +
            "/api/prospective_contact/" +
            contactId +
            "/credit_checks/report/" +
            reportId
        ),
    },
    activities: {
      create: (contact_id, data) =>
        ApiRequest.post(
          AppConfig.api_server +
            "/api/prospective_contact/" +
            contact_id +
            "/activities",
          data
        ),
      list: (contact_id) =>
        ApiRequest.get(
          AppConfig.api_server +
            "/api/prospective_contact/" +
            contact_id +
            "/activities/list"
        ),
      update: (contact_id, activity_id, data) =>
        ApiRequest.patch(
          AppConfig.api_server +
            "/api/prospective_contact/" +
            contact_id +
            "/activities/" +
            activity_id,
          data
        ),
      updateStatus: (contact_id, activity_id) =>
        ApiRequest.patch(
          AppConfig.api_server +
            "/api/prospective_contact/" +
            contact_id +
            "/activities/" +
            activity_id +
            "/status",
          {}
        ),
      get: (contact_id, activity_id) =>
        ApiRequest.get(
          AppConfig.api_server +
            "/api/prospective_contact/" +
            contact_id +
            "/activities/" +
            activity_id
        ),
    },
  },
  contacts: {
    create: (data) =>
      ApiRequest.post(AppConfig.api_server + "/api/contacts", data),
    update: (id, data) =>
      ApiRequest.patch(AppConfig.api_server + `/api/contacts/${id}`, data),
    getAllListed: () =>
      ApiRequest.get(AppConfig.api_server + "/api/contacts/getAllListed"),
    getAll: (searchTerm = "", clients_only = true, grouped = true) =>
      ApiRequest.get(AppConfig.api_server + "/api/contacts/", {
        params: {
          search: searchTerm ? searchTerm : undefined,
          grouped: grouped ? "1" : undefined,
          clients_only: clients_only ? "1" : undefined,
        },
      }),
    get: (id) => ApiRequest.get(AppConfig.api_server + "/api/contacts/" + id),
    updateSettings: (id, data) =>
      ApiRequest.patch(
        AppConfig.api_server + "/api/contacts/" + id + "/settings",
        data
      ),
    matters: {
      create: (contact_id, data) =>
        ApiRequest.post(
          AppConfig.api_server + "/api/contacts/" + contact_id + "/matters",
          data
        ),
      list: (contact_id) =>
        ApiRequest.get(
          AppConfig.api_server + "/api/contacts/" + contact_id + "/matters"
        ),
    },
    activities: {
      create: (contact_id, data) =>
        ApiRequest.post(
          AppConfig.api_server + "/api/contacts/" + contact_id + "/activities",
          data
        ),
      list: (contact_id) =>
        ApiRequest.get(
          AppConfig.api_server +
            "/api/contacts/" +
            contact_id +
            "/activities/list"
        ),
      update: (contact_id, activity_id, data) =>
        ApiRequest.patch(
          AppConfig.api_server +
            "/api/contacts/" +
            contact_id +
            "/activities/" +
            activity_id,
          data
        ),
      updateStatus: (contact_id, activity_id) =>
        ApiRequest.patch(
          AppConfig.api_server +
            "/api/contacts/" +
            contact_id +
            "/activities/" +
            activity_id +
            "/status",
          {}
        ),
      get: (contact_id, activity_id) =>
        ApiRequest.get(
          AppConfig.api_server +
            "/api/contacts/" +
            contact_id +
            "/activities/" +
            activity_id
        ),
    },
    tasks: {
      create: (contact_id, data) =>
        ApiRequest.post(
          AppConfig.api_server + "/api/contacts/" + contact_id + "/tasks",
          data
        ),
      list: (contact_id) =>
        ApiRequest.get(
          AppConfig.api_server + "/api/contacts/" + contact_id + "/tasks/list"
        ),
      update: (contact_id, task_id, data) =>
        ApiRequest.patch(
          AppConfig.api_server +
            "/api/contacts/" +
            contact_id +
            "/tasks/" +
            task_id,
          data
        ),
      updateStatus: (contact_id, task_id) =>
        ApiRequest.patch(
          AppConfig.api_server +
            "/api/contacts/" +
            contact_id +
            "/tasks/" +
            task_id +
            "/status",
          {}
        ),
      get: (contact_id, task_id) =>
        ApiRequest.get(
          AppConfig.api_server +
            "/api/contacts/" +
            contact_id +
            "/tasks/" +
            task_id
        ),
    },
    communications: {
      logs: {
        create: (contact_id, data) =>
          ApiRequest.post(
            AppConfig.api_server +
              "/api/contacts/" +
              contact_id +
              "/communications/logs/",
            data
          ),
      },
    },
    debt: {
      create: (contact_id, data) =>
        ApiRequest.post(
          AppConfig.api_server + "/api/contacts/" + contact_id + "/debt/",
          data
        ),
      getAll: (contact_id) =>
        ApiRequest.get(
          AppConfig.api_server + "/api/contacts/" + contact_id + "/debt/"
        ),
      update: (contact_id, debt_id, data) =>
        ApiRequest.patch(
          AppConfig.api_server +
            "/api/contacts/" +
            contact_id +
            "/debt/" +
            debt_id,
          data
        ),
    },
    credit_checks: {
      all: (id) =>
        ApiRequest.get(
          AppConfig.api_server + "/api/contacts/" + id + "/credit_checks"
        ),
      newReport: (id, reportType, creditId) =>
        ApiRequest.get(
          AppConfig.api_server +
            "/api/contacts/" +
            id +
            "/credit_checks/new/" +
            reportType +
            "/" +
            creditId
        ),
      report: (contactId, reportId) =>
        ApiRequest.get(
          AppConfig.api_server +
            "/api/contacts/" +
            contactId +
            "/credit_checks/report/" +
            reportId
        ),
    },
  },
  timeEntry: {
    add: (data) =>
      ApiRequest.post(AppConfig.api_server + "/api/timeEntry", data),
    list: () => ApiRequest.get(AppConfig.api_server + "/api/timeEntry/list"),
    update: (id, data) =>
      ApiRequest.patch(AppConfig.api_server + "/api/timeEntry/" + id, data),
    finish: (id, data) =>
      ApiRequest.post(AppConfig.api_server + `/api/timeEntry/${id}/finish`, data),
  },
  customFields: {
    add: (data) =>
      ApiRequest.post(AppConfig.api_server + "/api/customFields", data),
    get: (id) =>
      ApiRequest.get(AppConfig.api_server + "/api/customFields/" + id),
    archive: (id) =>
      ApiRequest.delete(AppConfig.api_server + "/api/customFields/" + id),
    list: (org) => ApiRequest.get(`${AppConfig.api_server}/api/customFields/list${org ? "?org_id="+org : ""}`),
    listPaged: (query) =>
      ApiRequest.get(
        AppConfig.api_server +
          "/api/customFields/pagedShortcodes" +
          quickObjectToQueryString(query)
      ),
    update: (id, data) =>
      ApiRequest.patch(AppConfig.api_server + "/api/customFields/" + id, data),
  },
  matters: {
    syncXero: (id) =>
      ApiRequest.get(AppConfig.api_server + `/api/matters/${id}/syncXero`),
    update: (id, data) =>
      ApiRequest.patch(AppConfig.api_server + `/api/matters/${id}`, data),
    list: () => ApiRequest.get(AppConfig.api_server + "/api/matters"),
    listPaged: (query) =>
      ApiRequest.get(
        AppConfig.api_server +
          "/api/matters/paged" +
          quickObjectToQueryString(query)
      ),
    count: () => ApiRequest.get(AppConfig.api_server + "/api/matters/count"),
    get: (matter_id) =>
      ApiRequest.get(AppConfig.api_server + "/api/matters/" + matter_id),
    activities: {
      create: (matter_id, data) =>
        ApiRequest.post(
          AppConfig.api_server + "/api/matters/" + matter_id + "/activities",
          data
        ),
      list: (matter_id) =>
        ApiRequest.get(
          AppConfig.api_server +
            "/api/matters/" +
            matter_id +
            "/activities/list"
        ),
      update: (matter_id, activity_id, data) =>
        ApiRequest.patch(
          AppConfig.api_server +
            "/api/matters/" +
            matter_id +
            "/activities/" +
            activity_id,
          data
        ),
      documentGenerate: (matter_id,activity_id, data) =>
      ApiRequest.post(
        AppConfig.api_server + "/api/matters/" + matter_id + "/activities/" + activity_id + "/generateDocument",
        data
      ),
      updateStatus: (matter_id, activity_id) =>
        ApiRequest.patch(
          AppConfig.api_server +
            "/api/matters/" +
            matter_id +
            "/activities/" +
            activity_id +
            "/status",
          {}
        ),
      get: (matter_id, activity_id) =>
        ApiRequest.get(
          AppConfig.api_server +
            "/api/matters/" +
            matter_id +
            "/activities/" +
            activity_id
        ),
    },

    fees:{
      getAll: (matter_id) =>
        ApiRequest.get(
          AppConfig.api_server + `/api/matters/${matter_id}/fees`
        ),
      getOne: (matter_id, fee_id) =>
        ApiRequest.get(
          AppConfig.api_server + `/api/matters/${matter_id}/fees/${fee_id}`
        ),
      update: (matter_id, fee_id, data) =>
        ApiRequest.patch(
          AppConfig.api_server + `/api/matters/${matter_id}/fees/${fee_id}`, data
        ),
      delete: (matter_id, fee_id, data) =>
        ApiRequest.delete(
          AppConfig.api_server + `/api/matters/${matter_id}/fees/${fee_id}`, data
        ),
      sendXeroEmail: (matter_id, fee_id) =>
        ApiRequest.get(
          AppConfig.api_server + `/api/matters/${matter_id}/fees/${fee_id}/sendXeroEmail`
        ),
      create: (matter_id, data) =>
        ApiRequest.post(
          AppConfig.api_server + `/api/matters/${matter_id}/fees`,
          data
        ),
      addTransaction: (matter_id, data) =>
        ApiRequest.post(
          AppConfig.api_server + `/api/matters/${matter_id}/fees/transaction`,
          data
        ),
      updateTransaction: (matter_id, fee_id, transaction_id, data) =>
        ApiRequest.patch(
          AppConfig.api_server + `/api/matters/${matter_id}/fees/${fee_id}transaction/${transaction_id}`,
          data
        ),
      deleteTransaction: (matter_id, fee_id, transaction_id) =>
        ApiRequest.delete(
          AppConfig.api_server + `/api/matters/${matter_id}/fees/${fee_id}/transaction/${transaction_id}`
        ),
    },
    tasks: {
      create: (matter_id, data) =>
        ApiRequest.post(
          AppConfig.api_server + "/api/matters/" + matter_id + "/tasks",
          data
        ),
      documentGenerate: (matter_id,task_id, data) =>
        ApiRequest.post(
          AppConfig.api_server + "/api/matters/" + matter_id + "/tasks/" + task_id + "/generateDocument",
          data
        ),
      list: (matter_id) =>
        ApiRequest.get(
          AppConfig.api_server + "/api/matters/" + matter_id + "/tasks/list"
        ),
      update: (matter_id, task_id, data) =>
        ApiRequest.patch(
          AppConfig.api_server +
            "/api/matters/" +
            matter_id +
            "/tasks/" +
            task_id,
          data
        ),
      updateStatus: (matter_id, task_id, extra_data = {}) =>
        ApiRequest.patch(
          AppConfig.api_server +
            "/api/matters/" +
            matter_id +
            "/tasks/" +
            task_id +
            "/status",
          extra_data
        ),
      get: (matter_id, task_id) =>
        ApiRequest.get(
          AppConfig.api_server +
            "/api/matters/" +
            matter_id +
            "/tasks/" +
            task_id
        ),
    },
    debtors: {
      getAll: (matter_id) =>
        ApiRequest.get(
          AppConfig.api_server + `/api/matters/${matter_id}/debtors`
        ),
      get: (matter_id, debtor_id) =>
        ApiRequest.get(
          AppConfig.api_server +
            `/api/matters/${matter_id}/debtors/${debtor_id}`
        ),
      archive: (matter_id, debtor_id) =>
        ApiRequest.post(
          AppConfig.api_server +
            `/api/matters/${matter_id}/debtors/${debtor_id}/archive`, {}
        ),
      create: (matter_id, data) =>
        ApiRequest.post(
          AppConfig.api_server + "/api/matters/" + matter_id + "/debtors",
          data
        ),
      update: (matter_id, debtor_id, data) =>
        ApiRequest.patch(
          AppConfig.api_server +
            `/api/matters/${matter_id}/debtors/${debtor_id}`,
          data
        ),
      uploadFile: (matter_id, debtor_id, data) =>
        ApiRequest.post(`${AppConfig.api_server}/api/matters/${matter_id}/debtors/${debtor_id}/upload_file`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        }),
      uploadInvoice: (matter_id, debtor_id, data) =>
        ApiRequest.post(`${AppConfig.api_server}/api/matters/${matter_id}/debtors/${debtor_id}/upload_invoice`, data, {
          headers: { "Content-Type": "multipart/form-data" },
        }),
      removeFile: (matter_id, debtor_id, file_id) =>
        ApiRequest.delete(`${AppConfig.api_server}/api/matters/${matter_id}/debtors/${debtor_id}/remove_file/${file_id}`),
      credit_checks: {
        all: (matter_id, debtor_id) =>
          ApiRequest.get(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/credit_checks`
          ),
        newReport: (matter_id, debtor_id, reportType, creditId) =>
          ApiRequest.get(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/credit_checks/new/${reportType}/${creditId}`
          ),
        report: (matter_id, debtor_id, reportId) =>
          ApiRequest.get(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/credit_checks/report/${reportId}`
          ),
      },
      debt: {
        create: (matter_id, debtor_id, data) =>
          ApiRequest.post(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/debt`,
            data
          ),
        edit: (matter_id, debtor_id, debt_id, data) =>
          ApiRequest.patch(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/debt/${debt_id}`,
            data
          ),
        addTransaction: (matter_id, debtor_id, debt_id, data) =>
          ApiRequest.post(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/debt/${debt_id}/transaction`,
            data
          ),

        // importTransactions: (matter_id, debtor_id, debt_id, file, parent, onUploadProgress) => {
        importTransactions: (matter_id, debtor_id, debt_id, file, onUploadProgress) => {
            let formData = new FormData();
            formData.append("file", file);
            // formData.append("parent", JSON.stringify(parent))
            return ApiRequest.post(
              AppConfig.api_server + 
              `/api/matters/${matter_id}/debtors/${debtor_id}/debt/${debt_id}/import_transactions`, 
                formData, {
                  headers: { "Content-Type": "multipart/form-data" },
                  onUploadProgress
                }
              )
          },

        editTransaction: (matter_id, debtor_id, debt_id, data,transaction_id) =>
          ApiRequest.patch(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/debt/${debt_id}/transaction/${transaction_id}`,
            data
          ),
        getAll: (matter_id, debtor_id, additional = {}) =>
          ApiRequest.patch(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/debt`, 
              additional
          ),
        getOne: (matter_id, debtor_id, debt_id) =>
          ApiRequest.get(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/debt/${debt_id}`
          ),
        update: (contact_id, debt_id, data) =>
          ApiRequest.patch(
            AppConfig.api_server +
              "/api/contacts/" +
              contact_id +
              "/debt/" +
              debt_id,
            data
          ),
        interest: (matter_id, debtor_id, debt_id) =>
          ApiRequest.get(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/debt/${debt_id}/calculate_interest`
          ),
      },
      activities: {
        create: (matter_id, debtor_id, data) =>
          ApiRequest.post(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/activities`,
            data
          ),
        list: (matter_id, debtor_id) =>
          ApiRequest.get(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/activities/list`
          ),
        update: (matter_id, debtor_id, activity_id, data) =>
          ApiRequest.patch(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/activities/${activity_id}`,
            data
          ),
        updateStatus: (matter_id, debtor_id, activity_id) =>
          ApiRequest.patch(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/activities/${activity_id}/status`,
            {}
          ),
        get: (matter_id, debtor_id, activity_id) =>
          ApiRequest.get(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/activities/${activity_id}`
          ),
      },
      tasks: {
        create: (matter_id, debtor_id, data) =>
          ApiRequest.post(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/tasks`,
            data
          ),
        list: (matter_id, debtor_id) =>
          ApiRequest.get(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/tasks/list`
          ),
        update: (matter_id, debtor_id, task_id, data) =>
          ApiRequest.patch(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/tasks/${task_id}`,
            data
          ),
        updateStatus: (matter_id, debtor_id, task_id) =>
          ApiRequest.patch(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/tasks/${task_id}/status`,
            {}
          ),
        get: (matter_id, debtor_id, task_id) =>
          ApiRequest.get(
            AppConfig.api_server +
              `/api/matters/${matter_id}/debtors/${debtor_id}/tasks/${task_id}`
          ),
      },
    },
  },
  clio: {
    practice_areas: {
      getAll: () =>
        ApiRequest.get(
          AppConfig.api_server + "/api/external_providers/clio/practice_areas"
        ),
    },
    users: {
      getAll: () =>
        ApiRequest.get(
          AppConfig.api_server + "/api/external_providers/clio/users"
        ),
    },
    documents: {
      upload: (file, parent, onUploadProgress) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("parent", JSON.stringify(parent))
        return ApiRequest.post(`${AppConfig.api_server}/api/external_providers/clio/files/upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress
        })
      },
      removeFile: clioId => ApiRequest.delete(`${AppConfig.api_server}/api/external_providers/clio/files/${clioId}`),
      downloadFile: clioId => ApiRequest.get(`${AppConfig.api_server}/api/external_providers/clio/files/${clioId}/download/latest`, {
        responseType: "blob"
      })
    }
  },
  external_providers: {
    credit_safe: {
      searchCompanies: (query, page = 1, pageSize = 10) =>
        ApiRequest.get(
          AppConfig.api_server +
            "/api/external_providers/credit_safe/companies",
          {
            params: query,
          }
        ),
    },
  },
  settings,
};
export default API;
