import React, {useState, useEffect} from "react";
import query_string from "query-string";
import Authentication from "../authentication";
import Bridge from "../bridge";
import API from "../api";
import Loader from "./loader";
import AuthenticationContext from "../_context/authentication";
import "../login_style.css";
import IdleTimeout from "./idleTimeout";
import { LockOpenIcon, XCircleIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";

import DisabledContent from "./account-disabled";
import LicenceMissingContent from "./account-licence";
import NewAccountContent from "./account-new-first";
import FrontEnd from "./front-end"

const defaultConfig = {
  applicationServer: "",
  applicationRoot: window.location.protocol + "//" + window.location.hostname + (window.location.port ? ":" + window.location.port : "") + "/",
  localPrefix: "authentication__"
};
const LoginWrapper  = ({config, children}) => {
  const [isLoggedOut, setIsLoggedOut]   = useState(false);
  const [isLoading, setIsLoading]       = useState(true);
  const [isIdleLogout, setIsIdleLogout] = useState(false);
  const [timeOut, setTimeOut] = useState(false);
  const [isLoggedIn, setIsLoggedIn]     = useState(false);
  const [userProfile, setUserProfile]   = useState({});
  const [authError, setAuthError]       = useState("");
  const [idleConfig, setIdleConfig] = useState({
    timeout: 15,
    preWarning: 5
  });

  const history = useHistory();

  const triggerLoginRedirect = () => {
    API.loginUrl(Bridge.getConfig("applicationRoot")).then(res => {
      if (res.goto !== undefined && res.goto.length > 5){
        window.location = res.goto;
      } else {
        // TODO error
      }
    }).catch(err => {
      // TODO error
    });
  };
  const _handleLogout  = () => {
    API.logout().then(() => {

    }).catch(err => {
      // TODO error
    });
    Authentication.setAccessToken(null);
    Authentication.setUserData({});
    Authentication.setUserScopes([]);
    setIsLoggedOut(true);
    setIsLoggedIn(false);
    setUserProfile({});
  };

  useEffect(() => {
    Bridge.setConfig({
      ...defaultConfig,
      ...config
    });
  }, [config]);
  useEffect(() => {
    const urlParams    = query_string.parse(window.location.search);
    const access_token = Authentication.getAccessToken();
    if (urlParams.code !== undefined){
      API.codeExchange(urlParams.code, Bridge.getConfig("applicationRoot")).then(res => {
        API.userDetails(res.access_token).then(details => {
          Authentication.setAccessToken(res.access_token);
          Authentication.setUserData(details.profile);
          Authentication.setUserScopes(details.scopes);

          // Force the page to reload to the root so we can start fresh
          window.location = "/";
        }).catch(() => {
          setAuthError("Either your session has expired or is invalid. Please login again and if the issue presets contact support.");
          setIsLoading(false);
        });
      }).catch((err) => {
        setAuthError(err.response.data.message);
        // setAuthError(err.response.data.message || "Either your session has expired or is invalid. Please login again and if the issue presets contact support.");
        setIsLoading(false);
      });
    } else if (access_token !== undefined && access_token !== null){
      API.userDetails(access_token).then(res => {
        Authentication.setUserData(res.profile);
        Authentication.setUserScopes(res.scopes);

        setIsLoggedIn(true);
        setIsLoading(false);
        setUserProfile(res.profile);
        setIdleConfig(res._idleTimeout);
      }).catch(err => {
        setAuthError("Your session has expired. Please login again to continue.");
        setTimeOut(true);
        setIsLoading(false);
        Authentication.setAccessToken(null);
        Authentication.setUserData({});
        Authentication.setUserScopes([]);
      })
    } else {
      setIsLoading(false);
      // triggerLoginRedirect();
    }
  }, []);

  /** Renders **/

  if (isLoading){
    return (
      <Loader>Please Wait...</Loader>
    );
  }
  if (authError){
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">There was an error with authentication!</h3>
            <div className="mt-2 text-sm text-red-700">
              <ul role="list" className="list-disc space-y-1 pl-5">
                <li>{authError}</li>
              </ul>
             {timeOut && <button
                onClick={() => history.push('./')}
                type="button"
                className="mt-5 inline-flex items-center gap-x-1.5 rounded-md bg-primary py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              >
                <LockOpenIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Login
              </button>}
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (isIdleLogout){
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">There was an error with authentication!</h3>
            <div className="mt-2 text-sm text-red-700">
              <ul role="list" className="list-disc space-y-1 pl-5">
                <li>Due to account inactivity and to protect your account, we have logged you out.</li>
              </ul>
              <button
                onClick={() => history.push('./')}
                type="button"
                className="mt-5 inline-flex items-center gap-x-1.5 rounded-md bg-primary py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              >
                <LockOpenIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (isLoggedOut){
    return (
      <p>You have been logged out, please close the web browser.</p>
    );
  }
  if (isLoggedIn){

    // GOD account is god, he is all knowing and always has access!!

    //plc_admin account is Martin, he needs access to organisations on the system not just self!

    if(userProfile.groups && !userProfile.groups.includes("god") && !userProfile.groups.includes("plc_admin")){

      if(userProfile.company && !userProfile.company.isClioLoggedIn){

         //todo: clio user has a account_owner flag which is needed to be checked when logging in!

        return <NewAccountContent logout={_handleLogout} />
      }else if(!userProfile.active){      
        return <DisabledContent logout={_handleLogout} />
      }else if(!userProfile.licence_transaction){
        return <LicenceMissingContent logout={_handleLogout} />
      }

    }

    return (
      <AuthenticationContext.Provider value={{
        user: userProfile,
        logout: () => _handleLogout()
      }}>
        <IdleTimeout onIdleExpiry={() => {
          setIsIdleLogout(true);
          _handleLogout();
        }} onHeartbeat={() => {
          API.heartbeat(Authentication.getAccessToken()).catch(err => {
            if (err.response !== undefined){
              if (err.response.status === 401){
                // Session expired
                setIsIdleLogout(true);
                _handleLogout();
              }
            }
          })
        }} maxIdleTime={idleConfig.timeout} preWarningTime={idleConfig.preWarning}/>

        {children}
      </AuthenticationContext.Provider>
    );
  }

  return <FrontEnd redirectLogin={triggerLoginRedirect} />

  return (
    <Loader>Redirecting to login screen...</Loader>
  );
}
export default LoginWrapper;