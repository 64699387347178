import React, { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import {
  ChevronDownIcon,
  XIcon,
  ChevronLeftIcon,
} from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";
import MatterReciever from "../../../contexts/matter_context/Matter_Receiver";
import DebtorReciever from "../../../contexts/debtor_context/Debtor_Receiver";
import ChainedContext from "../../../contexts/chainedContext";

const DebtorNav = ({ match, matter, debtor, history }) => {
  const solutions = [
    {
      name: "Debtor",
      description: "Air Log",
      href: `${match.url}`,
      exact: true,
    },
    // {
    //   name: "Edit",
    //   description: "Edit",
    //   href: `${match.url}/edit`,
    //   exact: true,
    // },
    {
      name: "Credit Check",
      description: "Credit check",
      href: `${match.url}/credit_checks`,

      exact: false,
    },
    {
      name: "Activities",
      description: "Activities",
      href: `${match.url}/activities`,
      exact: false,
    },
    // {
    //   name: "Tasks",
    //   description: "Tasks",
    //   href: `${match.url}/tasks`,
    //   exact: false,
    // },
    {
      name: "The Debt",
      description: "The Debt",
      href: `${match.url}/debt`,
      exact: false,
    },
  ];

  return (
    <Disclosure as="nav" className="bg-subMenuPrimary sticky-item-sub-nav">
      {({ open }) => (
        <>
          <div className=" mx-auto  px-2 border-b border-subMenuPrimary">
            <div className="flex items-center justify-between h-12">
              <div className=" flex-1 items-center">
                <div className="hidden md:block">
                  <div className="flex-1 flex items-center  justify-between text-center space-x-4">
                    <div className="hidden cursor-pointer lg:flex gap-2 antialiased text-md px-2 py-2 justify-center items-center text-subMenuPrimaryTitle tracking-wider">
                      <ChevronLeftIcon className="h-6" />
                      <span
                        onClick={() =>
                          history.push(`/matters/${matter._id}/debtors`)
                        }
                      >
                        Back To Matter
                      </span>

                      {solutions
                        .filter((s) => {
                          return s.hide !== true;
                        })
                        .map((item, itemIdx) => (
                          <Fragment key={itemIdx}>
                            {item.external ? (
                              <a
                                href={item.href}
                                target="_blank"
                                rel="noreferrer"
                                alt={item.description}
                              >
                                {item.name}
                              </a>
                            ) : (
                              <NavLink
                                exact={item.exact}
                                to={item.href}
                                activeClassName="bg-subMenuActive shadow"
                                className=" px-2 py-2 rounded-md text-md text-subMenuPrimaryText font-medium hover:bg-subMenuHover hover:text-subMenuHoverText tracking-wider"
                                aria-current={item.current ? "page" : undefined}
                              >
                                {item.name}
                              </NavLink>
                            )}
                          </Fragment>
                        ))}
                    </div>
                    {/* <div className="text-xl tracking-wider text-gray-700">
                      {debtor.debtor_name}{" "}
                      <span className="italic text-sm">
                        (
                        {debtor.companyRegistrationNumber
                          ? debtor.companyRegistrationNumber
                          : "--"}
                        )
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="hidden sm:flex gap-2 antialiased text-sm ml-auto px-2 py-2 justify-center items-center text-gray-400 tracking-wide">
                {matter.name}{" "}
              </div>
              <div className="-mr-2 flex lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-gray-200 inline-flex items-center justify-between p-2 rounded-md text-primary hover:bg-gray-300 focus:outline-none">
                  <div className="flex gap-2">
                    <span>Debtor menu</span>
                  </div>
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon
                      className="block h-6 w-6"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {solutions
                .filter((s) => {
                  return s.hide !== true;
                })
                .map((item, itemIdx) => (
                  <Fragment key={itemIdx}>
                    <NavLink
                      exact
                      to={item.href}
                      activeClassName="bg-gray-150 "
                      className="text-primary hover:bg-gray-300  block px-3 py-2 rounded-md text-base font-medium"
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </NavLink>
                  </Fragment>
                ))}
            </div>
          </Disclosure.Panel>
          {/* <div className="flex gap-2 border-b border-primary antialiased text-xl ml-auto px-2 py-2 justify-center items-center text-gray-800 font-medium tracking-wide">
            {debtor.debtor_name}{" "}
            <span className="italic text-sm">
              (
              {debtor.companyRegistrationNumber
                ? debtor.companyRegistrationNumber
                : "--"}
              )
            </span>
          </div> */}
        </>
      )}
    </Disclosure>
  );
};

export default ChainedContext(DebtorNav, [
  [
    MatterReciever,
    (matter) => {
      return { matter };
    },
  ],
  [
    DebtorReciever,
    (debtor) => {
      return { debtor };
    },
  ],
]);
