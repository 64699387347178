import React, { useEffect, useState } from "react";
import { Button, Dropdown, Group, Input } from "../../components/Forms";
import Segment from "../../components/Segment";
import ContactReceiver from "../../contexts/settings_context/Settings_Receiver";
import ChainedContext from "../../contexts/chainedContext";
import { ClipboardIcon } from "@heroicons/react/solid";
import API from "../../api";
import CronOverview from "./_components/CronOverview.js";
import { GiLinkedRings, GiSave } from "react-icons/gi";
import { Dialog, Switch, Transition } from "@headlessui/react";
import toast from "react-hot-toast"

function Dashboard({ settings }) {
  const [localSettings, setLocalSettings] = useState({});
  const [org, setOrg] = useState({});
  const [data, setData] = useState({});
  const [loadingUpdatePractice, setLoadingUpdatePractice] = useState(false);

  useEffect(() => {
    setLocalSettings(settings);
    API.organisations.getMyOrg().then(org => {
      setOrg(org); 
      setData({
        creditSafeShowPassword: false,
        creditSafe: org.creditSafe,
        xeroAccountsEmail: org.xeroAccountsEmail,
        xeroCurrency: org.xeroCurrency,
        xeroEInvoice: org.xeroEInvoice,
        xeroReference: org.xeroReference,
        xeroSetupAccountCode: org.xeroSetupAccountCode,
        xeroSetupDescription: org.xeroSetupDescription,
        xeroSetupDueDays: org.xeroSetupDueDays,
        xeroSetupItemCode: org.xeroSetupItemCode,
        xeroStatus: org.xeroStatus,
        xeroSuccessAccountCode: org.xeroSuccessAccountCode,
        xeroSuccessDescription: org.xeroSuccessDescription,
        xeroSuccessDueDays: org.xeroSuccessDueDays,
        xeroSuccessItemCode: org.xeroSuccessItemCode,
        xeroScaleItemCode: org.xeroScaleItemCode,
        xeroScalesDescription: org.xeroScalesDescription,
        xeroScaleDueDays: org.xeroScaleDueDays,
        xeroScaleAccountCode: org.xeroScaleAccountCode,
        xeroTax: org.xeroTax,
        xeroTaxRate: org.xeroTaxRate,
        _xeroConnectedTenants: org._xeroConnectedTenants,
        _xeroTenantId: org._xeroTenantId,
        _xeroItems: org._xeroItems,
        _xeroAccounts: org._xeroAccounts,
      })
    });
  }, [settings]);

  const updateSettings = () => {
    API.settings.edit(localSettings).then(() => settings._refresh());
  };

  const updateXero = () => {
    API.organisations.update(org._id, data).then(() => {
      toast.success("Xero Settings updated successfully");
      settings._refresh()
    });
  };

  const syncPracticeAreas = () => {
    setLoadingUpdatePractice(true);
    API.settings.syncPracticeAreas().then(() => {
      settings._refresh()
      setLoadingUpdatePractice(false);
    });
  }

  let classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className="sm:grid grid-cols-2">
      <Segment>
        <div className="flex">
          <Input
            type="number"
            label="Default Admin Rate (£/hr)"
            name={"adminRate"}
            value={localSettings.adminRate}
            onChange={(e) =>
              setLocalSettings({
                ...localSettings,
                [e.target.name]: e.target.value,
              })
            }
            className="w-9/12 md:w-10/12"
          />
          <Button
            icon={GiSave}
            colour="warning"
            label="Update"
            className="mx-auto ml-5 mt-auto h-fit w-fit text-center"
            onClick={() => updateSettings()}
          />
        </div>
        <br/>
        <div className="flex">
          <div class="w-9/12 md:w-10/12">
            <label class="block text-sm font-medium text-gray-700">Sync Practice Areas</label>
            <i>Last Updated: {localSettings.practiceAreasSync ? localSettings.practiceAreasSync : "N/A"}</i>
          </div>          
          <Button
            disabled={loadingUpdatePractice}
            icon={ClipboardIcon}
            colour="warning"
            label="Sync"
            className="mx-auto ml-5 mt-auto h-fit w-fit text-center"
            onClick={() => syncPracticeAreas()}
          />
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  CreditSafe
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Your CreditSafe link details
                </p>
              </div>
            </div>
            <div className="md:col-span-2">
              <Input
                label="Username"
                name="username"
                value={data.creditSafe && data.creditSafe.username}
                onChange={(e) => setData({...data, 
                  creditSafe: {
                    ...data.creditSafe,
                    [e.target.name]: e.target.value
                  } })}
              />
              <Input
                label="Password"
                name="password"
                type={data.creditSafeShowPassword ? "text" : "password"}
                value={data.creditSafe && data.creditSafe.password}
                onChange={(e) => setData({...data, 
                  creditSafe: {
                    ...data.creditSafe,
                    [e.target.name]: e.target.value
                  } })}
              /> 
              <br/>
              <Switch.Group
                as="div"
                className="flex items-center"
              >
                <Switch
                  checked={data.creditSafeShowPassword}
                  onChange={(e) => setData({...data,
                      creditSafeShowPassword: !data.creditSafeShowPassword })}
                  className={classNames(
                    data.creditSafeShowPassword ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      data.creditSafeShowPassword
                        ? "translate-x-5"
                        : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3">
                  <span className="text-sm font-medium text-gray-900">
                    Show Password
                  </span>
                </Switch.Label>
              </Switch.Group>
              <br/>
              <Switch.Group
                as="div"
                className="flex items-center"
              >
                <Switch
                  checked={data.creditSafe && data.creditSafe.sandbox}
                  onChange={(e) => setData({...data, 
                    creditSafe: {
                      ...data.creditSafe,
                      sandbox: !data.creditSafe.sandbox
                    } })}
                  className={classNames(
                    data.creditSafe && !data.creditSafe.sandbox ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      data.creditSafe && !data.creditSafe.sandbox
                        ? "translate-x-5"
                        : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3">
                  <span className="text-sm font-medium text-gray-900">
                    {data.creditSafe && data.creditSafe.sandbox ? "Sandbox" : "Live"} Mode
                  </span>
                </Switch.Label>
              </Switch.Group>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Xero - Connected Tenant
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Connection for the Organisation to the Xero Tenant <br/>
                  <Button
                    disabled={loadingUpdatePractice}
                    icon={GiLinkedRings}
                    colour="warning"
                    label="Get Tenants"
                    className="mx-auto ml-5 mt-auto h-fit w-fit text-center"
                    onClick={() => {
                      API.organisations.connectXero().then((url) => {
                        window.open(url, '_blank').focus();
                      });
                    }}
                  />
                </p>
              </div>
            </div>
            <div className="md:col-span-2">
              <Dropdown 
                  label="Connected Tenant"
                  name="_xeroTenantId"
                  placeholder="Select Tenant"
                  disabled={!data._xeroConnectedTenants || data._xeroConnectedTenants.length === 0}
                  value={data._xeroTenantId ? data._xeroTenantId : -1}
                  options={data._xeroConnectedTenants ? data._xeroConnectedTenants.map(t => {
                    return {text: t.tenantName, value: t.tenantId}
                  }) : [{text: "< < Use Get Tenants then refresh Page!", value: -1}]}
                  onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                />
              <p>Tenant: {org._xeroTenantId ? org._xeroTenantName : "Not Connected"}</p><br/>
              
              <br/>
              <i>*this will open in a new window/tab then you can close the window</i>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Global Xero
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Global Settings for all Invoices sent to Xero <br/><br/>
                  <strong>Status:</strong>
                  <br/>
                  <ul>
                    <li><strong>Draft:</strong> Sends over to Xero as DRAFT, will need to be approved and sent in Xero.</li>
                    <li><strong>Approve:</strong> Sends over to Xero as SUBMITTED, can be sent later to email.</li>
                    <li><strong>Approve & Send:</strong> Sends over to Xero as SUBMITTED, will send out the email to the Xero contact email address.</li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="md:col-span-2">
            <React.Fragment>
              <Group className="py-2">
                <Dropdown 
                  label="Currency"
                  name="xeroCurrency"
                  value={data.xeroCurrency}
                  options={[
                    {text: "GBP", value: "GBP"},
                    {text: "EUR", value: "EUR"},
                    {text: "USD", value: "USD"},
                  ]}
                  onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                />
                <Dropdown 
                  label="Tax"
                  name="xeroTax"
                  value={data.xeroTax}
                  options={[
                    {text: "Inclusive", value: "Inclusive"},
                    {text: "Exclusive", value: "Exclusive"},
                    {text: "No Tax", value: "NoTax"},
                  ]}
                  onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                />
                <Dropdown 
                  label="Tax Rate"
                  name="xeroTaxRate"
                  value={data.xeroTaxRate}
                  options={[
                    {text: "20% (VAT on income)", value: "20percent"},
                    {text: "5% (VAT on income)", value: "5percent"},
                    {text: "Exempt Income", value: "exempt"},
                    {text: "No VAT", value: "novat"},
                    {text: "Zero Rated EC Goods Income", value: "zeroECgoods"},
                    {text: "Zero Rated EC Services ", value: "zeroECservices"},
                    {text: "Zero Rated Income ", value: "zerorated"},
                  ]}
                  onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                />
                  <Dropdown 
                    label="Status"
                    name="xeroStatus"
                    value={data.xeroStatus}
                    options={[
                      {text: "Draft", value: "DRAFT"},
                      {text: "Approve", value: "SUBMITTED"},
                      {text: "Approve & Send", value: "SEND"},
                    ]}
                    onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                  />
              </Group>
              <Group className="py-2">
                <div>
                  <Input
                    label="Reference Format"
                    name="xeroReference"
                    value={data.xeroReference}
                    onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                  /><br/>
                  <p>Format Example: {data.xeroReference}#PLF Invoice Number#</p>
                </div>
                <p>Emails will be sent to the Primary Matter Contact on Xero.</p>
                {/*  
                <Input
                    label="Accounts Email Address"
                    name="xeroAccountsEmail"
                    type="email"
                    value={data.xeroAccountsEmail}
                    onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                  />*/}
                  <Switch.Group
                      as="div"
                      className="flex items-center"
                    >
                      <Switch
                        checked={data.xeroEInvoice}
                        onChange={() =>  setData({ ...data, xeroEInvoice: !data.xeroEInvoice })}
                        className={classNames(
                          data.xeroEInvoice ? "bg-primary" : "bg-gray-200",
                          "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        )}
                      >
                        <span
                          aria-hidden="true"
                          className={classNames(
                            data.xeroEInvoice
                              ? "translate-x-5"
                              : "translate-x-0",
                            "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                          )}
                        />
                      </Switch>
                      <Switch.Label as="span" className="ml-3">
                        <span className="text-sm font-medium text-gray-900">
                          Send as eInvoice (future dev)
                        </span>
                      </Switch.Label>
                    </Switch.Group>
              
              </Group>
              </React.Fragment>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Xero - Setup Fee
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Settings for the Setup Fees with Xero 
                </p>
              </div>
            </div>
            <div className="md:col-span-2">
            <React.Fragment>
              <Group className="py-2">
                {/*  
                <Input
                    label="Item Code"
                    name="xeroSetupItemCode"
                    value={data.xeroSetupItemCode}
                    onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                  />*/}

                <Dropdown 
                  label="Item Code"
                  name="xeroSetupItemCode"
                  placeholder="Select Item Code"
                  // disabled={!data._xeroTenantId}
                  value={data.xeroSetupItemCode}
                  options={data._xeroItems ? data._xeroItems.map(i => {
                    return {text: `${i.name} (${i.code})`, value: i.code}
                  }) : []}
                  onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                />

                <Input
                    label="Description"
                    name="xeroSetupDescription"
                    value={data.xeroSetupDescription}
                    onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                  />
                <Input
                    label="DueDays"
                    type="number"
                    min="0"
                    name="xeroSetupDueDays"
                    value={data.xeroSetupDueDays}
                    onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                  />
                  {/*  
                <Input
                    label="AccountCode"
                    name="xeroSetupAccountCode"
                    value={data.xeroSetupAccountCode}
                    onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                  />*/}
                <Dropdown 
                    label="AccountCode"
                    name="xeroSetupAccountCode"
                    placeholder="Select Item Code"
                    // disabled={!data._xeroTenantId}
                    value={data.xeroSetupAccountCode}
                    options={data._xeroAccounts ? data._xeroAccounts.map(i => {
                      return {text: `${i.name} (${i.code})`, value: i.code}
                    }) : []}
                    onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                 />
              </Group>
              </React.Fragment>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Xero - Success Fee
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Settings for the Success Fees with Xero 
                </p>
              </div>
            </div>
            <div className="md:col-span-2">
            <React.Fragment>
              <Group className="py-2">
                <Dropdown 
                  label="Item Code"
                  name="xeroSuccessItemCode"
                  placeholder="Select Item Code"
                  // disabled={!data._xeroTenantId}
                  value={data.xeroSuccessItemCode}
                  options={data._xeroItems ? data._xeroItems.map(i => {
                    return {text: `${i.name} (${i.code})`, value: i.code}
                  }) : []}
                  onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                />
                <Input
                    label="Description"
                    name="xeroSuccessDescription"
                    value={data.xeroSuccessDescription}
                    onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                  />
                <Input
                    label="DueDays"
                    type="number"
                    min="0"
                    name="xeroSuccessDueDays"
                    value={data.xeroSuccessDueDays}
                    onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                  />
                <Dropdown 
                    label="AccountCode"
                    name="xeroSuccessAccountCode"
                    placeholder="Select Item Code"
                    // disabled={!data._xeroTenantId}
                    value={data.xeroSuccessAccountCode}
                    options={data._xeroAccounts ? data._xeroAccounts.map(i => {
                      return {text: `${i.name} (${i.code})`, value: i.code}
                    }) : []}
                    onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                 />
              </Group>
              </React.Fragment>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Xero - Payment Stage Fee
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Settings for the Payment Stage Fees with Xero 
                </p>
              </div>
            </div>
            <div className="md:col-span-2">
            <React.Fragment>
              <Group className="py-2">
                <Dropdown 
                  label="Item Code"
                  name="xeroScaleItemCode"
                  placeholder="Select Item Code"
                  // disabled={!data._xeroTenantId}
                  value={data.xeroScaleItemCode}
                  options={data._xeroItems ? data._xeroItems.map(i => {
                    return {text: `${i.name} (${i.code})`, value: i.code}
                  }) : []}
                  onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                />
                <Input
                    label="Description"
                    name="xeroScalesDescription"
                    value={data.xeroScalesDescription}
                    onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                  />
                <Input
                    label="DueDays"
                    type="number"
                    min="0"
                    name="xeroScaleDueDays"
                    value={data.xeroScaleDueDays}
                    onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                  />
                <Dropdown 
                    label="AccountCode"
                    name="xeroScaleAccountCode"
                    placeholder="Select Item Code"
                    // disabled={!data._xeroTenantId}
                    value={data.xeroScaleAccountCode}
                    options={data._xeroAccounts ? data._xeroAccounts.map(i => {
                      return {text: `${i.name} (${i.code})`, value: i.code}
                    }) : []}
                    onChange={(e) => setData({...data, [e.target.name]: e.target.value})}
                 />
              </Group>
              </React.Fragment>
            </div>
          </div>
        </div>

        <Button
            icon={GiSave}
            colour="warning"
            label="Save"
            className="mx-auto ml-5 mt-auto h-fit w-fit text-center"
            onClick={() => updateXero()}
          />


      </Segment>
      <Segment>
        <CronOverview />
      </Segment>
    </div>
  );
}

export default ChainedContext(Dashboard, [
  [
    ContactReceiver,
    (settings) => {
      return { settings };
    },
  ],
]);
