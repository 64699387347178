import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Button,
  DateSelect,
  Input,
  SelectTwo,
} from "../../../../../components/Forms";
import { useParams } from "react-router-dom";

import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import {InterestTypes} from "../../../../../_lists";
import API from "../../../../../api";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import DocumentsUploader from "../../../_components/DocumentsUploader";
import Select from "react-tailwindcss-select";
import CommonFunctions from "../../../../../CommonFunctions";

const resetData = {
  amount: 0,
  rateType: "contractual",
  invoice_num: "",
  details: "",
  interest: 0,
  interestDate: "",
  invoiceDate: "",
  compensation: 0,
  invoices: [],
};
export default function Add_Debt_Form({ data, contact_settings, interest_rate, matter_settings, validateSubmit, edit = false, selectDebtor = false }) {
  const params = useParams();
  const [isUploading, setIsUploading] = useState(false);
  const [showDocumentUploader, setShowDocumentUploader] = useState(true);
  const [debt, setDebt] = useState(resetData);
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});
  const [baseRate, setBaseRate] = useState({});
  const [latePaymentsBaseRate, setLatePaymentsBaseRate] = useState({});
  const [statutoryRate, setStatutoryRate] = useState([]);
  const [compensationRates, setCompensationRates] = useState([]);

  const [matterInterestRate, setMatterInterestRate] = useState(0);

  const [debtors, setDebtors] = useState([]);

  const currentYear = (new Date()).getFullYear();
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

  // const years = range(1990, getYear(new Date()) + 1, 1);
  const years = range(currentYear + 6, currentYear - 12, -1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {

    if (mountLoading && params.debt_id && edit) {
      Promise.all([
        API.matters.debtors.getAll(params.matter_id),
        API.matters.debtors.debt.getOne(params.matter_id, params.debtor_id ,params.debt_id),
        API.matters.get(params.matter_id)
      ])
        .then((res) => {
          if(res[0]){
            setDebtors(res[0].debtors.filter(f => f._id !== params.debtor_id).filter(a => a.debtor_name != " ").map((a) => {
              return {
                label: a.debtor_name,
                value: a._id,
              }
            }));

            if(res[0].debtors.length == 1) {
              setDebt({
                ...debt, 
                debtor: res[0].debtors[0]._id
              })
            }
          }

          let additionalDebtors = [];

          if(res[1].additionalDebtors && res[1].additionalDebtors.length > 0){
            res[1].additionalDebtors.forEach(a => {
              let d = res[0].debtors.filter(x => a === x._id);

              if(d.length > 0){
                additionalDebtors.push({
                  label: d[0].debtor_name,
                  value: d[0]._id 
                });
              }

            })
          }

          if(res[2]){
            setMatterInterestRate(res[2].interest_rate)
          }


         setDebt({
          ...res[1],
          additionalDebtors
         })
        })
        .finally(() => setMountLoading(false));
    }
    else if(mountLoading){

      API.matters.get(params.matter_id).then((res) => setMatterInterestRate(res.interest_rate));

      API.matters.debtors.getAll(params.matter_id).then((res) => {
        if(res.debtors){
          setDebtors(res.debtors.filter(f => f._id !== params.debtor_id).map((a) => {
            return {
              label: a.debtor_name,
              value: a._id,
            }
          }));
          
          if(res.debtors.length == 1) {
            setDebt({
              ...debt, 
              debtor: res.debtors[0]._id
            })
          }

        }
      });

      setMountLoading(false);

    }

  }, [params, mountLoading]);

  useEffect(() => {
    setDebt({ ...debt,interest: interest_rate});
    if (debt.invoiceDate != "" && (matter_settings.paymentTerms ? matter_settings.paymentTerms : contact_settings.paymentTerms)) {   
      let intDate = dayjs(debt.invoiceDate).add(
        (matter_settings.paymentTerms ? matter_settings.paymentTerms : contact_settings.paymentTerms),
        "day"
      );

          Promise.all([
            API.settings.base_rates.normal.get(),
            API.settings.base_rates.late_payments.get(),
            API.settings.base_rates.statutory.get(),
            API.settings.base_rates.compensation.get()
          ]).then(res => {
            let rates = {
              baseRate: 0,
              latePaymentsBaseRate: 0,
              statutoryRate: 0
            };

            if(res[0][0]){
              let f1 = res[0].filter(e => dayjs(debt.invoiceDate).diff(dayjs(e.date).startOf("day")) >= 0);
    
              if(f1[0]){
                setBaseRate(f1[0]);
                rates.baseRate = f1[0];
              }else{
                setBaseRate(res[0][0]);
                rates.baseRate = res[0][0];
              }
   
            }
            if(res[1][0]){

              let f2 = res[1].filter(e => dayjs(debt.invoiceDate).diff(e.date) >= 0);

              if(f2[0]){
                setLatePaymentsBaseRate(f2[0]);
                rates.latePaymentsBaseRate = f2[0];
              }else{
                setLatePaymentsBaseRate(res[1][0]);
                rates.latePaymentsBaseRate = res[1][0];
              }
            }
            if(res[2][0]){

              let f2 = res[2].filter(e => dayjs(debt.invoiceDate).diff(e.date) >= 0);

              if(f2[0]){
                setStatutoryRate(f2[0]);
                rates.statutoryRate = f2[0];
              }else{
                setStatutoryRate(res[2][0]);
                rates.statutoryRate = res[2][0];
              }
            }
            // setStatutoryRate(res[2]);
            // rates.statutoryRate = res[2];

            setCompensationRates(res[3]);

            setDebt({
              ...debt, 
              interest: updateInterest(debt.rateType, rates),
              interestDate: intDate
            })
          }).catch(err => {
            console.log("Add_Debt_Form.js:61 ~ useEffect ~ err", err)
          });

    }
  }, [debt.invoiceDate]);
  useEffect(() => {
    validate();
  }, [debt]);

  /*
  useEffect(() => {
    Promise.all([
      API.settings.base_rates.normal.get(),
      API.settings.base_rates.late_payments.get(),
      API.settings.base_rates.statutory.get()
    ]).then(res => {
      if(res[0][0]){
        setBaseRate(res[0][0]);
      }
      if(res[1][0]){
        setLatePaymentsBaseRate(res[1][0]);
      }
      setStatutoryRate(res[2]);
      setGetRates(false);
    }).catch(err => {
      console.log("Add_Debt_Form.js:61 ~ useEffect ~ err", err)
    });
  }, [getRates]); */
    
  const updateInterest = (e, rates, interest_plus = 0) => {
    //update interest rates

      let interest = debt.interest;
      switch(e){        
        
        case "simply":
          interest = 3;
        break;
        case "contractual":
          interest = matterInterestRate; //pull from Matter of the interest rate for contractual
        break;
        case "base_rate":
          interest = rates.baseRate ? rates.baseRate.rate : 0;
        break;
        case "base_rate_plus":
          interest = (rates.baseRate ? rates.baseRate.rate : 0) + interest_plus;
        break;
        case "statutory":
          interest = rates.statutoryRate ? rates.statutoryRate.rate : 0;
        break;
        case "late_payment":
          interest = rates.latePaymentsBaseRate ? rates.latePaymentsBaseRate.rate : 0;
        break;
      }

      return interest;

  };


  const onChange = (e) => {
    if(e.target.name === "rateType"){
      let rates = {baseRate,latePaymentsBaseRate,statutoryRate};
      let comp = compensationRates.filter(c => c.cap <= debt.amount).sort((a, b) => (a.cap < b.cap ? 1 : -1));
      setDebt({ 
        ...debt, 
        [e.target.name]: e.target.value, 
        compensation: e.target.value === "late_payment" ? comp.length > 0 ? comp[0].fee : 0 : 0, 
        interest: updateInterest(e.target.value, rates) 
      });
    }else{
      setDebt({ ...debt, [e.target.name]: e.target.value });
    }
  };
  const submitData = () => {
    if(isUploading){
      toast.error("Please wait for all documents to finish uploading before before continuing.");
      return;
    }

    if(Object.keys(warnings).length >= 1 || Object.keys(errors).length >= 1){
      toast.error("There are validation issues that must be resolved before continuing.");
      return;
    }


    data(debt);
    setDebt(resetData);
    setShowDocumentUploader(false);
    setTimeout(() => setShowDocumentUploader(true), 500);
    toast.success(`Debt ${edit ? "Edited" : "Added"} Successfully`);
  };
  const validate = () => {
    let warnings = {};
    if(debt.invoiceDate && (dayjs(debt.invoiceDate).diff(dayjs())) > 0){
      warnings = {warn: true, message: "Be Advised that the date selected is in the future!"}
    }
    if(debt.invoiceDate && (dayjs().diff(dayjs(debt.invoiceDate), "years")) >= 6){
      warnings = {warn: true, message: "Be Advised that the date selected 6+ years ago!"}
    }
    setWarnings(warnings);

    const errors = {};
    if (debt.amount === 0) errors.amount = true;
    if (!debt.interestDate) errors.interestDate = true;
    if (!debt.invoiceDate) errors.invoiceDate = true;
    if (!debt.rateType) errors.rateType = true;
    if (!debt.debtor && selectDebtor) errors.debtor = true;


    if((debt.invoiceDate || debt.interestDate || debt.rateType) && Object.keys(errors).length > 0) {
      errors.notcomplete = true;
    }

    setErrors(errors);
    validateSubmit(errors);
    return errors;
  };
  const getInterest = (value, interest) => {
    let fig1 = value / 100;
    let fig2 = interest / 365;
    return fig1 * fig2;
  };

  let days = dayjs().diff(dayjs(debt.interestDate), "day");
  let dailyInt = getInterest(debt.amount, debt.interest);
  let totalInt = getInterest(debt.amount, debt.interest) * days;
  let totalOwed =
    (debt.recoverable_legal_costs ? debt.recoverable_legal_costs : 0) +
    debt.amount +
    debt.compensation +
    getInterest(debt.amount, debt.interest) * days;


    if(mountLoading){
      return "Loading...";
    }

  return (
    <div className="w-full p-2 ">
      <div className="bg-red-500">
        {warnings && warnings.warn &&
        <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8 mb-5">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center">
              <span className="flex rounded-lg">
                <ExclamationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 truncate font-medium text-white">
                <span className="md:hidden"><b className="animate-pulse">Warning!</b> {warnings && warnings.message}</span>
                <span className="hidden md:inline"><b className="animate-pulse">Warning!</b> {warnings && warnings.message}</span>
              </p>
            </div>
          </div>
        </div> }
    </div>
      <div className="w-full gap-2">
        <div className="text-center pb-4 font-medium text-lg  border-b border-gray-300 mx-10 mb-2">
          Debt Form
        </div>
        
          {selectDebtor && <>
            <div className="grid grid-cols-3 gap-4 ">
              <div className="grid grid-cols-3 col-span-2 gap-4 border-r border-gray-100 p-2">
                <div className="w-full">
                    <SelectTwo
                      label="Primary Debtor"
                      field="debtor"
                      options={debtors.map((d, i) => {
                        return {
                          key: i, text: d.label, value: d.value
                        };
                      })}
                      value={debt.debtor}
                      error={errors.debtor}
                      onChange={onChange}
                      disabled={!debtors.length > 1}
                    />
                  </div>
                </div>
              </div>
          </>}
          <div className="grid grid-cols-3 gap-4 ">
          <div className="grid grid-cols-3 col-span-2 gap-4 border-r border-gray-100 p-2">  
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700">
                Invoice Date
              </label>
              <DateSelect
                onChange={(date) =>
                  setDebt({
                    ...debt,
                    invoiceDate: date,
                  })
                }
                value={debt.invoiceDate ? new Date(debt.invoiceDate) : ""}
                name="invoiceDate"
                className={`relative w-full p-2 ${
                  errors.invoiceDate ? "border border-red-400" : warnings && warnings.warn ? "border border-blue-500 animate-pulse" : ""
                } `}
              />
            </div>
            <Input
              label="Debt Amount"
              type={"number"}
              name={"amount"}
              onChange={(e) => {

                let comp = compensationRates.filter(c => c.cap <= e.target.value).sort((a, b) => (a.cap < b.cap ? 1 : -1));

                setDebt({ 
                  ...debt, 
                  compensation: debt.rateType === "late_payment" ? comp.length > 0 ? comp[0].fee : 0 : 0,
                  amount: parseFloat(e.target.value) 
                })
              }
                
              }
              placeholder="11111"
              value={debt.amount}
              error={errors.amount}
              center={true}
            />

            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700">
                Interest From Date{" "}
                {(matter_settings.paymentTerms ? matter_settings.paymentTerms : contact_settings.paymentTerms) && (
                  <span className="text-sm italic">
                    ({(matter_settings.paymentTerms ? matter_settings.paymentTerms : contact_settings.paymentTerms)} days)
                  </span>
                )}
              </label>
              <DateSelect
                onChange={(date) =>
                  setDebt({
                    ...debt,
                    interestDate: date,
                  })
                }
                value={debt.interestDate ? new Date(debt.interestDate) : ""}
                name="interestDate"
                className={`relative w-full  p-2 ${
                  errors.interestDate ? "border border-red-400" : ""
                }`}
              />
            </div>
            <SelectTwo
              label="Interest Type"
              field="rateType"
              options={InterestTypes}
              value={debt.rateType}
              error={errors.rateType}
              onChange={onChange}
            />
            {debt.rateType === "late_payment" ?
            <Input
              type="number"
              label={`Compensation ${debt.rateType === "late_payment" ? "(Late Payment)" : ""}`}
              name={"compensation"}
              onChange={(e) =>
                setDebt({ ...debt, compensation: parseFloat(e.target.value) })
              }
              placeholder="inv123"
              value={debt.compensation}
            /> : debt.compensation > 0 ? <div>
            <label>Compensation</label>
            <div>{debt.compensation}</div>
          </div> : ""}

            <div className="w-full">

              {debt.rateType === "monthly" && 
              
                <Input
                  label="Monthly Interest"
                  name={"interest_monthly"}
                  type={"number"}
                  onChange={(e) =>
                    setDebt({
                      ...debt,
                      interest: parseFloat(e.target.value) * 12,
                      interest_monthly: parseFloat(e.target.value),
                    })
                  }
                  placeholder=""
                  value={debt.interest_monthly}
                />
              
              }

              {debt.rateType === "contractual" ? (
                <Input
                  label="Interest %"
                  name={"interest"}
                  type={"number"}
                  onChange={(e) =>
                    setDebt({
                      ...debt,
                      interest: parseFloat(e.target.value),
                    })
                  }
                  placeholder=""
                  value={debt.interest}
                />
              ) : debt.rateType === "base_rate_plus" ? <span> Interest % (Base: {baseRate ? baseRate.rate : 0}) <Input
              label="Plus"
              name={"interest_plus"}
              type={"number"}
              onChange={(e) => {
                setDebt({
                  ...debt,
                  interest_plus: parseFloat(e.target.value),
                  interest: updateInterest("base_rate_plus", {baseRate: baseRate},parseFloat(e.target.value)),
                })
                
              }
                
              }
              placeholder=""
              value={debt.interest_plus}
            /> = {debt.interest}</span> : (
                <div>
                  <label>Interest</label>
                  <div>{debt.interest}</div>
                </div>
              )}
          </div>
            <Input
                label="Recoverable Legal Costs"
                name={"recoverable_legal_costs"}
                type={"number"}
                onChange={(e) =>
                  setDebt({
                    ...debt,
                    recoverable_legal_costs: parseFloat(e.target.value),
                  })
                }
                placeholder="0"
                value={debt.recoverable_legal_costs ? debt.recoverable_legal_costs : 0}
              />
            {/* <div className="col-span-2"> */}
              <Input
                label="Invoice Number"
                name={"invoice_num"}
                onChange={onChange}
                placeholder="inv123"
                value={debt.invoice_num}
                error={errors.invoice_num}
              />
              {debtors.length > 1 &&
              <div>
                <label for="additionalDebtors" class="block text-sm font-medium text-gray-700">Additional Debtors</label>
                <Select 
                    isMultiple
                    name='additionalDebtors'
                    size='normal'
                    options={debtors}
                    placeholder= 'Select Debtors Associated'
                    className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={Array.isArray(debt.additionalDebtors) ? debt.additionalDebtors : []}
                    onChange={(value) => {
                      setDebt({ ...debt, additionalDebtors: value })}
                    }
                  />
              </div>}
              
            {/* </div> */}
          </div>
          <div className="col-span-1">
            {showDocumentUploader && <DocumentsUploader title={"Invoices"} existingDocuments={debt.invoices} isUploadingInProgress={a => setIsUploading(a)} onUploadedDocument={documents => {
              setDebt(a => {
                return {
                  ...a,
                  invoices: documents
                }
              })
            }} />}
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mt-4 p-2">
          <div className="flex justify-between border border-gray-100 p-2">
            Days: <span>{days ? days : "--"}</span>
          </div>
          <div className="flex justify-between border border-gray-100 p-2">
            Interest Per Day:
            <span>£{debt.amount ? CommonFunctions.toFormattedFixed(dailyInt) : "0.00"}</span>
          </div>
          <div className="flex justify-between border border-gray-100 p-2">
            Interest Total:
            <span>£{debt.amount ? CommonFunctions.toFormattedFixed(totalInt) : "0.00"}</span>
          </div>
          <div className="flex justify-between border border-gray-100 p-2">
            Compensation:
            <span>
              £{debt.compensation ? CommonFunctions.toFormattedFixed(debt.compensation) : "0.00"}
            </span>
          </div>
          <div className="flex justify-between border border-gray-100 p-2">
            Legal Costs:
            <span>
              £{debt.recoverable_legal_costs ? CommonFunctions.toFormattedFixed(debt.recoverable_legal_costs) : "0.00"}
            </span>
          </div>
          <div className="flex justify-between border border-gray-100 p-2">
            Total Owed:
            <span>£{debt.amount ? CommonFunctions.toFormattedFixed(totalOwed) : "0.00"}</span>
          </div>
        </div>
        <div className="flex justify-between mt-3 border-t border-gray-100 py-4">
          <Button
            colour="alert"
            onClick={() => {
              setDebt(resetData);
              toast.success("Form reset.");
            }}
            label="Cancel"
          />
          <Button 
            colour={edit ? "warning" : "positive"}
            onClick={submitData} 
            // disabled={errors.notcomplete} 
            label={edit ? 'Edit & Recalculate' : 'Add'}
           />
        </div>
      </div>
    </div>
  );
}
