import React, { useState, useEffect } from "react";
import { ThemeTable } from "../../../components/Tables";
import { Button } from "../../../components/Forms";
import API from "../../../api";
import Segment from "../../../components/Segment";
import CommonFunctions from "../../../CommonFunctions";
import Loader from "../../../components/Loader";
import { PlusIcon } from "@heroicons/react/solid";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import ChainedContext from "../../../contexts/chainedContext";
import MatterBar from "../_components/MatterBar";
import MatterBreadcrumbs from "../_components/MatterBreadcrumbs";
import DebtSchedule from "../debtors/_components/DebtSchedule";
function DebtsList({ matter, history, match }) {
  const [matterDebtors, setMatterDebtors] = useState({});
  const [debts, setDebts] = useState([]);
  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    API.matters.debtors
      .getAll(matter._id)
      .then((res) => {
        setMatterDebtors(res);
        API.matters.debtors.debt.getAll(matter._id, 0, res.debtors ? res.debtors.map(d => d._id) : []).then((r) => {
          setDebts(r);
          setMountLoading(false);
        });
        
      })
        
      .catch((e) => CommonFunctions.handleError(e));
  }, [matter]);

  const handleEdit = (data) => {
    alert("bang!");
  };

  return (
    <div className="">
      <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number} ${matterDebtors.debtors ? `(${matterDebtors.debtors.map((d, i) => (i !== 0 ? " " : "") + d.debtor_name)})` : ""}`, href: `/matters/${matter._id}`, current: false },
        { name: "Debts", href: '#', current: true },
      ]} />
      <Segment>
        <MatterBar matter={matter} history={history} />
        {mountLoading ? (
          <Loader />
        ) : (
          <>
            <Button
              style={{float: 'right'}}
              colour="positive"
              label="Add Invoice"
              onClick={() => history.push(`${match.url}/add`)}
              disabled={!matterDebtors.debtors && matterDebtors.debtors.length > 0}
            />
            <Button
              style={{float: 'right'}}
              colour="action"
              label="Add Payment"
              onClick={() => history.push(`${match.url}/payment`)}
              disabled={!debts.length > 0}
            />
            <div className="md:grid md:grid-cols-2 md:gap-36 pt-3 pb-4">
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <div className="text-3xl">{matterDebtors.debtors && matterDebtors.debtors.length}</div>
                <div className="text-xl text-primary">Debtors</div>
              </div>
              <div className="border border-primary p-4 rounded-xl text-center mb-4 md:mb-0 md:mx-auto md:w-52">
                <div className="text-3xl">{debts.length}</div>
                <div className="text-xl text-primary">Debts</div>
              </div>
            </div>
            <DebtSchedule
              debts={debts}
              editData={handleEdit}
              match={match}
              history={history}
            />
          </>
        )}
      </Segment>
    </div>
  );
}
export default ChainedContext(DebtsList, [
  [
    MatterReceiver,
    (matter) => {
      return { matter };
    },
  ],
]);
