import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader";
import API from "../../api";
import Segment from "../../components/Segment";
import CommonFunctions from "../../CommonFunctions";
import dayjs from "dayjs";
import MatterReceiver from "../../contexts/matter_context/Matter_Receiver";
import ChainedContext from "../../contexts/chainedContext";
import Checklist from "../../components/_checklists";
import MatterBar from "./_components/MatterBar";
import MatterBreadcrumbs from "./_components/MatterBreadcrumbs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button, DateSelect } from "../../components/Forms";
import toast from "react-hot-toast";
import { Authentication } from "../../plugins/clio_auth";

function ShowMatter({ history, matter }) {
  const [matterDebtors, setMatterDebtors] = useState({});
  const [matterFees, setMatterFees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMilestones, setLoadingMilestones] = useState(false);
  const [applicableFee, setApplicableFee] = useState({});

  useEffect(() => {
    matter._refresh();
  }, []);

  useEffect(() => {
    if (matter._id) {
      setLoading(true);

      Promise.all([
        API.matters.debtors.getAll(matter._id),
        API.matters.fees.getAll(matter._id)]
      ).then((res) => {
        setMatterDebtors(res[0]);
        setMatterFees(res[1]);
      })
      .catch((e) => CommonFunctions.handleError(e))
      .finally(() => {
        setLoading(false);
      });

    }

    const feeThresholds = matter.fee_thresholds ? matter.fee_thresholds.map(fee => ({
      ...fee,
      threshold: parseFloat(fee.threshold)
    })) : [];

    const fee = feeThresholds.find((fee, index) => {
      const nextFee = feeThresholds[index + 1];
      return (
        (nextFee 
          ? fee.threshold > matterDebtors.totalOwed && matterDebtors.totalOwed <= nextFee.threshold
          : matterDebtors.totalOwed > fee.threshold)
      );
    });

    if (fee) {
      setApplicableFee(fee);
    }

  }, [matter]);

  const milestoneDate = (title, name) => {
    return (
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">
          {title}

          {Authentication.can("matter.milestones.update") && name !== "open_date" &&
          <Button
              style={{display: "inline-block", marginLeft: "3px"}}
              colour="alert"
              label="X"
              onClick={() => {
                setLoadingMilestones(true);
                API.matters.update(matter._id, {[name]: null}).then((res) => {
                  if(res.success){
                    toast.success("Milestone '"+title+"' date removed!")
                    matter._refresh();
                  }
                  setLoadingMilestones(false);

                });
              }}
            />}
        </dt>
        <dd className="mt-1 text-sm text-gray-900">
            
            <DateSelect
              name={name}
              value={matter[name]}
              disabled={matter.status === "closed"}
              onChange={(date) => {
                setLoadingMilestones(true);
                API.matters.update(matter._id, {[name]: date}).then((res) => {
                  if(res.success){
                    toast.success("Milestone '"+title+"' date updated!")
                    matter._refresh();
                  }
                  setLoadingMilestones(false);

                });
              }}
            />
            
        </dd>
      </div>

    );
  }  

  const feeThresholds = matter.fee_thresholds ? matter.fee_thresholds.map(fee => ({
    ...fee,
    threshold: parseFloat(fee.threshold)
  })) : [];

  return (
    <>
    <MatterBreadcrumbs panes={[
        { name: `Client - ${matter.contact && `${matter.contact.type == "Company" ? `${matter.contact.name} ${matter.contact.suffix ? matter.contact.suffix : ''}` : `${matter.contact.prefix} ${matter.contact.first_name} ${matter.contact.last_name} `} `}`, href: `/contacts/${matter.contact && matter.contact._id}`, current: false },
        { name: `Matter - ${matter.display_number} ${matterDebtors.debtors ? `(${matterDebtors.debtors.map((d, i) => (i !== 0 ? " " : "") + d.debtor_name)})` : ""}`, href: '#', current: true },
      ]} />
    
    <Segment>
      <MatterBar matter={matter} history={history} showEdit />

      <div className="md:grid md:grid-cols-2">
        <div className="p-4">
          <div className="border border-primary bg-white rounded-lg p-4 h-full">
            {loading ? (
              <Loader />
            ) : (
                <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Client
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.contact ? (
                            matter.contact.name + (matter.contact.suffix ? " "+matter.contact.suffix : "") ? (
                              <a
                                className="underline hover:text-blue-500"
                                href={`/contacts/${matter.contact._id}`}
                              >
                                {matter.contact.name}{matter.contact.suffix ? " "+matter.contact.suffix : ""}
                              </a>
                            ) : (
                              <a
                                className="underline hover:text-blue-500"
                                href={`/contacts/${matter.contact._id}`}
                              >
                                {matter.contact.first_name}{" "}
                                {matter.contact.middle_name}{" "}
                                {matter.contact.last_name}
                              </a>
                            )
                          ) : (
                            "--"
                          )}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Fee Earner
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.responsible_attorney
                            ? matter.responsible_attorney.name
                            : "--"}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Status
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.status ? matter.status : "--"}
                        </dd>
                      </div>
                    </dl>
                    <p>&nbsp;</p>
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                      {/*  
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Open Date
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.open_date
                            ? dayjs(matter.open_date).format("DD/MM/YYYY HH:mm")
                            : ""}
                        </dd>
                      </div>*/}
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Client Reference
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.client_reference}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Practice Area
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.practice_area
                            ? matter.practice_area.name
                            : "--"}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Billable
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.billable ? "Yes" : "No"}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Billing Method
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.billing_method ? matter.billing_method : "--"}
                        </dd>
                      </div>
                      
                      <div className="sm:col-span-3">
                        <dt className="text-sm font-medium text-gray-500">
                          Description
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {matter.description ? matter.description : "--"}
                        </dd>
                      </div>
                      <div className="sm:col-span-3">
                        <dt className="text-sm font-medium text-gray-500">
                          Fees
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <ul>
                            <li className={matter.upfront_fee > 0 ? "text-primary font-bold" : ""}> Upfront Fee (setup / LBA): {" "}
                              {matter.upfront_fee ? `${matter.upfront_fee_type == "fixed" ? "£" : ""}${matter.upfront_fee}${matter.upfront_fee_type == "percent" ? "%" : ""}` : `Not Set!!`} 
                            </li>
                              {feeThresholds && feeThresholds.length > 0 ? 
                                feeThresholds.map((fee, index) => {
                                  const isApplicable = (fee.fee == applicableFee.fee && fee.threshold === applicableFee.threshold && fee.over_threshold === applicableFee.over_threshold) ? true : false ;

                                  return (
                                    <li key={index} className={isApplicable ? "text-primary font-bold" : ""}>
                                      - 
                                      
                                      {matter.scale == "stageScale" ? <>
                                      Stage: {fee.stage} - Fee: £{fee.fee.toLocaleString()}
                                    </> :
                                    <>
                                      {`${fee.over_threshold ? "Over:" : "Up to:"} £${fee.threshold.toLocaleString()}`} - Fee: {fee.type === "fixed" ? "£" : ""}{fee.fee.toLocaleString()}{fee.type === "percent" ? "%" : ""}
                                    </>}
                                    </li>
                                  );
                                }) : 
                                <li>No Threshold Fees Set!!</li>
                              }
                            {/* <li>Up to: £200 - £80 fee</li> */}
                          </ul>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
            )}
          </div>
        </div>
        

        <Segment>
          <div className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Milestones
                    </dt>
                  </div>
                </dl>
                <p>&nbsp;</p>

                  {loadingMilestones ? <Loader className="text-center" /> : <>
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

                  {milestoneDate("Case Opened", "open_date")}
                  {milestoneDate("Claim Prepared", "claim_prepared_date")}
                  {milestoneDate("Claim Sent to Court", "claim_sent_date")}
                  {milestoneDate("Date of Issue", "issue_date")}
                  {milestoneDate("Date of Judgment", "judgement_date")}
                  {milestoneDate("Judgment Received On", "judgement_received_date")}
                  {milestoneDate("Case Closed", "close_date")}
                  </dl></> }
                
              </div>
            </div>
        </Segment>

        <div>

          <div className="grid sm:grid-cols-1 py-2 px-4">
            <div className="border border-primary bg-white rounded-lg px-4 pb-2 mt-2">
              <div className="text-center text-xl py-2 text-primary">
                Fees
              </div>
              <div className="flex justify-around mb-4">
                <div className="  p-4 rounded-xl text-center">
                  <div className="text-3xl">
                    {applicableFee && 
                      applicableFee.type === "fixed" ? `£${applicableFee.fee ? CommonFunctions.toFormattedFixed(applicableFee.fee) : ""}` 
                      : applicableFee.type === "percent" ? `£${applicableFee.fee ? CommonFunctions.toFormattedFixed(parseFloat(matterDebtors.totalOwed) * parseFloat(applicableFee.fee / 100)) : ""} (${applicableFee.fee ? applicableFee.fee.toLocaleString() : ""}%)` : ""}
                  </div>
                  <div className="text-xl text-primary">Debt Fees</div>
                </div>
                <div className=" p-4 rounded-xl text-center">
                  <div className="text-3xl">
                    £{matterFees && CommonFunctions.toFormattedFixed(matterFees.reduce((acc, fee) => acc + fee.amount, 0))}
                  </div>
                  <div className="text-xl text-primary">Total Fees ({matterFees ? matterFees.length : 0})</div>
                </div>
                <div className="  p-4 rounded-xl text-center">
                  <div className="text-3xl">
                  £{matterFees && CommonFunctions.toFormattedFixed(matterFees.reduce((acc, fee) => acc + ((parseFloat(fee.amount)) - (fee.amountPaid ? parseFloat(fee.amountPaid) : 0)), 0))}
                  </div>
                  <div className="text-xl text-primary">Outstanding Fees</div>
                </div>
                
              </div>
            </div>
          </div>

          <div className="grid sm:grid-cols-1 py-2 px-4">
            <div className="border border-primary bg-white rounded-lg px-4 pb-2 mt-2">
              <div className="text-center text-xl py-2 text-primary">
                Debtors
              </div>
              <div className="flex justify-around mb-4">
                <div className=" p-4 rounded-xl text-center">
                  <div className="text-3xl">
                    {matterDebtors.debtors && matterDebtors.debtors.length > 0 ? 
                    matterDebtors.debtors && matterDebtors.debtors.map(debtor => {
                      return debtor.debtor_name
                    }).join(', ')
                      : ""}
                  </div>
                  <div className="text-xl text-primary">Debtors ({matterDebtors.debtors ? matterDebtors.debtors.length : 0})</div>
                </div>
                <div className="  p-4 rounded-xl text-center">
                  <div className="text-3xl">
                    £{matterDebtors.totalOwed
                      // ? matterDebtors.totalOwed.toLocaleString("en-GB")
                      ? CommonFunctions.toFormattedFixed(matterDebtors.totalOwed)
                      : 0}
                  </div>
                  <div className="text-xl text-primary">Amount Owed</div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid sm:grid-cols-1 py-2 px-4">
              <div className="border border-primary bg-white rounded-lg px-4 pb-2">
                <div className="text-center text-xl py-2 text-primary">
                  Clio Contacts - Custom Fields (defendant1, claiment1 & courtname1)
                </div>
                <div className="flex justify-around mb-4">
                  <div className=" p-4 rounded-xl text-center">
                    <div className="text-3xl">
                        {matter.defendant1 ? <Link to={"/contacts/"+matter.defendant1._id}>
                          {matter.defendant1.name} {matter.defendant1.suffix ? matter.defendant1.suffix : ""}
                        </Link> : matter._defendant1}
                    </div>
                    <div className="text-xl text-primary">Defendant1</div>
                  </div>
                  <div className=" p-4 rounded-xl text-center">
                    <div className="text-3xl">
                      {matter.claiment1 ? <Link to={"/contacts/"+matter.claiment1._id}>
                        {matter.claiment1.name} {matter.claiment1.suffix ? matter.claiment1.suffix : ""}
                      </Link> : matter._claiment1}
                    </div>
                    <div className="text-xl text-primary">Claiment1</div>
                  </div>
                  <div className=" p-4 rounded-xl text-center">
                    <div className="text-3xl">
                      {matter.court1 ? <Link to={"/contacts/"+matter.court1._id}>
                        {matter.court1.name} {matter.court1.suffix ? matter.court1.suffix : ""}
                      </Link> : matter._courtname1}
                    </div>
                    <div className="text-xl text-primary">Court1</div>
                  </div>
                  
                </div>
              </div>
            </div>

        </div>

        <Segment>
          <Checklist
              type="matter"
              parentId={matter._id}
              createTask={async (checklist_id) =>
                await Promise.all([
                  API.settings
                    .checklist("matter")
                    .createChecklistTask(matter._id, checklist_id),
                ])
              }
            />
        </Segment>

      </div>
    </Segment>
    </>
    
  );
}
export default ChainedContext(ShowMatter, [
  [
    MatterReceiver,
    (matter) => {
      return { matter };
    },
  ],
]);
