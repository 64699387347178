import React, { useState } from "react";
import { Button, Input, SelectTwo } from "../../../../components/Forms";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
const defaultData = {
  number: "",
  name: "Work",
  primary: false,
};

const EmailList = ({ onChange, phoneNumbers }) => {
  const [editIndex, setEditIndex] = useState(null);
  const [data, setData] = useState({
    ...defaultData,
  });

  const handleOnChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });
  const onTypeChange = (val) => setData({ ...data, name: val.target.value });
  const handleFormSubmit = () => {
    if (editIndex !== null) {
      onChange(
        phoneNumbers.map((item, index) => {
          if (index !== editIndex) return item;
          return {
            ...item,
            ...data,
          };
        })
      );
      setEditIndex(null);
    } else {
      onChange(
        [
          {
            ...data,
          },
        ].concat(phoneNumbers)
      );
    }
    setData({
      ...defaultData,
    });
  };
  const handleAddressEditButton = (index) => () => {
    setData(phoneNumbers[index]);
    setEditIndex(index);
  };
  const handleAddressDeleteButton = (index) => () => {
    onChange(phoneNumbers.filter((a, i) => i !== index));
  };
  const handleSetPrimary = (index) => () => {
    onChange(
      phoneNumbers.map((a, i) => {
        if (i === index) {
          return {
            ...a,
            primary: true,
          };
        }
        return {
          ...a,
          primary: false,
        };
      })
    );
  };

  const renderInputFields = () => {
    return (
      <React.Fragment>
        <div className="py-2 flex gap-2 items-end  justify-between">
          <div className="w-full">
            <Input
              label="Phone Number"
              name="number"
              value={data.number}
              onChange={handleOnChange}
            />
          </div>
          <div className="w-52">
            <SelectTwo
              label="Type"
              placeholder="Please Select Type"
              field="name"
              value={data.name}
              options={[
                { key: 0, text: "Work", value: "Work" },
                { key: 1, text: "Home", value: "Home" },
                { key: 2, text: "Mobile", value: "Mobile" },
                { key: 3, text: "Fax", value: "Fax" },
                { key: 4, text: "Pager", value: "Pager" },
                { key: 5, text: "Skype", value: "Skype" },
                { key: 6, text: "Other", value: "Other" },
              ]}
              onChange={onTypeChange}
            />
          </div>

          <div className="w-20">
            {editIndex !== null && (
              <Button
                colour={"alert"}
                label={`Cancel`}
                onClick={() => {
                  setData({
                    ...defaultData,
                  });
                  setEditIndex(null);
                }}
                className="mb-1"
              />
            )}
            <Button
              colour={editIndex !== null ? "warning" : "positive"}
              label={`${editIndex !== null ? "Update" : "Add"}`}
              onClick={handleFormSubmit}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };
  return (
    <div>
      {renderInputFields()}
      {phoneNumbers.length === 0 ? (
        <div>
          There are no phone numbers to display, create one using the form
          above.
        </div>
      ) : (
        <table className="min-w-full divide-y divide-gray-300 table-auto">
          <thead>
            <tr>
              <th className="text-left">Phone Number</th>
              <th className="text-center ">Type</th>
              <th className="text-center ">Is Primary</th>
              <th>{""}</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-300">
            {phoneNumbers.map((address, index) => {
              return (
                <tr key={index} className="w-full ">
                  <td className="p-2  w-3/6">{address.number}</td>
                  <td className="p-2 text-center w-1/6 ">{address.name}</td>
                  <td className="p-2   text-center w-1/4">
                    {address.primary ? (
                      "[Primary]"
                    ) : (
                      <Button
                        colour={"action"}
                        label={`Set Primary`}
                        onClick={handleSetPrimary(index)}
                        className="mx-auto"
                      />
                    )}
                  </td>
                  <td className="w-1/6 ">
                    <div className="flex gap-2 p-2  justify-end">
                      <Button
                        colour={"alert"}
                        label={<TrashIcon className="h-4" />}
                        onClick={handleAddressDeleteButton(index)}
                      />
                      <Button
                        colour={"warning"}
                        label={<PencilIcon className="h-4" />}
                        onClick={handleAddressEditButton(index)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
export default EmailList;
