import React, { useEffect, useState } from "react";
import Segment from "../../../components/Segment";
import Loader from "../../../components/Loader";
import API from "../../../api";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Button} from "../../../components/Forms";
import CommonFunctions from "../../../CommonFunctions";
import { PencilIcon, PlusIcon, TrashIcon, UploadIcon } from "@heroicons/react/solid";
import _appConfig from "../../../_appConfig";
import {ChromePicker} from "react-color-v2";
import { GiSave } from "react-icons/gi";


const WhitelabelingMenus = () => {
  const [globalError, setGlobalError] = useState(null);

  const [mount, setMount] = useState(true);
  const [styleUpdate, setStyleUpdate] = useState(false);
  const [org, setOrg] = useState({});

  const [data, setData] = useState({})

  const getData = () => {
    setGlobalError(null);
    API.organisations.getMyOrg().then((res) => {

      let whitelabeling = res.whitelabeling ? res.whitelabeling : {};

      setOrg({_id: res._id});
      setData(whitelabeling);
      
    }).finally(() => setMount(false));
  };

  useEffect(() => {
    getData();
  }, mount);

  if (mount) {
    return <Loader>Retrieving data from server...</Loader>;
  }
  if (styleUpdate) {
    return <Loader>Loading Sytle, the browser will refresh...</Loader>;
  }
  if (globalError) {
    return <div className={"m-4 p-2 bg-red-600 text-white"}>{globalError}</div>;
  }

  // console.log("org", org)
  console.log("data", data)

  return (
    <React.Fragment>
      <Segment>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Whitelabeling - Menus
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Whitelabeing options for menus on the system for your organization.
              </p>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                
              <div>
                <div className="mt-6 border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-3">
                      <dt className="text-sm font-medium leading-6 text-gray-900">Primary Menu (Top Menu)</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                        Background <br/>
                        <ChromePicker color={data.menuPrimary ? data.menuPrimary : "#000"} onChange={(color) => setData({...data, menuPrimary: color.hex})}/>
                      </dd>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                        Menu Title Text<br/>
                        <ChromePicker color={data.menuPrimaryTitle ? data.menuPrimaryTitle : "#000"} onChange={(color) => setData({...data, menuPrimaryTitle: color.hex})}/>
                        <br/>Menu Items Text <br/>
                        <ChromePicker color={data.menuPrimaryText ? data.menuPrimaryText : "#000"} onChange={(color) => setData({...data, menuPrimaryText: color.hex})}/>
                      </dd>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                        Hover <br/>
                        <ChromePicker color={data.menuHover ? data.menuHover : "#000"} onChange={(color) => setData({...data, menuHover: color.hex})}/><br/>
                        Hover Menu Items Text <br/>
                        <ChromePicker color={data.menuHoverText ? data.menuHoverText : "#000"} onChange={(color) => setData({...data, menuHoverText: color.hex})}/>
                      </dd>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                        Active <br/>
                        <ChromePicker color={data.menuActive ? data.menuActive : "#000"} onChange={(color) => setData({...data, menuActive: color.hex})}/>
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-3">
                      <dt className="text-sm font-medium leading-6 text-gray-900">Sub Primary Menu (Below Top Menu)</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                        Background <br/>
                        <ChromePicker color={data.subMenuPrimary ? data.subMenuPrimary : "#000"} onChange={(color) => setData({...data, subMenuPrimary: color.hex})}/>
                      </dd>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                        Menu Title Text<br/>
                        <ChromePicker color={data.subMenuPrimaryTitle ? data.subMenuPrimaryTitle : "#000"} onChange={(color) => setData({...data, subMenuPrimaryTitle: color.hex})}/>
                        <br/>Menu Items Text <br/>
                        <ChromePicker color={data.subMenuPrimaryText ? data.subMenuPrimaryText : "#000"} onChange={(color) => setData({...data, subMenuPrimaryText: color.hex})}/>
                      </dd>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                        Hover <br/>
                        <ChromePicker color={data.subMenuHover ? data.subMenuHover : "#000"} onChange={(color) => setData({...data, subMenuHover: color.hex})}/><br/>
                        Hover Menu Items Text <br/>
                        <ChromePicker color={data.subMenuHoverText ? data.subMenuHoverText : "#000"} onChange={(color) => setData({...data, subMenuHoverText: color.hex})}/>
                      </dd>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                        Active <br/>
                        <ChromePicker color={data.subMenuActive ? data.subMenuActive : "#000"} onChange={(color) => setData({...data, subMenuActive: color.hex})}/>
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-3">
                      <dt className="text-sm font-medium leading-6 text-gray-900">SideMenu</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                        Background <br/>
                        <ChromePicker color={data.sidemenuPrimary ? data.sidemenuPrimary : "#000"} onChange={(color) => setData({...data, sidemenuPrimary: color.hex})}/>
                      </dd>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                        Menu Title Text<br/>
                        <ChromePicker color={data.sidemenuPrimaryTitle ? data.sidemenuPrimaryTitle : "#000"} onChange={(color) => setData({...data, sidemenuPrimaryTitle: color.hex})}/>
                        <br/>Menu Items Text <br/>
                        <ChromePicker color={data.sidemenuPrimaryText ? data.sidemenuPrimaryText : "#000"} onChange={(color) => setData({...data, sidemenuPrimaryText: color.hex})}/>
                      </dd>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                        Hover <br/>
                        <ChromePicker color={data.sidemenuHover ? data.sidemenuHover : "#000"} onChange={(color) => setData({...data, sidemenuHover: color.hex})}/><br/>
                        Hover Menu Items Text <br/>
                        <ChromePicker color={data.sidemenuHoverText ? data.sidemenuHoverText : "#000"} onChange={(color) => setData({...data, sidemenuHoverText: color.hex})}/>
                      </dd>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                        Active <br/>
                        <ChromePicker color={data.sidemenuActive ? data.sidemenuActive : "#000"} onChange={(color) => setData({...data, sidemenuActive: color.hex})}/>
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-3">
                      <dt className="text-sm font-medium leading-6 text-gray-900">SideMenu Badge</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                        Background <br/>
                        <ChromePicker color={data.sidemenuBadge ? data.sidemenuBadge : "#000"} onChange={(color) => setData({...data, sidemenuBadge: color.hex})}/> <br/>
                        Badge 0 / No Notifications <br/>
                        <ChromePicker color={data.sidemenuBadgeZero ? data.sidemenuBadgeZero : "#000"} onChange={(color) => setData({...data, sidemenuBadgeZero: color.hex})}/> <br/>
                      </dd>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                        Text<br/>
                        <ChromePicker color={data.sidemenuBadgeText ? data.sidemenuBadgeText : "#000"} onChange={(color) => setData({...data, sidemenuBadgeText: color.hex})}/> <br/>
                        Badge 0 / No Notifications <br/>
                        <ChromePicker color={data.sidemenuBadgeZeroText ? data.sidemenuBadgeZeroText : "#000"} onChange={(color) => setData({...data, sidemenuBadgeZeroText: color.hex})}/>
                      </dd>
                    </div>
                  </dl>
                </div>
                <Button
                    className={`inline-flex justify-center rounded-md border bg-orange-600 hover:bg-orange-700 focus:ring-orange-500 border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                    colour={"warning"}
                    icon={GiSave}
                    label={"Save "}
                    onClick={() => {
                      let formData = new FormData();
                      /*
                      let sendData = {
                        menuPrimary: data.menuPrimary,
                        menuPrimaryText: data.menuPrimaryText,
                        menuPrimaryTitle: data.menuPrimaryTitle,
                        menuHover: data.menuHover,
                        menuHoverText: data.menuHoverText,
                        menuActive: data.menuActive,
                      };*/
                      formData.append("data", JSON.stringify(data));
                      
                      API.organisations.updateWhitelabeling(org._id, formData)
                        .then((res) => {
                          setStyleUpdate(true);
                          window.location.reload();
                        })
                        .catch((e) => CommonFunctions.handleError(e));
                    }}
                  />
              </div>

              </div>
            </div>
          </div>
        </div>
      </Segment>
    </React.Fragment>
  );
};
export default WhitelabelingMenus;
