import Segment from "../../../components/Segment";

const licneceMissingContent = ({logout}) => {
    return (
        <Segment>
            <div className="text-left">
            
                <a onClick={() => logout()} className="text-sm font-semibold leading-6 text-gray-900 cursor-pointer">
                &larr; Logout<span aria-hidden="true"></span>
                </a>

            </div>
          <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div className="text-center">
              <p className="text-base font-semibold text-primary">Error</p>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">No Licence Has Been Assigned to your Account!</h1>
              <p className="mt-6 text-base leading-7 text-gray-600">Sorry, your account doesn't have an active user License to access the Pro Legal Flow System!</p>
              <div className="mt-6 text-base leading-7 text-gray-600">
                Please contact one of your system administrators
              </div>
            </div>
          </main></Segment>);
}

export default licneceMissingContent;