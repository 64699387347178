import React, { Fragment, useEffect, useState } from "react";
import API from "../../api";
import CommonFunctions from "../../CommonFunctions";
import Segment from "../../components/Segment";
import dayjs from "dayjs";
import PaginationWrapper from "../../components/PaginationWrapper";
import Header from "../../components/Header";
import { BookOpenIcon } from "@heroicons/react/outline";
import { ThemeTable } from "../../components/Tables";
import { PlusIcon, UploadIcon, RefreshIcon } from "@heroicons/react/solid";
import { Button } from "../../components/Forms";
import { Dialog, Transition } from "@headlessui/react";
import TransactionImporter from "./debtors/debt/_components/TransactionImporter";
import { toast } from "react-hot-toast";

export default function Index({ history }) {
  const [matterCount, setMatterCount] = useState(0);
  const [importModal, setImportModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [clioSyncing, setClioSyncing] = useState(false);

  const syncClio = () => {
    setClioSyncing(true);

    API.settings.background_tasks.syncClioOpen().then(() => {
      setClioSyncing(false);
      toast.success("Custom Fields, Matters and tasks synced with Clio");
    }).catch((e) => {
      CommonFunctions.handleError(e);
      setClioSyncing(false);
    });
  };

  useEffect(() => {
    if (!matterCount) {
      API.matters
        .count()
        .then((res) => setMatterCount(res))
        .catch((e) => CommonFunctions.handleError(e));
    }
  }, [matterCount]);

  const getData = (pageNum, pageSize, search, filters) => {
    return API.matters
      .listPaged({
        pageNum,
        pageSize,
        search,
        filters,
      })
      .catch((e) => CommonFunctions.handleError(e));
  };

  const renderModal = () => {
    const handleClose = () => {
      setImportModal(false);
    };

    return (
      <Transition.Root show={importModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:py-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Import Payments from CSV
                    </Dialog.Title>
                    <div className="mt-4">
                      <div className={`overflow-x-auto text-lg`}>
                        <TransactionImporter 
                          title={"File"} 
                          isUploadingInProgress={a => setIsUploading(a)}
                          debtor={{_id: null}}
                          matter={{_id: null}}
                          debt={{_id: null}}
                          onUploadedDocument={res => {
                            console.log("🚀 ~ <TransactionImporter", res);
                            // setIsUploading(false);
                            toast.success("Imported successfully");
                          }} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  {/*  
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleModalSubmit}
                    disabled={isUploading}
                  >
                    Import
                  </button>*/}
                  <Button
                    label="Close"
                    colour="alert"
                    onClick={handleClose}
                    // disabled={isUploading}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <>
      {renderModal()}
      <Header
        title={"Matter Directory"}
        body={
          <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
            <dd className="mt-3 -ml-0.5 flex items-center text-sm text-menuPrimaryText font-medium sm:ml-0 sm:mt-0">
              <BookOpenIcon
                className="flex-shrink-0 h-5 w-5 text-menuPrimaryText mr-1"
                aria-hidden="true"
              />
              {matterCount}{" "}
              {matterCount && (matterCount > 1 || matterCount < 1)
                ? "Matters"
                : "Matter"}
            </dd>
          </dl>
        }
      />
      <div className="mt-4">
        <Segment>
          <span className="isolate inline-flex rounded-md shadow-sm mb-3">
            <Button
              onClick={() => history.push(`/matters/add`)}
              label="New Matter"
              colour="positive"
              icon={PlusIcon}
            />
            {/*  
            <button
              type="button"
              className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            >
              Months
            </button>*/}
            <Button
              onClick={() => setImportModal(true)}
              label="Import Payments"
              colour="warning"
              icon={UploadIcon}
            />
            <button
              onClick={() => syncClio()}
              type="button"
              className="relative ml-2 -ml-px inline-flex items-center rounded-r-md bg-amber-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-amber-500 hover:bg-amber-700 focus:z-10"
            >
              <RefreshIcon className="h-4" />&nbsp;
              Sync Open Clio Matters
            </button>
          </span>
          <PaginationWrapper
            preserve_options={"matters"}
            dataQuery={getData}
            search={true}
            status_filter={true}
            render={(items) => {
              return (
                <ThemeTable
                  header={[
                    { text: "Display Number (Clio)", value: "display_number" },
                    { text: "Client - Debtor Names", value: "contact" },
                    { text: "Date", value: "open_date" },
                    { text: "Practice Area", value: "practice_area_name" },
                    { text: "Dealing", value: "name" },
                    { text: "Status", value: "status" },
                  ]}
                  body={items.map((item) => {
                    let close_date = dayjs(item.close_date).isValid() ? dayjs(item.close_date).format("DD/MM/YYYY") : "";
                    return {...item,
                      status: item.status === "Closed" ? "Closed - "+close_date : item.status,
                    	name: item.originating_attorney
                      	? item.originating_attorney.name
                      	: "--",
                    	practice_area_name: item.practice_area
                      	? item.practice_area.name
                      	: "--",
                    	open_date: dayjs(item.open_date).format("DD/MM/YYYY"),
                    	contact: item.contact ?
                        item.contact.type == "Company" ? `${item.contact.name} ${item.contact.suffix ? " "+ item.contact.suffix : ""} - ${item.debtors.map((d => d.debtor_name)).join(", ")}` : 
                        `${item.contact.prefix} ${item.contact.first_name} ${item.contact.last_name} - ${item.debtors.map((d => d.debtor_name)).join(", ")}` : 
                      "--",
                  	}})}
                  
                  
                  action={(data) => history.push(`/matters/${data._id}`)}
                  noDataMessage="No Matters..."
                />
              );
            }}
          />
        </Segment>
      </div>
    </>
  );
}
