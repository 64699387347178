import {ApiRequest} from "../../plugins/clio_auth";
import AppConfig from "../../_appConfig";


const baseURL = `${AppConfig.api_server}/api/settings/background_tasks`;
export default {
  cron_auth_status: () => ApiRequest.get(`${baseURL}/cron_auth_status`),
  list_crons: () => ApiRequest.get(`${baseURL}/list_crons`),
  getAuthUrl: () => ApiRequest.get(`${baseURL}/start_background_auth`, {
    params: {
      redirectUrl: AppConfig.applicationRoot
    }
  }),
  syncClio: () => ApiRequest.post(`${baseURL}/clio_sync`, {}),
  syncClioOpen: () => ApiRequest.post(`${baseURL}/clio_sync_open`, {}),
  syncContacts: () => ApiRequest.post(`${baseURL}/clio_sync_contacts`, {}),
  automationTasks: () => ApiRequest.post(`${baseURL}/automations_cron`, {})
};