import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  Fragment,
} from "react";
import {
  OfficeBuildingIcon,
  SearchIcon,
  UserIcon,
  PlusIcon,
  RefreshIcon
} from "@heroicons/react/solid";
import { debounce } from "debounce";
import API from "../../api";
import Header from "../../components/Header";
import { Button } from "../../components/Forms";
import Segment from "../../components/Segment";
import toast from "react-hot-toast";
import CommonFunctions from "../../CommonFunctions";

export default function ContactList({ history }) {
  const [contacts, setContacts] = useState({});
  const [
    showClientsOnly,
    // setShowClientsOnly
  ] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewType, setViewType] = useState("company");
  const [filterName, setFilterName] = useState("all");
  const [clioSyncing, setClioSyncing] = useState(false);

  const getData = (data) => {
    API.contacts
      .getAll(data.searchTerm, data.showClientsOnly, true)
      .then((res) => {
        let final = {};
        Object.keys(res).forEach((a) => {
          if (res[a].length >= 1) {
            let contacts = res[a].filter((c) => {
              let allowed = [];
              switch(filterName){
                case "A-G":
                  allowed = ["A", "B", "C", "D", "E", "F", "G"];
                break;
                case "H-P":
                  allowed = ["H", "I", "J", "K", "L", "M", "N", "O", "P"];
                break;
                case "Q-Z":
                  allowed = ["Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
                break;
              }

              let firstLetter = c.name ? c.name[0] : c.first_name[0];

              if(allowed.includes(firstLetter) || filterName === "all"){
                
                switch(viewType){
                  default: 
                  case "all":
                  return c;
                  
                  case "company":
                  if(c.type !== "Person"){
                    return c;
                  }
                  break;
                  case "indv":
                  if(c.type === "Person"){
                    return c;
                  }
                  break;
      
                }
              }
            });

            if(contacts.length > 0){
              final[a] = contacts;
            }
          }
        });

        setContacts(final);
      });
  };

  const syncClio = () => {
    setClioSyncing(true);

    API.settings.background_tasks.syncContacts().then(() => {
      setClioSyncing(false);
      toast.success("Contacts synced with Clio");
    }).catch((e) => {
      CommonFunctions.handleError(e);
      setClioSyncing(false);
    });
  };

  useEffect(() => {
    getData({ searchTerm, showClientsOnly });
  }, [viewType, filterName])

  const debounceHandler = useCallback(
    () =>
      debounce((data) => {
        getData(data);
      }, 500),
    []
  );

  useEffect(() => {
    const theDebounceFunc = debounceHandler();
    theDebounceFunc({ showClientsOnly, searchTerm });
  }, [showClientsOnly, searchTerm, debounceHandler]);

  const totalCompanies = useMemo(() => {
    let total = 0;

    Object.keys(contacts).forEach((a) => {
      total += contacts[a].filter((b) => b.type !== "Person").length;
    });

    return total;
  }, [contacts]);

  const totalIndividuals = useMemo(() => {
    let total = 0;

    Object.keys(contacts).forEach((a) => {
      total += contacts[a].filter((b) => b.type === "Person").length;
    });

    return total;
  }, [contacts]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <div>
      {/* <div
        className={`sm:order-first sm:flex sm:flex-col flex-shrink-0 w-full border-r border-gray-300`}
      > */}
      {/* <div className="bg-gray-100 px-4 border-b border-primary h-12 flex items-center justify-between text-xl text-primary tracking-wider ">
        Contacts Directory
      </div> */}
      <Header
        title={"Clients Directory"}
        body={
          <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
            <dd className="mt-3 -ml-0.5 flex items-center text-sm text-menuPrimaryText font-medium capitalize sm:mr-2 sm:ml-0 sm:mt-0">
              <OfficeBuildingIcon
                className="flex-shrink-0 h-5 w-5 text-menuPrimaryText"
                aria-hidden="true"
              />
              {totalCompanies}{" "}
              {totalCompanies && (totalCompanies > 1 || totalCompanies < 1)
                ? "Companies"
                : "Company"}
            </dd>
            <dd className="mt-3 -ml-0.5 flex items-center text-sm text-menuPrimaryText font-medium sm:ml-0 sm:mt-0">
              <UserIcon
                className="flex-shrink-0 h-5 w-5 text-menuPrimaryText"
                aria-hidden="true"
              />
              {totalIndividuals}{" "}
              {totalIndividuals &&
              (totalIndividuals > 1 || totalIndividuals < 1)
                ? "Individuals"
                : "Individual"}
            </dd>
          </dl>
        }
      />
      {/* <Header
          title={"Contact Directory"}
          body={
            <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
              <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-2">
                Directory of
              </dd>
              <dd className="mt-3 -ml-0.5 flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-2 sm:ml-0 sm:mt-0">
                <OfficeBuildingIcon
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {totalCompanies}{" "}
                {totalCompanies && (totalCompanies > 1 || totalCompanies < 1)
                  ? "Companies"
                  : "Company"}
              </dd>
              <dd className="mt-3 -ml-0.5 flex items-center text-sm text-gray-500 font-medium sm:ml-0 sm:mt-0">
                <UserIcon
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {totalIndividuals}{" "}
                {totalIndividuals &&
                (totalIndividuals > 1 || totalIndividuals < 1)
                  ? "Individuals"
                  : "Individual"}
              </dd>
            </dl>
          }
        /> */}
      <div className="">
        <Segment>
          <form className="flex space-x-4" action="#">
            <div className="flex-1 min-w-0">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <SearchIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  name="search"
                  className="h-9 block w-full bg-gray-200 pl-10 sm:text-sm border-gray-300 rounded-md border-transparent"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            {/* <Button
                label={showClientsOnly ? "Show All" : "Show Only Clients"}
                onClick={() => setShowClientsOnly(!showClientsOnly)}
                colour="blue"
                className="text-sm"
              /> */}
            <Button
              label="New Client"
              onClick={() => history.push(`/contacts/add`)}
              colour="positive"
              className="text-sm"
              icon={PlusIcon}
            />
            <Button
              label="Clio Sync"
              onClick={() => syncClio()}
              colour="warning"
              className="text-sm"
              icon={RefreshIcon}
            />
          </form>
          <nav className="mt-4 flex-1 min-h-0" aria-label="Directory">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <span className="isolate inline-flex rounded-md shadow-sm mb-2">
                    <button
                      type="button"
                      className={classNames("relative inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary", viewType == "company" && "bg-primary")}
                      onClick={() => setViewType("company")}
                    >
                      <OfficeBuildingIcon className="h-6 mx-auto text-gray-700" />
                      Companies
                    </button>
                    <button
                      type="button"
                      className={classNames("relative inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary", viewType == "indv" && "bg-primary")}
                      onClick={() => setViewType("indv")}
                    >
                       <UserIcon className="h-6 mx-auto text-gray-700" />
                      Individuals
                    </button>
                    <button
                      type="button"
                      className={classNames("relative inline-flex items-center rounded-r-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:borderprimary focus:outline-none focus:ring-1 focus:ring-primary", viewType == "all" && "bg-primary")}
                      onClick={() => setViewType("all")}
                    >
                      <OfficeBuildingIcon className="h-6 mx-auto text-gray-700" />
                      <UserIcon className="h-6 mx-auto text-gray-700" />
                      Both
                    </button>
                  </span>
                  <br/>
                  <span className="isolate inline-flex rounded-md shadow-sm mb-2">
                    <button
                      type="button"
                      className={classNames("relative inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary", filterName == "all" && "bg-primary")}
                      onClick={() => setFilterName("all")}
                    >
                      View All
                    </button>
                    <button
                      type="button"
                      className={classNames("relative inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary", filterName == "A-G" && "bg-primary")}
                      onClick={() => setFilterName("A-G")}
                    >
                      A-G
                    </button>
                    <button
                      type="button"
                      className={classNames("relative inline-flex items-center border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary", filterName == "H-P" && "bg-primary")}
                      onClick={() => setFilterName("H-P")}
                    >
                      H-P
                    </button>
                    <button
                      type="button"
                      className={classNames("relative inline-flex items-center rounded-r-md border border-gray-300 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:borderprimary focus:outline-none focus:ring-1 focus:ring-primary", filterName == "Q-Z" && "bg-primary")}
                      onClick={() => setFilterName("Q-Z")}
                    >
                      Q-Z
                    </button>
                  </span>
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full">
                        <thead className="bg-white">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Email
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Phone
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Type
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {Object.keys(contacts).map((letter, letter_index) => (
                            <Fragment key={letter_index}>
                              <tr className="border-t border-gray-200">
                                <th
                                  colSpan={4}
                                  scope="colgroup"
                                  className="bg-gray-50 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
                                >
                                  {letter}
                                </th>
                              </tr>
                              {contacts[letter].sort((a, b) => {
                                  // Check if 'name' property exists in both 'a' and 'b'
                                  if (a.name && b.name) {
                                    return a.name.localeCompare(b.name);
                                  }
                                  
                                  // If 'name' property is missing in one of them, use 'first_name'
                                  const aName = a.name || a.first_name || '';
                                  const bName = b.name || b.first_name || '';

                                  return aName.localeCompare(bName);
                                }).map(
                                (contact, contact_index) => (
                                  <tr
                                    key={contact._id}
                                    onClick={() => {
                                      history.push(`/contacts/${contact._id}`);
                                    }}
                                    className={classNames(
                                      contact_index === 0
                                        ? "border-gray-300"
                                        : "border-gray-200",
                                      "border-t",
                                      "cursor-pointer",
                                      "hover:bg-gray-100"
                                    )}
                                  >
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                      {contact.name
                                        ? contact.name + (contact.suffix ? " "+contact.suffix : "")
                                        : `${contact.first_name}${
                                            contact.middle_name
                                              ? ` ${contact.middle_name}`
                                              : ""
                                          }${
                                            contact.last_name
                                              ? ` ${contact.last_name}`
                                              : ""
                                          }`}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {contact._primary_email_address
                                        .address !== undefined
                                        ? contact._primary_email_address.address
                                        : "N/A"}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {contact._primary_phone_number.number !==
                                      undefined
                                        ? contact._primary_phone_number.number
                                        : "N/A"}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {contact.type === "Person" ? (
                                        <UserIcon className="h-6 mx-auto text-gray-700" />
                                      ) : (
                                        <OfficeBuildingIcon className="h-6 mx-auto text-gray-700" />
                                      )}
                                    </td>
                                  </tr>
                                )
                              )}
                            </Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </Segment>
      </div>
      {/* </div> */}
      {/* <div className="flex w-full">
        {profile.name && showCard ? (
          <CompanyCard profile={profile} hide={() => setShowCard(false)} />
        ) : (
          <div className="text-center h-full items-center  w-full p-8 text-gray-400 ">
            <div className="flex flex-col gap-8 justify-center border-4 border-dashed border-gray-300 rounded-xl h-full w-full">
              <div className="text-3xl tracking-wider">
                Active Companies: xxx
              </div>
              <div className="text-3xl tracking-wider">
                Total Companies: xxx
              </div>
            </div>
          </div>
        )}
      </div> */}
    </div>
  );
}
