import React, { useEffect, useState } from "react";
import Segment from "../../../components/Segment";
import Loader from "../../../components/Loader";
import API from "../../../api";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

const AreaTasksSettingsDash = () => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [globalError, setGlobalError] = useState(null);
  const [areas, setAreas] = useState([]);

  const history = useHistory();

  const params = useParams();

  const getData = () => {
    setGlobalError(null);
    API.settings.area_tasks
      .getAll(params.org_id)
      .then((res) => {
        setAreas(res);
      })
      .catch((err) => {
        if (err.response !== undefined) {
          setGlobalError(err.response.data.message);
        } else {
          setGlobalError(
            "There was an unexpected error while trying to retrieve the area tasks from the server."
          );
        }
      })
      .finally(() => {
        setIsInitialLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  if (isInitialLoading) {
    return <Loader>Retrieving data from server...</Loader>;
  }
  if (globalError) {
    return <div className={"m-4 p-2 bg-red-600 text-white"}>{globalError}</div>;
  }

  return (
    <React.Fragment>
      <Segment>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Areas of Law, Task Workflow Builer
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Create workflows withs Tasks & Steps
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                onClick={() => API.settings.area_tasks.create({
                  org_id: params.org_id ? params.org_id : undefined
                }).then((res) => {
                  if(res.success) {
                    history.push(params.org_id ? `/admin/organisations/${params.org_id}/area_tasks/${res.id}` : `/settings/area_tasks/${res.id}`);
                  }
                })}
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                Add
              </button>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Area
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">View</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {areas &&
                        areas.length > 0 &&
                        areas.map((a, index) => (
                          <tr key={"area_" + index}>
                            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                              {index + 1}
                            </td>
                            <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                              {a.area}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <a
                                href={params.org_id ? `/admin/organisations/${params.org_id}/area_tasks/${a._id}` : "/settings/area_tasks/" + a._id}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                View<span className="sr-only">, {a.area}</span>
                              </a>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Segment>
    </React.Fragment>
  );
};
export default AreaTasksSettingsDash;
