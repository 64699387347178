import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  Button,
  DateSelect,
  Input,
  SelectTwo,
} from "../../../../../components/Forms";
import DatePicker from "react-datepicker";
import toast from "react-hot-toast";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import API from "../../../../../api";
import CommonFunctions from "../../../../../CommonFunctions";

export default function Add_Payment_Form({ data, debts = []}) {
  // const [debts, setDebts] = useState({});
  const [payment, setPayment] = useState({
    reference: "",
    paymentDate: "",
    paymentDue: "",
    paymentType: "",
    amount: 0
  });
  const [isMount, setIsMount] = useState(true);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});

  useEffect(() => {
    API.settings.payment_types.get().then((res) => {
      setPaymentTypes(res.filter(t => t.archived !== true).map((p) => {
        return { key: 0, text: p.title, value: p._id };
      }));
      setIsMount(false)
    })

  }, [isMount]);

  const currentYear = (new Date()).getFullYear();
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

  const years = range(currentYear + 6, currentYear - 12, -1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const getInterest = (value, interest) => {
    let fig1 = value / 100;
    let fig2 = interest / 365;
    return fig1 * fig2;
  };

  useEffect(() => {
    validate();
  }, [payment]);

  const onChange = (e) => {

    if(e.target.name === "amount"){
      if(e.target.value < 0){
        return false;
      }

      let total_balance = 0;

      let amount = e.target.value;

      let theDebts = debts;

      theDebts.forEach(debt => {

        let outstanding = debt.Outstanding ? parseFloat(debt.Outstanding) : 0;
        let compensation = debt.compensation ? parseFloat(debt.compensation) : 0;
        let recoverable_legal_costs = debt.recoverable_legal_costs ? parseFloat(debt.recoverable_legal_costs) : 0;

        let days = dayjs().diff(dayjs(debt.interestDate), "day");
        let interest = getInterest(debt.amount, debt.interest) * days;

        let balance = outstanding + compensation + recoverable_legal_costs + (interest ? parseFloat(interest) : 0);

        total_balance = total_balance + balance;

        let balanceAfter = balance - amount;

        if(balanceAfter < 0){
          debt.paymentAmount = balance;
        }else{
          debt.paymentAmount = amount;
        }

        amount = balanceAfter < 0 ? Math.abs(balanceAfter) : 0;

      });

      debts = theDebts;

      if(parseFloat(e.target.value) > parseFloat(total_balance).toFixed(2)){
        toast.error(`£${e.target.value} can't be entered as this is above the total, invoices total is £${parseFloat(total_balance) ? parseFloat(total_balance).toFixed(2) : total_balance}`)
        setPayment({ ...payment, [e.target.name]: "" });
        return false;
      }

    }

      setPayment({ ...payment, [e.target.name]: e.target.value });
  };
  const submitData = () => {

    if(Object.keys(warnings).length >= 1 || Object.keys(errors).length >= 1){
      toast.error("There are validation issues that must be resolved before continuing.");
      return;
    }
    data(payment);
  };
  const validate = () => {
    let warnings = {};
    setWarnings(warnings);

    const errors = {};
    if (!payment.amount || !payment.amount > 0) errors.amount = true;
    if (!payment.reference) errors.reference = true;

    if(Object.keys(errors).length > 0) {
      errors.notcomplete = true;
    }

    setErrors(errors);
    return errors;
  };

  let total_paid = 0;
  let total_balance = 0;
  let total_after = 0;
  let total_payment = 0;

  let debts_body = debts.map((debt, index) => {
    let outstanding = debt.Outstanding ? parseFloat(debt.Outstanding) : 0;
    let compensation = debt.compensation ? parseFloat(debt.compensation) : 0;
    let recoverable_legal_costs = debt.recoverable_legal_costs ? parseFloat(debt.recoverable_legal_costs) : 0;

    let days = dayjs().diff(dayjs(debt.interestDate), "day");
    let interest = getInterest(debt.amount, debt.interest) * days;

    let balance = outstanding + compensation + recoverable_legal_costs + (interest ? parseFloat(interest) : 0);

    total_paid = total_paid + (parseFloat(debt.AmountPaid) ? parseFloat(debt.AmountPaid) : 0);
    total_balance = total_balance + balance;
    total_after = total_after + (balance - (parseFloat(debt.paymentAmount) ? parseFloat(debt.paymentAmount) : 0));
    total_payment = total_payment + (parseFloat(debt.paymentAmount) ? parseFloat(debt.paymentAmount) : 0);

    return (
    <tr key={index} className="p-2 divide-x divide-gray-300 hover:bg-gray-50">
      <td className="p2">
        {dayjs(debt.invoiceDate) ? dayjs(debt.invoiceDate).format("DD MMMM YYYY") : "N/A"}
      </td>
      <td className="p2">
        #{debt.invoice_num} - {debt.detors}
      </td>
      <td className="p2">
        £{parseFloat(debt.AmountPaid) ? CommonFunctions.toFormattedFixed(parseFloat(debt.AmountPaid)) : "0.00"}
      </td>
      <td className="p2">
        £{parseFloat(balance) ? CommonFunctions.toFormattedFixed(parseFloat(balance)) : "0.00"}
      </td>
      <td className="p2">
        £{parseFloat(debt.paymentAmount) ? CommonFunctions.toFormattedFixed(parseFloat(debt.paymentAmount)) : "0.00"}
      </td>
      <td className="p2">
        £{parseFloat(balance - debt.paymentAmount) ? CommonFunctions.toFormattedFixed(parseFloat(balance - debt.paymentAmount)) : "0.00"}
      </td>
    </tr>
  )});

  return (
    <div className="w-full p-2 ">
      <div className="bg-red-500">
        {warnings && warnings.warn &&
        <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8 mb-5">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center">
              <span className="flex rounded-lg">
                <ExclamationCircleIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 truncate font-medium text-white">
                <span className="md:hidden"><b className="animate-pulse">Warning!</b> {warnings && warnings.message}</span>
                <span className="hidden md:inline"><b className="animate-pulse">Warning!</b> {warnings && warnings.message}</span>
              </p>
            </div>
          </div>
        </div> }
    </div>
      <div className="w-full gap-2">
        <div className="text-center pb-4 font-medium text-lg  border-b border-gray-300 mx-10 mb-2">
          Payment Form
        </div>
        <div className="grid grid-cols-2 gap-4 ">
          <div className="grid grid-cols-5 col-span-2 gap-4 border-r border-gray-100 p-2">
            <Input 
              label="Payment Reference"
              name="reference"
              onChange={onChange}
              value={payment.reference}
              error={errors.reference}
            />
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700">
                Payment Date
              </label>
              <DateSelect
                onChange={(date) =>
                  setPayment({
                    ...payment,
                    paymentDate: date,
                  })
                }
                value={payment.paymentDate ? new Date(payment.paymentDate) : ""}
                name="paymentDate"
                className={`relative w-full p-2${
                  errors.paymentDate ? "border border-red-400" : warnings && warnings.warn ? "border border-blue-500 animate-pulse" : ""
                } `}
              />
            </div>
            {/*  
            <div className="w-full">
              <label className="block text-sm font-medium text-gray-700">
                Payment Due
              </label>
              <DatePicker
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      {"<"}
                    </button>
                    <select
                      value={(new Date(date)).getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
          
                    <select
                      value={months[(new Date(date)).getMonth()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
          
                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      {">"}
                    </button>
                  </div>
                )}
                onChange={(date) =>
                  setPayment({
                    ...payment,
                    paymentDue: date,
                  })
                }
                selected={payment.paymentDue ? new Date(payment.paymentDue) : ""}
                showPopperArrow={false}
                shouldCloseOnSelect={true}
                dateFormat="dd-MM-yy"
                peekNextMonth
                className={`relative w-full p-2 rounded-lg bg-gray-100 border ${
                  errors.paymentDate ? "border-red-400" : warnings && warnings.warn ? "border-blue-500 animate-pulse" : "border-gray-300"
                } `}
              />
            </div>*/}
            
            <div className="col-span-6 sm:col-span-1">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Payment Method
              </label>

              <SelectTwo
                placeholder="Select Method"
                field="paymentType"
                options={paymentTypes}
                className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={payment.paymentType}
                onChange={onChange}
              />
            </div>
            <Input
              label="Payment Amount"
              type={"number"}
              name={"amount"}
              onChange={onChange}
              placeholder="100.00"
              value={payment.amount}
              error={errors.amount}
              center={true}
              minimum={0}
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4 mt-4 p-2">
          <div className="flex justify-between border border-gray-100 p-2">
            Payment Total:
            <span>
              £{parseFloat(payment.amount) ? CommonFunctions.toFormattedFixed(parseFloat(payment.amount)) : "0.00"}
            </span>
          </div>
          <div className="flex justify-between border border-gray-100 p-2">
            Invoices Total:
            <span>
              £{parseFloat(total_balance) ? CommonFunctions.toFormattedFixed(parseFloat(total_balance)) : "0.00"}
            </span>
          </div>
          <div className="flex justify-between border border-gray-100 p-2">
            Invoices After Payment:
            <span>
              £{parseFloat(total_after) ? CommonFunctions.toFormattedFixed(parseFloat(total_after)) : "0.00"}
            </span>
          </div>
        </div>
        <div className="w-full gap-4 mt-4 p-2">
        <table className="w-full border border-gray-400">
          <thead className="bg-gray-50">
            <tr className="bg-gray-200 text-gray-600 divide-x divide-gray-300">
              <th scope="col" className="font-medium">
                Date
              </th>
              <th scope="col" className="font-medium">
                Invoice
              </th>
              <th scope="col" className="font-medium">
                Amount Paid
              </th>
              <th scope="col" className="font-medium">
                Debt Balance
              </th>
              <th scope="col" className="font-medium">
                Payment Amount
              </th>
              <th scope="col" className="font-medium">
                Balance After Payment
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {debts_body}

            <tr className="p-2 divide-x divide-gray-300 bg-gray-200">
              <td></td>
              <td className="text-right p-2 border-r border-gray-400">Totals:</td>
              <td className="p-2 border-r border-gray-400">£{parseFloat(total_paid) ? CommonFunctions.toFormattedFixed(parseFloat(total_paid)) : "0.00"}</td>
              <td className="p-2 border-r border-gray-400">£{parseFloat(total_balance) ? CommonFunctions.toFormattedFixed(parseFloat(total_balance)) : "0.00"}</td>
              <td className="p-2 border-r border-gray-400">£{parseFloat(total_payment) ? CommonFunctions.toFormattedFixed(parseFloat(total_payment)) : "0.00"}</td>
              <td className="p-2 border-r border-gray-400">£{parseFloat(total_after) ? CommonFunctions.toFormattedFixed(parseFloat(total_after)) : "0.00"}</td>
            </tr>
          </tbody>
        </table>
        </div>
        <div className="flex justify-between mt-3 border-t border-gray-100 py-4">
          {/*  
          <Button
            colour="red"
            onClick={() => {
              history.push(`/matters/${matter._id}/debtors`)
            }}
            label="Cancel"
          />*/}
          <Button 
            colour="positive" 
            onClick={submitData} 
            // disabled={errors.notcomplete} 
            label={"Add"}
           />
        </div>
      </div>
    </div>
  );
}
