import React, { useEffect, useState } from "react";
import Segment from "../../../components/Segment";
import API from "../../../api";
import Loader from "../../../components/Loader";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { Button, FileUploader } from "../../../components/Forms";
import CommonFunctions from "../../../CommonFunctions";
import { PencilIcon, PlusIcon, TrashIcon, UploadIcon } from "@heroicons/react/solid";
import _appConfig from "../../../_appConfig";
import {ChromePicker} from "react-color-v2";

const WhitelabelingDash = () => {
  const [globalError, setGlobalError] = useState(null);

  const [mount, setMount] = useState(true);
  const [styleUpdate, setStyleUpdate] = useState(false);
  const [org, setOrg] = useState({});

  const [data, setData] = useState({})

  const getData = () => {
    setGlobalError(null);
    API.organisations.getMyOrg().then((res) => {

      let whitelabeling = res.whitelabeling ? res.whitelabeling : {};

      setOrg({_id: res._id});
      setData(whitelabeling);
      
    }).finally(() => setMount(false));
  };

  useEffect(() => {
    getData();
  }, mount);

  if (mount) {
    return <Loader>Retrieving data from server...</Loader>;
  }
  if (styleUpdate) {
    return <Loader>Loading Sytle, the browser will refresh...</Loader>;
  }
  if (globalError) {
    return <div className={"m-4 p-2 bg-red-600 text-white"}>{globalError}</div>;
  }

  // console.log("org", org)
  console.log("data", data)

  return (
    <React.Fragment>
      <Segment>
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Whitelabeling - Global
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Global whitelabeling for your organization.
              </p>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                
              <div>
                <div className="mt-6 border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <div className="bg-gray-50 px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-3">
                      <dt className="text-sm font-medium leading-6 text-gray-900">Logo</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        Current:
                        <img height="300" width="300" src={data.logo ?  _appConfig.api_server + data.logo_url : ""} />

                        <FileUploader onFileToUpload={(d) => setData({...data, logo_new: d})} />

                        {data.logo_new ? data.logo_new.name : ``}

                        <br/>

                        {data.logo_new ? 
                            <Button
                              className={`inline-flex justify-center rounded-md border bg-orange-600 hover:bg-orange-700 focus:ring-orange-500 border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                              colour={"warning"}
                              icon={UploadIcon}
                              label={"Save & Upload new logo"}
                              onClick={() => {
                                let formData = new FormData();
                                formData.append("logo", data.logo_new);

                                API.organisations.updateWhitelabeling(org._id, formData)
                                  .then((res) => {
                                      setData({...data, logo_new: undefined});
                                      setStyleUpdate(true);
                                      window.location.reload();
                                    }
                                  )
                                  .catch((e) => CommonFunctions.handleError(e));
                              }}
                            /> : "Save button will appear when new image is uploaded!"}

                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              </div>
            </div>
          </div>
        </div>
      </Segment>
    </React.Fragment>
  );
};
export default WhitelabelingDash;
