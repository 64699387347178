import React, { useState, useEffect, Fragment } from "react";
import { PlusIcon, XIcon } from "@heroicons/react/solid";
import Segment from "../../../../components/Segment";
import { Button, Group, SelectTwo, SelectZero } from "../../../../components/Forms";
import { Dialog, Switch, Transition } from "@headlessui/react";
import API from "../../../../api";
import AddressList from "../../../contacts/forms/_fields/addressList";
import EmailList from "../../../contacts/forms/_fields/emailList";
import PhoneList from "../../../contacts/forms/_fields/phoneList";
import WebsiteList from "../../../contacts/forms/_fields/websiteList";

const ContactForm = ({ org = {}, onSubmit, onCancel }) => {
  const [data, setData] = useState({
    name: "",
    contact: "",
    contactNumber: "",
    _clioId: "",
  });

  useEffect(() => {

    if (org._id) {
      setData(org);
    }
  }, [org]);

  const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    // TODO form validation

    onSubmit(data);
  };

  let classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className={"mt-4"}>

      <Segment padding="px-4 mb-5">
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Organisation Information
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Details for all organisation info
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:rounded-md">
                <div className="space-y-6 bg-white px-4 py-5 sm:p-6">

                    <>
                      <div className="grid grid-cols-4 gap-6">
                        <div className="col-span-6 sm:col-span-1">
                          <label
                            htmlFor="first_name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            value={data.name}
                            onChange={onChange}
                            id="name"
                            autoComplete="name"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          />
                        </div>
                        <div className="col-span-4 sm:col-span-1">
                          <label
                            htmlFor="middle_name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Contact Name
                          </label>
                          <input
                            id="contact"
                            type="text"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            name="contact"
                            value={data.contact}
                            onChange={onChange}
                          />
                        </div>
                        <div className="col-span-4 sm:col-span-1">
                          <label
                            htmlFor="last_name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Contact Number
                          </label>
                          <input
                            type="text"
                            id="contactNumber"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            name="contactNumber"
                            value={data.contactNumber}
                            onChange={onChange}
                          />
                        </div>
                        <div className="col-span-4 sm:col-span-1">
                          <label
                            htmlFor="last_name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Clio ID
                          </label>
                          <input
                            type="text"
                            id="_clioId"
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            name="_clioId"
                            value={data._clioId}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    </>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>


        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Communications
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Contact details including Email Addresses, Phone Numbers and
                  Websites
                </p>
              </div>
            </div>
            <div className="md:col-span-2">
              <EmailList
                emails={data.email_addresses || []}
                onChange={(a) => {
                  setData({
                    ...data,
                    email_addresses: a,
                  });
                }}
              />
              <PhoneList
                phoneNumbers={data.phone_numbers || []}
                onChange={(a) => {
                  setData({
                    ...data,
                    phone_numbers: a,
                  });
                }}
              />
              <WebsiteList
                websites={data.web_sites || []}
                onChange={(a) => {
                  setData({
                    ...data,
                    web_sites: a,
                  });
                }}
              />
            </div>
          </div>
        </div> 

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Addresses
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  Contact Addresses details
                </p>
              </div>
            </div>
            <div className="md:col-span-2">
              <AddressList
                addresses={data.addresses || []}
                onChange={(a) => {
                  setData({
                    ...data,
                    addresses: a,
                  });
                }}
                showValidationErrors={false}
              />{" "}
              {/** TODO pass the show validation as needed **/}
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>

        <div className="py-2 flex justify-between">
          <Button
            className="block ml-auto mr-0"
            label={org._id !== undefined ? "Update" : "Create"}
            onClick={handleSubmit}
            colour="positive"
            icon={PlusIcon}
          />
        </div>
      </Segment>

      {/*  
      <Segment padding="px-4">
        <SelectTwo
          placeholder="Please Select Type"
          field="type"
          label={"Contact Type"}
          options={contactTypes}
          value={data.type}
          onChange={onChange}
        />
        {data.type !== "" && (
          <React.Fragment>
            <div className="mt-4">
              <div className="leading-6 font-medium text-xl text-gray-900">
                Contact Information
              </div>
              <div className="w-full border-t border-gray-300 my-2" />
            </div>
            {data.type === "Company" && (
              <div className="md:grid md:grid-cols-5">
                <Group className="py-2 col-span-4">
                  <Input
                    label="Name"
                    name="name"
                    value={data.name}
                    onChange={onChange}
                  />
                  <Input
                    label="Company Registration Number"
                    placeholder="01234567"
                    name="companyRegistrationNumber"
                    value={data.companyRegistrationNumber}
                    onChange={onChange}
                  />
                  <Input
                    label="VAT Number"
                    placeholder="01234567"
                    name="vatRegistrationNumber"
                    value={data.vatRegistrationNumber}
                    onChange={onChange}
                  />
                </Group>
                <div className="md:col-span-1">
                  <Button
                    colour="green"
                    label="Credit Lookup"
                    onClick={() => {
                      setModalOpen(true);
                    }}
                    className="mx-auto md:creditLookupButton"
                    icon={BookOpenIcon}
                    disabled={
                      data.name && data.companyRegistrationNumber ? false : true
                    }
                  />
                </div>
              </div>
            )}
            {data.type === "Person" && (
              <div className="w-full">
                <Group className="py-2">
                  <Input
                    label="Prefix"
                    name="prefix"
                    value={data.prefix}
                    onChange={onChange}
                  />
                  <Input
                    label="First name"
                    name="first_name"
                    value={data.first_name}
                    onChange={onChange}
                  />
                  <Input
                    label="Middle name"
                    name="middle_name"
                    value={data.middle_name}
                    onChange={onChange}
                  />
                  <Input
                    label="Last name"
                    name="last_name"
                    value={data.last_name}
                    onChange={onChange}
                  />
                </Group>
              </div>
            )}
            <div className="grid grid-cols-2 gap-4 py-2">
              <div className="p-4 border border-primary rounded-md">
                <div className="text-lg">Communications</div>
                {/* 
                <EmailList
                  emails={data.email_addresses || []}
                  onChange={(a) => {
                    setData({
                      ...data,
                      email_addresses: a,
                    });
                  }}
                /> 
                <div className="w-full border-t border-gray-300 my-2" />
                <PhoneList
                  phoneNumbers={data.phone_numbers || []}
                  onChange={(a) => {
                    setData({
                      ...data,
                      phone_numbers: a,
                    });
                  }}
                />
                <div className="w-full border-t border-gray-300 my-2" />
                <WebsiteList
                  websites={data.web_sites || []}
                  onChange={(a) => {
                    setData({
                      ...data,
                      web_sites: a,
                    });
                  }}
                />*-/}
              </div>
              <div className="p-4 border border-primary rounded-md">
                <div className="text-lg">Addresses</div>
              </div>
            </div>
          </React.Fragment>
        )}

        <div className="w-full border-t border-gray-300 my-2" />
      </Segment>*/}
    </div>
  );
};
export default ContactForm;
