import React from "react";
import PropTypes from "prop-types";

export default function Button({
  label = "",
  colour = "",
  color = "",
  disabled = false,
  onClick = () => {},
  className = "",
  style = {},
  icon,
}) {

  if(!colour){
    colour = color;
  }

  const colourList = {
    subtle: `bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 disabled:bg-green-500 disabled:opacity-50 text-white`,

    // action: `bg-action hover:bg-action text-action focus:outline-none focus:ring-2 focus:ring-gray-500 disabled:bg-action disabled:opacity-50`,
    activity: `bg-activity hover:bg-activityHover text-activityText hover:text-activityTextHover focus:outline-none focus:ring-2 focus:ring-activity disabled:opacity-50`,

    sidebarTimer: `bg-sidebarTimer hover:bg-sidebarTimerHover text-sidebarTimerText hover:text-sidebarTimerTextHover focus:outline-none focus:ring-2 focus:ring-sidebarTimer disabled:opacity-50`,
    sidebarTimerActive: `bg-sidebarTimerActive hover:bg-sidebarTimerActiveHover text-sidebarTimerActiveText hover:text-sidebarTimerActiveTextHover focus:outline-none focus:ring-2 focus:ring-sidebarTimerActive disabled:opacity-50`,
    sidebarTimerViewAll: `bg-sidebarTimerViewAll hover:bg-sidebarTimerViewAllHover text-sidebarTimerViewAllText hover:text-sidebarTimerViewAllTextHover focus:outline-none focus:ring-2 focus:ring-sidebarTimerViewAll disabled:opacity-50`,

    action: `bg-action hover:bg-actionHover text-actionText hover:text-actionTextHover focus:outline-none focus:ring-2 focus:ring-action disabled:opacity-50`,
    positive: `bg-positive hover:bg-positiveHover text-positiveText hover:text-positiveTextHover focus:outline-none focus:ring-2 focus:ring-positive disabled:opacity-50`,
    warning: `bg-warning hover:bg-warningHover text-warningText hover:text-warningTextHover focus:outline-none focus:ring-2 focus:ring-warning disabled:opacity-50`,
    alert: `bg-alert hover:bg-alertHover text-alertText hover:text-alertTextHover focus:outline-none focus:ring-2 focus:ring-alert disabled:opacity-50`,

    green: `bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 disabled:bg-green-500 disabled:opacity-50 text-white`,
    red: `bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 disabled:bg-red-500 disabled:opacity-50 text-white`,
    grey: "bg-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 disabled:bg-gray-500 disabled:opacity-50 text-white",
    blue: "bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-700 disabled:bg-blue-500 disabled:opacity-50 text-white",
    amber:
      "bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-600 disabled:bg-amber-500 disabled:opacity-50 text-white",
  };

  const Icon = icon;

  return (
    <button
      type="button"
      className={`px-2.5 py-1.5 border border-transparent font-medium rounded shadow-sm flex items-center ${
        colour && colourList[colour]
          ? colourList[colour]
          : "bg-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 disabled:bg-gray-400 disabled:opacity-50 text-gray-500"
      } ${className ? className : ""}`}
      disabled={disabled}
      style={style}
      onClick={onClick}
    >
      {icon !== undefined ? (
        
        <Icon
          className={` h-5 w-5 ${label ? "mr-1" : ""}`}
          aria-hidden="true"
        />
      ) : (
        <></>
      )}
      {label}
    </button>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  colour: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  icon: PropTypes.func,
};
