import React, { useEffect, useState } from "react";
import Loader from "../../../../components/Loader";
import API from "../../../../api";
import CommonFunctions from "../../../../CommonFunctions";
import { Button, Input } from "../../../../components/Forms";
import { PencilIcon, PlusIcon, TrashIcon, XIcon } from "@heroicons/react/solid";
import { ConfirmModal } from "../../../../components/Modals";
import Segment from "../../../../components/Segment";
import { useParams } from "react-router-dom";

const initialState = { name: "", description: "" };

export default function ServiceManagement() {
  const [listData, setListData] = useState([]);
  const [itemData, setItemData] = useState(initialState);

  const [modalOpen, setModalOpen] = useState("");
  const [editing, setEditing] = useState(false);
  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);

  const params = useParams();

  useEffect(() => {
    if (mount) {
      setMount(false);
      setLoading(true);

      API.settings
        .services()
        .list(params.org_id)
        .then((res) => setListData(res))
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setLoading(false));
    }
  }, [mount, loading]);

  const handleChange = (e) =>
    setItemData({ ...itemData, [e.target.name]: e.target.value });

  const renderTable = (header, body) => (
    <div className={`text-lg`}>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {header.map((header_item, i) => {
                      return (
                        <th
                          key={"th_" + i}
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          {header_item.text}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {body.length > 0 ? (
                    body.map((body_item, body_index) => (
                      <tr key={body_index}>
                        {header.map((header_item, header_index) => (
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {body_item[header_item.value]}
                          </td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <td
                      colspan={header.length}
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-center"
                    >
                      No Data...
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <ConfirmModal
        open={modalOpen !== ""}
        handleClose={() => setModalOpen("")}
        handleSubmit={() => {
          API.settings
            .services()
            .remove(modalOpen)
            .then(() => {
              setMount(true);
              setModalOpen("");
            })
            .catch((e) => CommonFunctions.handleError(e));
        }}
        header="Are you sure?"
        content={
          "Deleting is an irreversible action, but you can re-add an item with the same name."
        }
      />
      <Segment>
        <div>
          <h3 className="leading-6 font-medium text-gray-900 flex justify-between">
            <span className="my-auto text-xl">Services</span>
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
        </div>
        <div className="pt-2 pb-4 md:grid md:grid-cols-12 md:gap-4">
          <Input
            label="Name"
            name="name"
            value={itemData.name}
            onChange={handleChange}
            className="col-span-4"
          />
          <Input
            label="Description"
            name="description"
            value={itemData.description}
            onChange={handleChange}
            className="col-span-8"
          />
          <div className="flex justify-between col-span-12">
            {editing ? (
              <Button
                label="Cancel"
                colour="alert"
                icon={XIcon}
                onClick={() => {
                  setEditing(false);
                  setItemData(initialState);
                }}
              />
            ) : (
              <></>
            )}
            <Button
              label={editing ? "Save" : "Add"}
              colour={editing ? "warning" : "positive"}
              icon={editing ? PencilIcon : PlusIcon}
              className={editing === false ? "ml-auto" : ""}
              onClick={() => {
                if (editing) {
                  API.settings
                    .services()
                    .edit(itemData._id, {
                      ...itemData,
                      action_btn: "",
                      org_id: params.org_id
                    })
                    .then(() => {
                      setEditing(false);
                      setItemData(initialState);
                    })
                    .catch((e) => CommonFunctions.handleError(e))
                    .finally(() => setMount(true));
                } else {
                  API.settings
                    .services()
                    .add({
                      ...itemData,
                      org_id: params.org_id
                    })
                    .then(() => {
                      setItemData(initialState);
                    })
                    .catch((e) => CommonFunctions.handleError(e))
                    .finally(() => setMount(true));
                }
              }}
              disabled={itemData.name && itemData.description ? false : true}
            />
          </div>
        </div>
      </Segment>
      <Segment>
        {loading ? (
          <Loader />
        ) : (
          renderTable(
            [
              { text: "Name", value: "name" },
              { text: "Description", value: "description" },
              { text: "Actions", value: "action_btn" },
            ],
            listData.map((item) => ({
              ...item,
              action_btn: (
                <div className="mx-auto md:grid md:grid-cols-2 md:gap-x-0">
                  <Button
                    icon={PencilIcon}
                    colour="action"
                    className="md:ml-auto md:mr-2 mb-2 md:mb-0"
                    onClick={() => {
                      setEditing(true);
                      setItemData({ ...itemData, ...item });
                    }}
                  />
                  <Button
                    icon={TrashIcon}
                    colour="alert"
                    className="md:mr-auto md:mr-2"
                    onClick={() => {
                      setModalOpen(item._id);
                    }}
                  />
                </div>
              ),
            }))
          )
        )}
      </Segment>
    </>
  );
}
