import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import { Button } from "../../../components/Forms";
import { PencilIcon, PlusIcon } from "@heroicons/react/solid";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Segment from "../../../components/Segment";
import _appConfig from "../../../_appConfig";
import HtmlEditor from "../../../components/widgets/HtmlEditor";

export default function EmailManagement({ history }) {
  // const [listData, setListData] = useState([]);

  const params = useParams();

  const [data, setData] = useState({
    name: "",
    subject: "",
    body_data: "",
    footer_data: "",
  });
  // const [itemData, setItemData] = useState(initialState);

  const handleSaveData = (data, field) => {
    setData(a => {
      return {
        ...a,
        [field]: data
      }
    })
  }

  const [editing, setEditing] = useState(false);
  const [mount, setMount] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (mount && params.id) {
      setMount(false);
      setLoading(true);
      setEditing(true);

      API.settings
        .email_templates()
        .get(params.id)
        .then((res) =>
          setData({
            name: res.name,
            subject: res.subject,
            body_data: res.content,
            footer_data: res.footer_content,
          })
        )
        .catch((e) => CommonFunctions.handleError(e))
        .finally(() => setLoading(false));
    }
  }, [mount, loading]);

  const handleChange = (e) =>
    setData({ ...data, [e.target.name]: e.target.value });

  return (
    <Segment>
      <div>
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Template Settings
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Enter Name of template and subject
              </p>
            </div>
          </div>
          <div class="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div class="shadow sm:overflow-hidden sm:rounded-md">
                <div class="space-y-6 bg-white px-4 py-5 sm:p-6">
                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Template
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="name"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={data.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-3 sm:col-span-2">
                      <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Subject
                      </label>
                      <div class="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          name="subject"
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={data.subject}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="hidden sm:block" aria-hidden="true">
        <div class="py-5">
          <div class="border-t border-gray-200"></div>
        </div>
      </div>

      <div class="mt-10 sm:mt-0">
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Body Content
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Content to go into the body
              </p>
            </div>
          </div>
          <div class="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div class="overflow-hidden shadow sm:rounded-md">
                <div class="bg-white px-4 py-5 sm:p-6">
                  <div class="grid grid-cols-1 gap-6">
                    <div class="col-span-12 sm:col-span-3">
                      <div class="mt-1">
                        <HtmlEditor
                          content={data.body_data}
                          options={{
                            height: 400,
                          }}
                          onContentUpdate={(d) => handleSaveData(d, "body_data")}
                        />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="hidden sm:block" aria-hidden="true">
        <div class="py-5">
          <div class="border-t border-gray-200"></div>
        </div>
      </div>

      <div class="mt-10 sm:mt-0">
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="md:col-span-1">
            <div class="px-4 sm:px-0">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                Footer Content
              </h3>
              <p class="mt-1 text-sm text-gray-600">
                Conetent for footer of the template
              </p>
            </div>
          </div>
          <div class="mt-5 md:col-span-2 md:mt-0">
            <form action="#" method="POST">
              <div class="overflow-hidden shadow sm:rounded-md">
                <div class="bg-white px-4 py-5 sm:p-6">
                  <div class="grid grid-cols-1 gap-6">
                    <div class="col-span-12 sm:col-span-3">
                      <div class="mt-1">
                        <HtmlEditor
                          content={data.footer_data}
                          options={{
                            height: 400,
                          }}
                          onContentUpdate={(d) => handleSaveData(d, "footer_data")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-gray-50 px-4 py-3 text-right sm:px-6">
                  <Button
                    className={`inline-flex justify-center rounded-md border border-transparent py-2 px-4 ${editing ? "bg-orange-600 hover:bg-orange-700 focus:ring-orange-500" : "bg-green-600 hover:bg-green-700 focus:ring-green-500"} text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                    icon={editing ? PencilIcon : PlusIcon}
                    label={editing ? "Save" : "Add"}
                    colour={editing ? "warning" : "positive"}
                    onClick={() => {
                      let sendData = {
                        name: data.name,
                        subject: data.subject,
                        archived: false,
                        content: data.body_data,
                        footer_content: data.footer_data,
                        org_id: params.org_id ? params.org_id : undefined,
                      };

                      if (editing) {
                        API.settings
                          .email_templates()
                          .edit(params.id, sendData)
                          .then((res) =>
                            history.push(params.org_id ? `/admin/organisations/${params.org_id}/email_templates` : "/settings/email_templates")
                          )
                          .catch((e) => CommonFunctions.handleError(e));
                      } else {
                        API.settings
                          .email_templates()
                          .add(sendData)
                          .then((res) =>
                            history.push(params.org_id ? `/admin/organisations/${params.org_id}/email_templates` : "/settings/email_templates")
                          )
                          .catch((e) => CommonFunctions.handleError(e));
                      }
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Segment>
  );
}
