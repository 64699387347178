import React, {Fragment, useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import Segment from "../../../components/Segment";
import Loader from "../../../components/Loader";
import API from "../../../api";
import { BookOpenIcon, CheckIcon, ExclamationCircleIcon, UserCircleIcon, XIcon } from "@heroicons/react/solid";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import toast from "react-hot-toast";
import { SunIcon } from "@heroicons/react/outline";
import { Button, SelectTwo } from "../../../components/Forms";
import dayjs from "dayjs";
import { BiRefresh } from "react-icons/bi";
import { GoFilePdf } from "react-icons/go";

const Users = ({history}) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [globalError, setGlobalError] = useState(null);
  const [lastSync, setLastSync] = useState("N/A");
  const [users, setUsers] = useState([]);
  const [licences, setLicences] = useState([]);
  const [plans, setPlans] = useState([]);
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSubscriptions, setOpenSubscriptions] = useState(false);

  const [orgId, setOrgId] = useState(null);

  const [licence, setLicence] = useState({});
  const [renewOpen, setRenewOpen] = useState(false);

  const params = useParams();

  const getData = () => {
    setGlobalError(null);
    Promise.all([
      API.users.getAll(params.org_id),
      params.org_id ? API.organisations.get(params.org_id) : API.organisations.getMyOrg()
    ]).then(res => {
      console.log("🚀 ~ getData ~ res:", res)
      setUsers(res[0].users);
      setOrgId(res[1]._id);
      
      if(res[0].last_sync){
        setLastSync(dayjs(res[0].last_sync).format("DD/MM/YYYY HH:mm:ss"));
      }

      setLicences(res[1].transactions);

      let p = [
        { name: 'Unlicenced', value: -1 },
        { 
          name: 'Admin', 
          left: (res[1].transactions.filter(l => l.subscription && l.subscription.user == "admin").length - res[1].transactions.filter(l => l.subscription && l.subscription.user == "admin" && l.assigned_to).length), 
          assigned: res[1].transactions.filter(l => l.subscription && l.subscription.user == "admin" && l.assigned_to).length, 
          value: "admin"
        },
        { 
          name: 'User', 
          left: (res[1].transactions.filter(l => l.subscription && l.subscription.user == "user").length - res[1].transactions.filter(l => l.subscription && l.subscription.user == "user" && l.assigned_to).length), 
          assigned: res[1].transactions.filter(l => l.subscription && l.subscription.user == "user" && l.assigned_to).length, 
          value: "user"
        },
      ];

      setPlans(p);

      /*
      setLicences([{
        key: -1, seats: 0, left: 0, assigned: [], value: -1, text: "Unlicenced", selected: true
      }].concat(res[0].licences.map((l, index) => (
        { 
          key: (index + 1), 
          text: `${l.description} - ${l.left}/${l.seats} seats`, 
          description: l.description, 
          seats: l.seats, 
          value: `licence_${(index + 1)}`,
          left: l.left,
          disabled: !l.left > 0,
          assigned: l.assigned,
          user: l.user
        }
      )))); 
      */

    }).catch(err => {
      if(err.response !== undefined){
        setGlobalError(err.response.data.message);
      } else {
        setGlobalError("There was an unexpected error while trying to retrieve the users from the server.");
      }
    }).finally(() => {
      setIsInitialLoading(false);
    });
  };
    

  useEffect(() => {
    getData();
  }, []);

  if(isInitialLoading){
    return (
      <Loader>Retrieving data from server...</Loader>
    );
  }
  if(globalError){
    return (
      <div className={"m-4 p-2 bg-red-600 text-white"}>{globalError}</div>
    );
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const tiers = [
    {
      name: 'User',
      id: 'user',
      description: 'Basic user ',
    },
    {
      name: 'Postbox Authoriser',
      id: 'postbox',
      description: 'User with Postbox sending ability.',
    },
    {
      name: 'Administrator',
      id: 'admin',
      description: 'System Admins',
    },
  ]
  const sections = [
    {
      name: 'Access',
      features: [
        { name: 'Task List', tiers: { Administrator: true, "Postbox Authoriser": true, User: true } },
        { name: 'Prospects (View, Edit, Create)', tiers: { Administrator: true, "Postbox Authoriser": true, User: true } },
        { name: 'Client (View, Edit, Create, Convert)', tiers: { Administrator: true, "Postbox Authoriser": true, User: true } },
        { name: 'Matters (View, Edit, Create, Debts)', tiers: { Administrator: true, "Postbox Authoriser": true, User: true } }, 
        { name: 'Postbox - View', tiers: { Administrator: true, "Postbox Authoriser": true, User: "User's Postbox Only" } },
        { name: 'Postbox - Approve Email', tiers: { Administrator: true, "Postbox Authoriser": true, User: false } },
        { name: 'Notifications - Send & Receive', tiers: { Administrator: true, "Postbox Authoriser": true, User: true } },
        { name: 'Notifications - Global Notifications', tiers: { Administrator: true, "Postbox Authoriser": false, User: false } },
        { name: 'Settings', tiers: { Administrator: true, "Postbox Authoriser": false, User: false } },
      ],
    },
  ]

  const renderEditModal = () => {

    // let current_ = user && licences.filter(item => item.value == user.licence);

    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {
          setOpen(false);
          setUser(null);
        }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
  
          <div className="fixed inset-0 z-10 overflow-y-auto">
          
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="">

                  {(user && user.groups && user.groups.includes("god")) && 
                    
                    <div className="flex items-center gap-x-6 bg-primary py-2.5 px-6 sm:px-3.5 sm:before:flex-1 mb-2">
                      <p className="text-sm leading-6 text-white">
                          <strong className="font-semibold"><BookOpenIcon className="h-5 w-5 text-white inline" aria-hidden="true" /> God Account</strong>
                          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                            <circle cx={1} cy={1} r={1} />
                          </svg>
                          Permissions can't be changed for God account. God is all knowing and always active without a licence!
                      </p>
                    </div>}
                  {(user && user.groups && user.groups.includes("plc_admin")) && 
                    
                    <div className="flex items-center gap-x-6 bg-primary py-2.5 px-6 sm:px-3.5 sm:before:flex-1 mb-2">
                      <p className="text-sm leading-6 text-white">
                          <strong className="font-semibold"><BookOpenIcon className="h-5 w-5 text-white inline" aria-hidden="true" /> PLC Admin</strong>
                          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                            <circle cx={1} cy={1} r={1} />
                          </svg>
                          Licence not required for PLC Admins as they have full access to all the system including accessing other Organisations!
                      </p>
                    </div>}
                    
                    <div className="text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="">
                        <p className="text-sm text-gray-500">
                        <form className="space-y-8 divide-y divide-gray-200">
                          <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">

                            <div className="">
                              <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Edit User - {user && user.name}</h3>
                                <p className="mt-1 max-w-2xl text-sm text-gray-500">Edit details assosicated with User.</p>
                              </div>
                              <div className="space-y-6 sm:space-y-5">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    First Name
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={user && user.firstName}
                                      disabled={true} //Clio Controlled
                                      type="text"
                                      name="firstName"
                                      id="firstName"
                                      onChange={(e) => setUser({...user, firstName: e.target.value})}
                                      className="opacity-50 block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                    />
                                  </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Surname
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={user && user.lastName}
                                      disabled={true} //Clio Controlled
                                      type="text"
                                      name="lastName"
                                      id="lastName"
                                      onChange={(e) => setUser({...user, lastName: e.target.value})}
                                      className="opacity-50 block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm"
                                    />
                                  </div>
                                </div>

                                {(user && user.groups && !user.groups.includes("god")) && 

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Active
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                  <input
                                      id="active"
                                      name="active"
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                      checked={user && user.active}
                                      onChange={(e) => setUser({...user, active: !user.active})}
                                    />
                                  </div>
                                </div>}

                                {(user && user.groups && !user.groups.includes("god") && !user.groups.includes("plc_admin")) && 

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Licence
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">

                                    <RadioGroup
                                      value={user && user.licence ? user.licence : -1}
                                      onChange={(e) => {
                                        let groups = [];
                                        switch(e){
                                          case "user":
                                            groups = ["user"];
                                          break;
                                          case "admin":
                                            groups = ["administrator"];
                                          break;
                                        }
                                        if(user){
                                          setUser({ ...user, licence: e, groups })}
                                        } 
                                      }
                                      className="space-y-4"
                                    >
                                      {plans.map((plan) => (
                                        <RadioGroup.Option
                                          key={plan.name}
                                          value={plan.value}
                                          aria-label={plan.name}
                                          disabled={plan.value !== -1 && !plan.left > 0}
                                          className={` ${plan.value !== -1 && !plan.left > 0 ? "opacity-30" : ""} group relative block cursor-pointer rounded-lg border border-gray-300 bg-white px-6 py-4 shadow-sm focus:outline-none data-[focus]:border-primary data-[focus]:ring-2 data-[focus]:ring-primary sm:flex sm:justify-between`}
                                        >
                                          {({ checked }) => (
                                            <>
                                              <span className="flex items-center">
                                                <span className="flex flex-col text-sm">
                                                  <span className="font-medium text-primary">{plan.name}</span>
                                                </span>
                                              </span>
                                              {plan.value !== -1 && (
                                                <span className="mt-2 flex text-sm sm:ml-4 sm:mt-0 sm:flex-col sm:text-right">
                                                  <span className="font-medium text-secondary">
                                                    {/* {plan.assigned} / {plan.left} */}
                                                    {plan.left} Available
                                                  </span>
                                                </span>
                                              )}
                                              <span
                                                aria-hidden="true"
                                                className={`pointer-events-none absolute -inset-px rounded-lg border-2 ${
                                                  checked ? 'border-primary' : 'border-transparent'
                                                }`}
                                              />
                                            </>
                                          )}
                                        </RadioGroup.Option>
                                      ))}
                                    </RadioGroup>

                                  {/*  

                                  <SelectTwo 
                                    placeholder="Please Select Licence"
                                    field="licence"
                                    options={licences.filter(l => l.left > 0 || l.key == -1 || current_.key == l.key)}
                                    value={user && user.licence ? user.licence : -1} //`licence_${user_license}`
                                    onChange={(e) => {

                                      let update_licences = licences;

                                      let current_license = user && licences.findIndex(item => item.value == user.licence);
                                      // user license is already set, increase the left value on current
                                      if(current_license !== -1){
                                        
                                        update_licences[current_license].left += 1;
                                        update_licences[current_license].disabled = false;
                                        
                                        update_licences[current_license].text = `${update_licences[current_license].description} - ${update_licences[current_license].left}/${update_licences[current_license].seats} seats`;
                                      }

                                      setUser({ ...user, [e.target.name]: e.target.value });

                                      let new_licence = update_licences.findIndex(item => item.value == e.target.value);

                                      if(e.target.value !== -1){

                                        update_licences[new_licence].left -= 1;

                                        if(!update_licences[new_licence].left > 0){
                                          update_licences[new_licence].disabled = true;
                                        }

                                        update_licences[new_licence].text = `${update_licences[new_licence].description} - ${update_licences[new_licence].left}/${update_licences[new_licence].seats} seats`;

                                      }

                                      setLicences(update_licences);

                                    }}
                                  />*/}
                                  </div>
                                </div>}

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label htmlFor="email" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Email address
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={user && user.email}
                                      disabled={true} //Clio Controlled
                                      id="email"
                                      name="email"
                                      type="email"
                                      onChange={(e) => setUser({...user, email: e.target.value})}
                                      className="opacity-50 block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Phone Number
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={user && user.phoneNumber}
                                      id="phoneNumber"
                                      name="phoneNumber"
                                      type="text"
                                      onChange={(e) => setUser({...user, phoneNumber: e.target.value})}
                                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Job Title 
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={user && user.jobTitle}
                                      type="text"
                                      name="jobTitle"
                                      id="jobTitle"
                                      onChange={(e) => setUser({...user, jobTitle: e.target.value})}
                                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    User Rate (p/h)
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <input
                                      value={user && user.rate}
                                      type="number"
                                      name="rate"
                                      id="rate"
                                      onChange={(e) => setUser({...user, rate: e.target.value})}
                                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    />
                                  </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                                  <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    Postbox Signature
                                  </label>
                                  <div className="mt-1 sm:col-span-2 sm:mt-0">
                                    <textarea
                                      id="signature"
                                      name="signature"
                                      rows={3}
                                      onChange={(e) => setUser({...user, signature: e.target.value})}
                                      className="block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      value={user && user.signature}
                                    />
                                    <p className="mt-2 text-sm text-gray-500">Email sign off.</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {(user && user.groups && !user.groups.includes("god")) && 

                            <div className="divide-y divide-gray-200 pt-4">
                              <div className="divide-y divide-gray-200">
                                <div className="">
                                  <div role="group" aria-labelledby="label-notifications">
                                    <div className="sm:grid sm:grid-cols-3 sm:items-baseline sm:gap-4">
                                      <div>
                                        <div
                                          className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
                                          id="label-notifications"
                                        >
                                          System Role
                                        </div>
                                      </div>
                                      <div className="sm:col-span-2">
                                        <div className="max-w-lg">
                                          {/* {current_[0].user == "user" || current_[0].user === "admin" ?  */}
                                          {user.licence == "user" || user.licence === "admin" ? 
                                          <>
                                            <p className="text-sm text-gray-500">Select role for User Account</p>
                                            <div className="mt-4 space-y-4">
                                              {user.licence === "admin" &&
                                              <div className="flex items-center">
                                                <input
                                                  id="admin"
                                                  name="groups"
                                                  type="radio"
                                                  className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                                                  checked={user && user.groups && user.groups.includes("administrator")}
                                                  onChange={() => setUser({...user, groups: ["administrator"]})}
                                                />
                                                <label htmlFor="admin" className="ml-3 block text-sm font-medium text-gray-700">
                                                  Administrator
                                                </label>
                                              </div>}
                                              {user.licence === "user" && <>
                                                <div className="flex items-center">
                                                  <input
                                                    id="postbox_authorizer"
                                                    name="groups"
                                                    type="radio"
                                                    className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                                                    checked={user && user.groups && user.groups.includes("postbox_authorizer")}
                                                    onChange={() => setUser({...user, groups: ["postbox_authorizer"]})}
                                                  />
                                                  <label htmlFor="postbox_authorizer" className="ml-3 block text-sm font-medium text-gray-700">
                                                    Postbox Authorizer
                                                  </label>
                                                </div>
                                                <div className="flex items-center">
                                                  <input
                                                    id="user"
                                                    name="groups"
                                                    type="radio"
                                                    className="h-4 w-4 border-gray-300 text-primary focus:ring-primary"
                                                    checked={user && user.groups && user.groups.includes("user")}
                                                    onChange={() => setUser({...user, groups: ["user"]})}
                                                  />
                                                  <label htmlFor="user" className="ml-3 block text-sm font-medium text-gray-700">
                                                    User
                                                  </label>
                                                </div>
                                              </>}
                                              
                                            </div>
                                          </> : <p className="text-sm text-gray-500">User must have a License for a System Role</p>}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> }
                          </div>

                          <div className="pt-5">
                            <div className="flex justify-end">
                              <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setOpen(false)}
                              >
                                Cancel
                              </button>
                              <button
                                disabled={user && !user.groups.length > 0 && (user.licence == "user" || user.licence === "admin")}
                                type="submit"
                                className={`${user && !user.groups.length > 0 && (user.licence == "user" || user.licence === "admin") ? "opacity-50" : ""} ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2`}
                                onClick={() => {
                                  console.log("🚀 ~ API.users.update ~ user._id:", user)
                                  API.users.update(user._id, user).then((res) => {
                                    if(res.success){
                                      setOpen(false);
                                      getData();
                                      toast.success(`User updated successfully`);
                                    }
                                  });
                                }}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </form>
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  }
  const renderSubscriptionsModal = () => {

    return(
      <Transition
        show={openSubscriptions}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={openSubscriptions}
          onClose={() => setOpenSubscriptions(false)}
          className="fixed z-10 inset-0 overflow-y-auto"
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className=" inline-block w-full max-w-5xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="text-xl">
                <Dialog.Title>
                  Licence Subscriptions
                </Dialog.Title>
              </div>
              <div className="flex flex-col px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="text-center">
                      <tr>
                        <th>Type</th>
                        {/* <th>Purchased</th> */}
                        {/* <th>Assigned</th> */}
                        {/* <th>Remaining</th> */}
                        <th>Receipt</th>
                        <th>Assigned To</th>
                        <th>Expiry Date</th>
                        <th>Days To Renew</th>
                        <th>Payment Type</th>
                        <th>Renew</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {licences.sort((a, b) => {
                          if (a.subscription.user === "admin" && b.subscription.user === "user") return -1;
                          if (a.subscription.user === "user" && b.subscription.user === "admin") return 1;
                          return 0;
                        }).map(l => {
                          let days = l.expiry ? dayjs(l.expiry).diff(dayjs(),'day') : 0;
                          return(
                            <tr>
                            <td>{l.subscription.name}</td>
                            <td>{l.purchase ? <Button
                                // onClick={() => window.open(l.purchase.receipt_url[l.purchase.receipt_url.length - 1].url, "_blank")}
                                onClick={() => {

                                  let purchase = l.purchase ? l.purchase.receipt_url : [];
                                  let url = purchase[purchase.length - 1] ? purchase[purchase.length - 1].url : null;

                                    if(url){
                                      window.open(url, "_blank")
                                    }else{
                                      console.log("licence", l);
                                      console.log("purhcase", purchase);
                                      toast.error("No Recipient URL found!")
                                    }
                                    
                                  }
                                }
                                label="View"
                                colour="action"
                                icon={GoFilePdf}
                              />  : "No Assigned Receipt"}</td>
                            <td>{l.assigned_to ? l.assigned_to.name : "Not Assigned" }</td>
                            <td>{l.expiry && dayjs(l.expiry).format("DD/MM/YYYY HH:mm")}</td>
                            <td><span className={days <= 0 ? "animate-pulse text-red-500" : days <= 28 ? "animate-pulse text-orange-500" : ""}>{days} days</span></td>
                            <td>{`${l.package.duration} ${l.package.frequency} ${l.package.payment}`}</td>
                            <td className="text-center">
                            {l.package.payment === "single" ? days <= 28 ? <Button
                            
                              label="Renew"
                              color="positive"
                              className="text-xs mx-auto"
                              onClick={() => {
                                setRenewOpen(true);
                                setLicence({...l.package, lid: l._id})
                              }}
                              /> : "Not Nearing Expiration" : "Renews Automatically"}
                            </td>
                          </tr>)}
                        )}
                        {/*  
                      <tr>
                        <td>Admin</td>

                        <td>{licences.filter(l => l.subscription && l.subscription.user == "admin").length}</td>
                        <td>{licences.filter(l => l.subscription && l.subscription.user == "admin" && l.assigned_to).length}</td>
                        <td>{licences.filter(l => l.subscription && l.subscription.user == "admin").length - licences.filter(l => l.subscription && l.subscription.user == "admin" && l.assigned_to).length}</td>

                        <td>{users.filter(u => u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user == "admin").map(u => <>{u.name}<br/></>)}</td>
                        <td>{users.filter(u => u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user == "admin").map(u => <>{u.licence_transaction.expiry ? dayjs(u.licence_transaction.expiry).format("DD/MM/YYYY HH:mm") : ""}<br/></>)}</td>
                        <td>{users.filter(u => u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user == "admin").map(u => {
                          let days = u.licence_transaction.expiry ? dayjs(u.licence_transaction.expiry).diff(dayjs(),'day') : 0;
                          //16 days
                          return <span className={days <= 0 ? "animate-pulse text-red-500" : days <= 28 ? "animate-pulse text-orange-500" : ""}>{days} days<br/></span>
                        } )}</td>
                        <td>{users.filter(u => u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user == "admin").map(u => <>{`${u.licence_transaction.package.duration} ${u.licence_transaction.package.frequency} ${u.licence_transaction.package.payment}`}<br/></>)}</td>
                        <td>{users.filter(u => u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user == "admin").map(u => <>
                          {u.licence_transaction.package.payment === "single" ? <Button
                            
                            label="Renew"
                            color="positive"
                            className="text-xs"
                            onClick={() => {
                              setRenewOpen(true);
                              setLicence({...u.licence_transaction.package, lid: u.licence_transaction._id})
                            }}
                            /> : "Renews Automatically"}
                        <br/></>)}</td>
                      </tr>
                      <tr class="border-t">
                        <td>User</td>

                        <td>{licences.filter(l => l.subscription && l.subscription.user == "user").length}</td>
                        <td>{licences.filter(l => l.subscription && l.subscription.user == "user" && l.assigned_to).length}</td>
                        <td>{licences.filter(l => l.subscription && l.subscription.user == "user").length - licences.filter(l => l.subscription && l.subscription.user == "user" && l.assigned_to).length}</td>         

                        <td>{users.filter(u => u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user == "user").map(u => <>{u.name}<br/></>)}</td>
                        <td>{users.filter(u => u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user == "user").map(u => <>{u.licence_transaction.expiry ? dayjs(u.licence_transaction.expiry).format("DD/MM/YYYY HH:mm") : ""}<br/></>)}</td>
                        <td>{users.filter(u => u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user == "user").map(u => {
                          let days = u.licence_transaction.expiry ? dayjs(u.licence_transaction.expiry).diff(dayjs(),'day') : 0;
                          //16 days
                          return <span className={days <= 0 ? "animate-pulse text-red-500" : days <= 28 ? "animate-pulse text-orange-500" : ""}>{days} days<br/></span>
                        } )}</td>
                        <td>{users.filter(u => u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user == "user").map(u => <>{`${u.licence_transaction.package.duration} ${u.licence_transaction.package.frequency} ${u.licence_transaction.package.payment}`}<br/></>)}</td>
                        <td>{users.filter(u => u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user == "user").map(u => <>
                          
                          {u.licence_transaction.package.payment === "single" ? <Button
                            
                            label="Renew"
                            color="positive"
                            className="text-xs"
                            onClick={() => {
                              setRenewOpen(true);
                              setLicence({...u.licence_transaction.package, lid: u.licence_transaction._id})
                            }}
                            /> : "Renews Automatically"}
                        
                        <br/></>)}</td>
                      </tr>*/}
                    </tbody>
                  </table>
              </div>
              <div className="flex justify-between mt-4 border-t border-gray-300 pt-4">
                <Button
                  colour="alert"
                  label="Close"
                  onClick={() => setOpenSubscriptions(false)}
                  icon={XIcon}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );

  }

  const renewDialog = () => {
    return (
      <Transition
        show={renewOpen}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Dialog
          open={renewOpen}
          onClose={() => setRenewOpen(false)}
          className="fixed z-10 inset-0 overflow-y-auto"
        >
          <div className="text-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className=" inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="text-xl">
                <Dialog.Title>
                  Renew Licence
                </Dialog.Title>
              </div>
              <div className="px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                <div className="">
                <h3
                  className={classNames(
                    'text-gray-900',
                    'text-lg font-semibold leading-8',
                  )}
                >
                  {licence && licence.name}
                </h3>
                <p className="mt-1 text-sm leading-6 text-gray-600">{licence && licence.text}</p>
                <p className="mt-1 flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold tracking-tight text-gray-900">£{licence && licence.price}</span>
                    <span className="text-sm font-semibold leading-6 text-gray-600">/{licence && licence.frequency}</span>
                  </p>
                </div>
              </div>
              <div className="flex justify-between mt-4 border-t border-gray-300 pt-4">
                <Button
                  colour="alert"
                  label="Cancel"
                  onClick={() => {setRenewOpen(false); setLicence({})}}
                  icon={XIcon}
                />

                <Button
                  colour="positive"
                  label="Renew"
                  onClick={() => {
                    API.organisations.renewLicence(orgId, licence.lid).then(res => {
                      if(res.success) {
                        toast.success(res.message);
                        getData();
                        setRenewOpen(false);
                      }else{
                        toast.error(res.message);
                      }
                    });
                  }}
                  // icon={GiBasket}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const setEditUser = (user) => {

    setUser(user);
    setOpen(true);
    return;

    let user_license = user && licences.findIndex(item => item.assigned.includes(user._id));
    setUser({...user, licence: user_license !== -1 ? `licence_${user_license}` : -1})
    setOpen(true);
  }

  const adminlicencesExpiringSoon = users.filter(u => {
    if (u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user == "admin") {
      let days = u.licence_transaction.expiry ? dayjs(u.licence_transaction.expiry).diff(dayjs(),'day') : 0;
      return days !== null && days <= 28; //16 days
    }
    return false;
  });
  const adminlicencesExpired = users.filter(u => {
    if (u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user == "admin") {
      let days = u.licence_transaction.expiry ? dayjs(u.licence_transaction.expiry).diff(dayjs(),'day') : 0;
      return days !== null && days <= 0;
    }
    return false;
  });
  const userlicencesExpiringSoon = users.filter(u => {
    if (u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user == "user") {
      let days = u.licence_transaction.expiry ? dayjs(u.licence_transaction.expiry).diff(dayjs(),'day') : 0;
      return days !== null && days <= 28; // 16 days
    }
    return false;
  });
  const userlicencesExpired = users.filter(u => {
    if (u.licence_transaction && u.licence_transaction.subscription && u.licence_transaction.subscription.user == "user") {
      let days = u.licence_transaction.expiry ? dayjs(u.licence_transaction.expiry).diff(dayjs(),'day') : 0;
      return days !== null && days <= 0;
    }
    return false;
  });

  return (
    <React.Fragment>
      {renderEditModal()}
      {renewDialog()}
      {renderSubscriptionsModal()}
      <Segment>
        <div className="px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Users</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the users on the system <br/>
                Users are created in Clio and automatically added when signed in or you can Sync the users from your organisation<br/>
                Last Sync: {lastSync} <br/>
                <Button
                disabled={params.org_id} 
                colour="warning" label="Sync" onClick={() => {
                  API.users.sync().then(res => {
                    if(res.success){
                      toast.success(res.message);
                      setIsInitialLoading(true);
                      getData();
                    }else{
                      toast.error(res.message);
                    }
                  })
                }} icon={BiRefresh}
              />
              {params.org_id && <i>User sync not available via Admin as Clio API runs from current logged in organisation!</i>}
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-auto">
              <p className="mt-2 text-sm text-gray-700">
                  User Licences: 
                  <div
                    className="flex"
                  >
                    <Button label="View Subscriptions" onClick={() => setOpenSubscriptions(true)} color="action" /> 
                  {!params.org_id && <Button label="Add Licence" className="ml-3" onClick={() => history.push(`/settings/account/${orgId}/purchase_license`)} color="positive" />}
                  </div>
                  <br/>
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Purchased</th>
                      <th>Assigned</th>
                      <th>Remaining</th>
                      <th>Expiring Soon (28 days)</th>
                      <th>Expired</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    <tr>
                      <td>Admin</td>
                      <td>{licences.filter(l => l.subscription && l.subscription.user == "admin").length}</td>
                      <td>{licences.filter(l => l.subscription && l.subscription.user == "admin" && l.assigned_to).length}</td>
                      <td>{licences.filter(l => l.subscription && l.subscription.user == "admin").length - licences.filter(l => l.subscription && l.subscription.user == "admin" && l.assigned_to).length}</td>
                      <td>{adminlicencesExpiringSoon.length}</td>
                      <td>{adminlicencesExpired.length}</td>
                    </tr>
                    <tr>
                      <td>User</td>
                      <td>{licences.filter(l => l.subscription && l.subscription.user == "user").length}</td>
                      <td>{licences.filter(l => l.subscription && l.subscription.user == "user" && l.assigned_to).length}</td>
                      <td>{licences.filter(l => l.subscription && l.subscription.user == "user").length - licences.filter(l => l.subscription && l.subscription.user == "user" && l.assigned_to).length}</td>
                      <td>{userlicencesExpiringSoon.length}</td>
                      <td>{userlicencesExpired.length}</td>
                    </tr>
                  </tbody>
                </table>
              </p>
              
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Signature
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Title
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Role
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Rate (p/h)
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Licence
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Account Created
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-6 sm:pr-0">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {users.map((user) => 
                    {

                      let daysLeft = user.licence_transaction ? user.licence_transaction.expiry ? dayjs(user.licence_transaction.expiry).diff(dayjs(),'day') : 0 : null;

                      return(
                        <tr key={user.email}>
                          <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm sm:pl-0">
                            <div className="flex items-center">
                            {(user && user.groups && !user.groups.includes("god")) ? 
                              <div className="h-10 w-13 flex-shrink-0">
                              {user.groups.includes("plc_admin") ? 
                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  PLC Admin
                                </span> : user.active && user.licence ? 
                                daysLeft <= 0 ? 
                                <span className="inline-flex rounded-full animate-pulse bg-red-200 px-2 text-xs font-semibold leading-5 text-red-700">
                                  Expired!
                                </span> :
                                // 16 days
                                daysLeft <= 28 ? 
                                <span className="inline-flex rounded-full animate-pulse bg-orange-200 px-2 text-xs font-semibold leading-5 text-orange-700">
                                  Expiring Soon
                                </span> :

                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  Active
                                </span>
                                : user.active ? 
                                <span className="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">
                                  Un-Licenced
                                </span> :
                                <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                  Disabled
                                </span>}
                              </div> : <div className="h-10 w-13 flex-shrink-0">
                                <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                  God
                                </span>
                                </div>}
                              <div className="ml-4">
                                <div className="font-medium text-gray-900">{user.name}</div>
                                <div className="text-gray-500">{user.email}</div>
                                <div className="text-gray-500">{user.phoneNumber}</div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {user.signature}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <div className="text-gray-900">{user.jobTitle}</div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.groups}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">£{user.rate} per hour</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            
                            {user.licence_transaction && user.licence_transaction.subscription && <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">
                              {user.licence_transaction.subscription.description}
                                </span>}

                            {user.licence_transaction && user.licence_transaction.package && <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">
                              {user.licence_transaction.package.duration} {user.licence_transaction.package.frequency} {user.licence_transaction.package.payment}
                                </span>}

                            {user.licence_transaction && <span className="inline-flex rounded-full bg-blue-100 px-2 text-xs font-semibold leading-5 text-blue-800">
                              Purchased: {dayjs(user.licence_transaction.createdAt).format("DD/MM/YYYY")} Expires:{dayjs(user.licence_transaction.expiry).format("DD/MM/YYYY")}
                                </span>}

                                
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.createdAt && dayjs(user.createdAt).format("DD/MM/YYYY")}</td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-right text-sm font-medium sm:pr-0">
                            <a href="#" onClick={() => setEditUser(user)} className="text-indigo-600 hover:text-indigo-900">
                              Edit<span className="sr-only">, {user.name}</span>
                            </a>
                          </td>
                        </tr>
                      )
                    }
                    
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </Segment>
      <Segment>
        <div className="px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Permissions</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the permissions per role
              </p>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-my-2 -mx-6 overflow-x-auto lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="isolate">
                  {/* Feature comparison (up to lg) */}
                  <section aria-labelledby="mobile-comparison-heading" className="lg:hidden">

                        <div className="mx-auto max-w-2xl space-y-16">
                          {tiers.map((tier) => (
                            <div key={tier.id} className="border-t border-gray-900/10">
                              <div
                                className={classNames(
                                  tier.featured ? 'border-indigo-600' : 'border-transparent',
                                  '-mt-px w-72 border-t-2 pt-10 md:w-80'
                                )}
                              >
                                <h3
                                  className={classNames(
                                    tier.featured ? 'text-indigo-600' : 'text-gray-900',
                                    'text-sm font-semibold leading-6'
                                  )}
                                >
                                  {tier.name}
                                </h3>
                                <p className="mt-1 text-sm leading-6 text-gray-600">{tier.description}</p>
                              </div>

                              <div className="mt-10 space-y-10">
                                {sections.map((section) => (
                                  <div key={section.name}>
                                    <h4 className="text-sm font-semibold leading-6 text-gray-900">{section.name}</h4>
                                    <div className="relative mt-6">
                                      {/* Fake card background */}
                                      <div
                                        aria-hidden="true"
                                        className="absolute inset-y-0 right-0 hidden w-1/2 rounded-lg bg-white shadow-sm sm:block"
                                      />

                                      <div
                                        className={classNames(
                                          tier.featured ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-900/10',
                                          'relative rounded-lg bg-white shadow-sm sm:rounded-none sm:bg-transparent sm:shadow-none sm:ring-0'
                                        )}
                                      >
                                        <dl className="divide-y divide-gray-200 text-sm leading-6">
                                          {section.features.map((feature) => (
                                            <div
                                              key={feature.name}
                                              className="flex items-center justify-between py-3 px-4 sm:grid sm:grid-cols-2 sm:px-0"
                                            >
                                              <dt className="pr-4 text-gray-600">{feature.name}</dt>
                                              <dd className="flex items-center justify-end sm:justify-center sm:px-4">
                                                {typeof feature.tiers[tier.name] === 'string' ? (
                                                  <span
                                                    className={tier.featured ? 'font-semibold text-indigo-600' : 'text-gray-900'}
                                                  >
                                                    {feature.tiers[tier.name]}
                                                  </span>
                                                ) : (
                                                  <>
                                                    {feature.tiers[tier.name] === true ? (
                                                      <CheckIcon className="mx-auto h-5 w-5 text-green-500" aria-hidden="true" />
                                                    ) : (
                                                      <XIcon className="mx-auto h-5 w-5 text-red-400" aria-hidden="true" />
                                                    )}

                                                    <span className="sr-only">
                                                      {feature.tiers[tier.name] === true ? 'Yes' : 'No'}
                                                    </span>
                                                  </>
                                                )}
                                              </dd>
                                            </div>
                                          ))}
                                        </dl>
                                      </div>

                                      {/* Fake card border */}
                                      <div
                                        aria-hidden="true"
                                        className={classNames(
                                          tier.featured ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-900/10',
                                          'pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 rounded-lg sm:block'
                                        )}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </section>

                      {/* Feature comparison (lg+) */}
                      <section aria-labelledby="comparison-heading" className="hidden lg:block">

                        <div className="grid grid-cols-4 gap-x-8 border-t border-gray-900/10 before:block">
                          {tiers.map((tier) => (
                            <div key={tier.id} aria-hidden="true" className="-mt-px">
                              <div
                                className={classNames(
                                  tier.featured ? 'border-indigo-600' : 'border-transparent',
                                  'border-t-2 pt-10'
                                )}
                              >
                                <p
                                  className={classNames(
                                    tier.featured ? 'text-indigo-600' : 'text-gray-900',
                                    'text-sm font-semibold leading-6'
                                  )}
                                >
                                  {tier.name}
                                </p>
                                <p className="mt-1 text-sm leading-6 text-gray-600">{tier.description}</p>
                              </div>
                            </div>
                          ))}
                        </div>

                        <div className="-mt-6 space-y-16">
                          {sections.map((section) => (
                            <div key={section.name}>
                              <h3 className="text-sm font-semibold leading-6 text-gray-900">{section.name}</h3>
                              <div className="relative -mx-8 mt-10">
                                {/* Fake card backgrounds */}
                                <div
                                  className="absolute inset-y-0 inset-x-8 grid grid-cols-4 gap-x-8 before:block"
                                  aria-hidden="true"
                                >
                                  <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                                  <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                                  <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                                </div>

                                <table className="relative w-full border-separate border-spacing-x-8">
                                  <thead>
                                    <tr className="text-left">
                                      <th scope="col">
                                        <span className="sr-only">Feature</span>
                                      </th>
                                      {tiers.map((tier) => (
                                        <th key={tier.id} scope="col">
                                          <span className="sr-only">{tier.name} tier</span>
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {section.features.map((feature, featureIdx) => (
                                      <tr key={feature.name}>
                                        <th
                                          scope="row"
                                          className="w-1/4 py-3 pr-4 text-left text-sm font-normal leading-6 text-gray-900"
                                        >
                                          {feature.name}
                                          {featureIdx !== section.features.length - 1 ? (
                                            <div className="absolute inset-x-8 mt-3 h-px bg-gray-200" />
                                          ) : null}
                                        </th>
                                        {tiers.map((tier) => (
                                          <td key={tier.id} className="relative w-1/4 px-4 py-0 text-center">
                                            <span className="relative h-full w-full py-3">
                                              {typeof feature.tiers[tier.name] === 'string' ? (
                                                <span
                                                  className={classNames(
                                                    tier.featured ? 'font-semibold text-indigo-600' : 'text-gray-900',
                                                    'text-sm leading-6'
                                                  )}
                                                >
                                                  {feature.tiers[tier.name]}
                                                </span>
                                              ) : (
                                                <>
                                                  {feature.tiers[tier.name] === true ? (
                                                    <CheckIcon className="mx-auto h-5 w-5 text-green-500" aria-hidden="true" />
                                                  ) : (
                                                    <XIcon className="mx-auto h-5 w-5 text-red-400" aria-hidden="true" />
                                                  )}

                                                  <span className="sr-only">
                                                    {feature.tiers[tier.name] === true ? 'Yes' : 'No'}
                                                  </span>
                                                </>
                                              )}
                                            </span>
                                          </td>
                                        ))}
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>

                                {/* Fake card borders */}
                                <div
                                  className="pointer-events-none absolute inset-y-0 inset-x-8 grid grid-cols-4 gap-x-8 before:block"
                                  aria-hidden="true"
                                >
                                  {tiers.map((tier) => (
                                    <div
                                      key={tier.id}
                                      className={classNames(
                                        tier.featured ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-900/10',
                                        'rounded-lg'
                                      )}
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Segment>
    </React.Fragment>
  );
}
export default Users;