import React, { useState, useEffect, Fragment } from "react";
import { GiHouse } from "react-icons/gi";
import {
  UserIcon,
  OfficeBuildingIcon,
  ReceiptTaxIcon,
  PhoneIcon,
  CalendarIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import { Button } from "../../../../components/Forms";
import { Dialog, Transition } from "@headlessui/react";
import API from "../../../../api";
export default function Bar({ debtor, history, contact = {}, showEdit, showArchive }) {

  const [modalOpen, setModalOpen] = useState(false);

  const renderModal = () => {
    const handleClose = () => {
      setModalOpen(false);
    };

    const handleModalSubmit = () => {

      API.matters.debtors.archive(debtor.matter,debtor._id).then((res) => {
        history.push(
          `/matters/${debtor.matter}/debtors`
        )
      });
      setModalOpen(false);
    };

    return (
      <Transition.Root show={modalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={handleClose}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:py-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Archive Debtor : {debtor.debtor_name}
                    </Dialog.Title>
                    <div className="mt-4">
                      <div className={`overflow-x-auto text-lg`}>
                       Are you sure you wish to Archive the debtor?
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button
                    label="Archive"
                    colour="alert"
                    onClick={handleModalSubmit}
                  />
                  <Button
                    label="Cancel"
                    colour="alert"
                    onClick={handleClose}
                  />
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <div className="mb-2 mx-auto px-4 md:flex md:items-center md:justify-between md:space-x-5 lg:px-4 border-b pb-2">
    {renderModal()}
      <div className="flex items-center space-x-5">
        <div>
          <h1 className="text-2xl font-bold text-primary">
          {debtor.debtor_salutation ? debtor.debtor_salutation + " " : ""}{debtor.debtor_name}
          </h1>
          <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <OfficeBuildingIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              Type: {debtor.company_type ? debtor.company_type : "--"}
            </div>
            {/* <div className="mt-2 flex items-center text-sm text-gray-500">
              <GiHouse
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {debtor.addresses &&
              debtor.addresses.find((item) => item.primary === true)
                ? `${
                    debtor.addresses.find((item) => item.primary === true)
                      .postal_code
                  } (${
                    debtor.addresses.find((item) => item.primary === true).name
                  })`
                : "N/A"}
            </div> */}
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <UserIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {debtor.contact_name ? debtor.contact_name : "--"}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <PhoneIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {debtor.contact_phone ? debtor.contact_phone : "--"}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <CalendarIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              Payment terms:{" "}
              {contact.settings && contact.settings.paymentTerms
                ? contact.settings.paymentTerms + " days."
                : "--"}
            </div>
          </div>
        </div>
      </div>

      <span className="isolate inline-flex rounded-md shadow-sm">
      {showEdit && (
      <button
        type="button"
        className={`relative inline-flex items-center rounded-l-md ${!showArchive && 'rounded-r-md'} bg-orange-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-orange-500 hover:bg-orange-400 focus:z-10`}
        onClick={() =>
          history.push(
            `/matters/${debtor.matter}/debtors/${debtor._id}/edit`
          )
        }
      >
        <PencilIcon className="inline-block h-5" />{" "}Edit Debtor
      </button>
      )}
      {showArchive && (
      <button
        type="button"
        className="relative -ml-px inline-flex items-center rounded-r-md bg-red-500 px-3 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-red-500 hover:bg-red-400 focus:z-10"
        onClick={() => setModalOpen(true)}
      >
        <TrashIcon className="inline-block h-5"/>{" "} (Admin) Archive
      </button>
      )}
    </span>
    </div>
  );
}
