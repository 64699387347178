import { useEffect, useState } from "react";
import _api from "../../../api";
import { GiCheckMark,GiBasket } from "react-icons/gi";
import toast from "react-hot-toast";
import { Button, Input } from "../../../components/Forms";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XIcon } from "@heroicons/react/solid";

export default function ListLicences({buy = false, admin = false}) {

    const [licenceTypes, setLicenceTypes] = useState([]);
    const [mount, setMount] = useState(true);
    const [org, setOrg] = useState({});

    const [licence, setLicence] = useState({});
    const [isOpen, setIsOpen] = useState(false);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
    const frequencies = [
      { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
      { value: 'yearly', label: 'Annually', priceSuffix: '/year' },
    ]
    const [frequency, setFrequency] = useState(frequencies[0])

    useEffect(() => {
        if(mount){

            Promise.all([
                _api.organisations.getMyOrg(),
                _api.licences.getAll()])
                .then(res => {

                setOrg(res[0]);

                let arr = res[1].licences.map((l, index) => {
                  return ({
                    key: index, 
                    value: l._id, 
                    text: l.description,
                    name: l.name,
                    price: l.price,
                    seats: l.seats,
                    duration: l.duration,
                    frequency: l.frequency,
                    discount: l.discount,
                    payment: l.payment,
                    user: l.subscription ? l.subscription.user : ""
                  })
                });
                setLicenceTypes(arr);
                setMount(false);
            });
        }
        
    }, [])

    const purchaseDialog = () => {

        const handlePaymentSuccess = (response) => {
          console.log('Payment Successful:', response);
          // Handle success (e.g., update UI, send data to server)
        };
      
        const handlePaymentFailure = (response) => {
          console.log('Payment Failed:', response);
          // Handle failure (e.g., show error message)
        };
    
        return (
          <Transition
            show={isOpen}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Dialog
              open={isOpen}
              onClose={() => setIsOpen(false)}
              className="fixed z-10 inset-0 overflow-y-auto"
            >
              <div className="text-center min-h-screen">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-80" />
                <span
                  className="inline-block h-screen align-middle"
                  aria-hidden="true"
                >
                  &#8203;
                </span>
                <div className=" inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <div className="text-xl">
                    <Dialog.Title>
                      Purchase License
                    </Dialog.Title>
                  </div>
                  <div className="px-2 py-4 border-t border-gray-300 mt-2 justify-center align-middle">
                    <div className="">
                    <h3
                      className={classNames(
                        'text-gray-900',
                        'text-lg font-semibold leading-8',
                      )}
                    >
                      {licence && licence.name}
                    </h3>
                    <p className="mt-1 text-sm leading-6 text-gray-600">{licence && licence.text}</p>
                    <p className="mt-1 flex items-baseline gap-x-1">
                        <span className="text-4xl font-bold tracking-tight text-gray-900">£{licence && licence.price}</span>
                        <span className="text-sm font-semibold leading-6 text-gray-600">{licence && licence.payment != "single" ? `per`: `for`}{licence && ` ${licence.duration} ${licence.frequency}(s)`}</span>
                      </p>
    
                    </div>
                  </div>
                  <div className="flex justify-between mt-4 border-t border-gray-300 pt-4">
                    <Button
                      colour="alert"
                      label="Cancel"
                      onClick={() => {setIsOpen(false); setLicence({})}}
                      icon={XIcon}
                    />
    
                    <Button
                      colour="positive"
                      label="Purchase"
                      onClick={() => {
                        _api.organisations.addLicence(org._id, {licence: licence.value}).then(res => {
                          if(res.success) {
                            toast.success(res.message);
                            window.location = "/";
                          }else{
                            toast.error(res.message);
                          }
                        });
                      }}
                      icon={GiBasket}
                    />
                  </div>
                </div>
              </div>
            </Dialog>
          </Transition>
        );
      };

    return (<div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-5">
        {purchaseDialog()}
        {licenceTypes && licenceTypes.map((l, index) => (
          <div
            key={index}
            className={classNames(
              'ring-1 ring-gray-200',
              l.user !== "admin" && admin ? "opacity-30" : "",
              'rounded-3xl p-8',
            )}
          >
            <h3
              className={classNames(
                'text-gray-900',
                'text-lg font-semibold leading-8',
              )}
            >
              {l.name}
            </h3>
            <p className="mt-4 text-sm leading-6 text-gray-600">{l.text}</p>
            <p className="mt-6 flex items-baseline gap-x-1">
              <span className="text-4xl font-bold tracking-tight text-gray-900">£{l.price}</span>
              <span className="text-sm font-semibold leading-6 text-gray-600">{l.payment != "single" ? `per`: `for`}{` ${l.duration} ${l.frequency}(s)`}</span>
            </p>
            {buy ?
            <a
              onClick={() => {
                if(l.user == "admin"){
                  setIsOpen(true);
                  setLicence(l);
                }
              }}
              className={classNames(
                'text-primary ring-1 ring-inset ring-primary hover:bg-primary hover:text-white',
                l.user !== "admin" && admin ? "cursor-not-allowed" : "cursor-pointer",
                'mt-6 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
              )}
            >
              Buy license
            </a> : ""}
            <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                <li className="flex gap-x-3">
                  <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                    License for {l.seats} {l.user}s
                </li>
                <li className="flex gap-x-3">
                  <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                    Access to Pro Legal Flow
                </li>
                <li className="flex gap-x-3">
                  <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                    User Role
                </li>
                <li className="flex gap-x-3">
                  <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                    Postbox Authoriser Role
                </li>
                {l.user == "admin" && <li className="flex gap-x-3">
                  <GiCheckMark className="h-6 w-5 flex-none text-primary" aria-hidden="true" />
                    Admin Role
                </li>}
                
            </ul>
          </div>
        ))}</div>);
}