import React, { useState, useEffect } from "react";
import { GoInfo, GoLaw } from "react-icons/go";
import {
  UserIcon,
  OfficeBuildingIcon,
  ReceiptTaxIcon,
  LocationMarkerIcon,
} from "@heroicons/react/solid";

import { Button } from "../../../components/Forms";
export default function MatterBar({ matter, contact = {}, history, showEdit }) {
  return (
    <div className="mb-2 mx-auto px-4 md:flex md:items-center md:justify-between md:space-x-5 lg:px-4 border-b pb-2">
      <div className="flex items-center space-x-5">
        <div>
          <h1 className="text-2xl font-bold text-primary">
            {matter.display_number}
          </h1>
          <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
            <button
              className="mt-2 underline flex items-center text-sm text-gray-500 cursor-pointer hover:text-gray-800"
              onClick={() => history.push(`/contacts/${matter.contact._id}`)}
            >
              <UserIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {matter.contact ? matter.contact.name + (matter.contact.suffix ? " "+matter.contact.suffix : "") : "--"} 
            </button>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <LocationMarkerIcon
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {matter.location ? matter.location : "N/A"}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <GoInfo
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {matter.status ? matter.status : "N/A"}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <GoLaw
                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              {matter.responsible_attorney
                ? matter.responsible_attorney.name
                : "--"}
            </div>
          </div>
        </div>
      </div>
      {showEdit && (
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <Button
            onClick={() => history.push(`/matters/${matter._id}/edit`)}
            label="Edit Matter"
            colour="action"
          />
        </div>
      )}
    </div>
  );
}
