import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../../components/NotFound";
import FeesList from "./list";
import AddFee from "./Add";
import EditFee from "./Edit";
import AddPayment from "./AddPayment";
import FeePage from "./FeePage";

const routePrefix = "/matters/:matter_id/fees";

export default function FeePages() {
  return (
    <div>
      <Switch>
        <Route exact path={`${routePrefix}/payment`} component={AddPayment}/>
        <Route exact path={`${routePrefix}/add`} component={AddFee}/>
        <Route exact path={`${routePrefix}/:fee_id`} component={FeePage}/>
        <Route exact path={`${routePrefix}/:fee_id/edit`} component={EditFee} />
        <Route exact path={`${routePrefix}`} component={FeesList} />
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
}
