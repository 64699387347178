import {MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/solid";
import React, {useState} from "react";

const QtySelector = ({className = "", onChange, value, min = 0, max = 999}) => {

  return (
      <div className={`flex gap-2 ml-2 ${className}`}>
        <button onClick={() => value > min ? onChange(value - 1) : ""} className="rounded-lg text-center py-1 bg-blue-gray-600 hover:bg-blue-gray-700 focus:outline-none">
          <MinusCircleIcon className={"h-8"}/>
        </button>
        <input value={value} readOnly type="text" className="w-12 bg-white rounded-lg text-center shadow focus:outline-none focus:shadow-lg"/>
        <button onClick={() => onChange(value + 1)} className="rounded-lg text-center py-1  bg-blue-gray-600 hover:bg-blue-gray-700 focus:outline-none">
          <PlusCircleIcon className={"h-8"}/>
        </button>
      </div>
  )
}
export default QtySelector;