import { ApiRequest } from "../../plugins/clio_auth";
import AppConfig from "../../_appConfig";

export default class DocTemplates {
  async add(data) {
    return ApiRequest.post(
      `${AppConfig.api_server}/api/settings/doc_types/`,
      data
    );
  }

  async edit(id, data) {
    return ApiRequest.patch(
      `${AppConfig.api_server}/api/settings/doc_types/${id}`,
      data
    );
  }

  /*
  async remove(id) {
    return ApiRequest.delete(
      `${AppConfig.api_server}/api/settings/doc_types/${id}`
    );
  }*/

  async list(org, search) {
      let query = '';

      if (org) {
          query += `?org_id=${org}`;
      }

      if (search) {
          // If org is already in the query, use '&', otherwise start with '?'
          query += `${query ? '&' : '?'}search=${search}`;
      }

      return ApiRequest.get(
        `${AppConfig.api_server}/api/settings/doc_types/list${query}`
      );
  }
  async get(id) {
    return ApiRequest.get(
      `${AppConfig.api_server}/api/settings/doc_types/${id}`
    );
  }
}
