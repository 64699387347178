import React from "react";
import { Switch, Route } from "react-router-dom";
import NotFound from "../../../components/NotFound";
import Dash from "./dash";
import Navbar from "./Navbar";
import Texts from "./texts";
import Menus from "./menus";
import Buttons from "./buttons";

export default function AreaTasksSettings({history, match}) {
  const routePrefix = "/settings/whitelabeling";

  return (
    <>
      <Navbar history={history} match={{...match, url: "/settings"}} />
      <Switch>
        <Route exact path={`${routePrefix}/buttons`} component={Buttons} />
        <Route exact path={`${routePrefix}/menus`} component={Menus} />
        <Route exact path={`${routePrefix}/texts`} component={Texts} />
        <Route exact path={`${routePrefix}`} component={Dash} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
    
  );
}
