import React, { useState, useEffect } from "react";

import Authentication from "../plugins/clio_auth/authentication";
import GlobalSearch from "./widgets/global_search";

export default function Header({ history }) {
  const [user, setUser] = useState({});

  useEffect(() => {
    let data = Authentication.getUserData();
    setUser(data);
  }, []);

  const greeting = () => {
    const d = new Date();
    const time = d.getHours();
    let greeting = "Good Evening";
    if (time > 0 && time < 12) {
      greeting = "Good Morning";
    } else if (time > 12 && time < 18) {
      greeting = "Good Afternoon";
    }
    return greeting;
  };

  return (
    <div className="bg-menuPrimary px-4 border-b border-menuPrimary h-12 flex items-center justify-between text-menuPrimaryTitle text-xl tracking-wider ">
      <div>
        {greeting()}, {user && user.firstName} {user && user.lastName}
      </div>

      <div className="mt-6 ml-0 flex space-x-3 md:mt-0 md:ml-4">
        <GlobalSearch.HeaderBar history={history} />
      </div>
    </div>

    // <div className="bg-white dark:bg-gray-900 shadow">
    //   <div className="px-2">
    //     <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200 dark:border-gray-700">
    //       <div className="flex-1 min-w-0">
    //         <div className="flex items-center">
    //           <div>
    //             <div className="flex items-center">
    //               <h1 className="sm:ml-3 text-2xl font-bold leading-7 text-gray-900 dark:text-gray-200 sm:leading-9 sm:truncate">
    //                 {greeting()}, {user && user.firstName}{" "}
    //                 {user && user.lastName}
    //               </h1>
    //             </div>
    //             {/* <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
    //               <dt className="sr-only">Company</dt>
    //               <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
    //                 <OfficeBuildingIcon
    //                   className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
    //                   aria-hidden="true"
    //                 />
    //                 {user && user.company && user.company.name}
    //               </dd>
    //               <dt className="sr-only">email</dt>
    //               <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 ">
    //                 <MailIcon
    //                   className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
    //                   aria-hidden="true"
    //                 />
    //                 {user && user.email}
    //               </dd>
    //             </dl> */}
    //           </div>
    //         </div>
    //       </div>
    //       <div className="mt-6 ml-0 flex space-x-3 md:mt-0 md:ml-4">
    //         <GlobalSearch.HeaderBar history={history} />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
