import React, { Fragment, useState, useEffect } from "react";

import { NavLink, useParams } from "react-router-dom";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import MatterReceiver from "../../../contexts/matter_context/Matter_Receiver";
import ChainedContext from "../../../contexts/chainedContext";
import { PlusIcon } from "@heroicons/react/solid";
import API from "../../../api";
// function MatterNav({ matter, match }) {
//   const history = useHistory();

//   return (
//     <div>
//       <div className={`text-white bg-white shadow`}>
//         <div className="px-2">
//           <div className="py-6 md:flex md:items-center md:justify-between">
//             <div className="flex-1 min-w-0">
//               <div className="flex items-center">
//                 <div>
//                   <div className="flex items-center">
//                     <h1 className="text-black sm:ml-3 text-2xl font-bold leading-7 sm:leading-9 sm:truncate">
//                       View Matter {matter.display_number}
//                     </h1>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="mt-6 ml-0 flex space-x-3 md:mt-0 md:ml-4 md:mr-3">
//               <Button
//                 colour="green"
//                 icon={PencilIcon}
//                 label="Edit"
//                 onClick={() => {
//                   history.push(`${match.url}/edit`);
//                 }}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="bg-navBak">
//         <div className="border-b border-gray-300">
//           <div className="px-4">
//             <nav className="p-2 flex space-x-8" aria-label="Tabs">
//               <NavLink
//                 exact
//                 to={`${match.url}`}
//                 activeClassName="border-b-2  border-amber-500 text-gray-900"
//                 className={
//                   " text-gray-500 hover:text-gray-700 hover:border-amber-500 cursor-pointer whitespace-nowrap py-2 px-1  font-medium "
//                 }
//               >
//                 Dash
//               </NavLink>
//               <NavLink
//                 to={`${match.url}/debtors`}
//                 activeClassName="border-b-2  border-amber-500 text-gray-900"
//                 className={
//                   " text-gray-500 hover:text-gray-700 hover:border-amber-500 cursor-pointer whitespace-nowrap py-2 px-1 font-medium "
//                 }
//               >
//                 Debtors
//               </NavLink>
//               <NavLink
//                 to={`${match.url}/tasks`}
//                 activeClassName="border-b-2  border-amber-500 text-gray-900"
//                 className={
//                   " text-gray-500 hover:text-gray-700 hover:border-amber-500 cursor-pointer whitespace-nowrap py-2 px-1 font-medium "
//                 }
//               >
//                 Tasks
//               </NavLink>
//               {/* <NavLink
//                 to={`${match.url}/settings`}
//                 activeClassName="border-b-2  border-amber-500 text-gray-900"
//                 className={
//                   " text-gray-500 hover:text-gray-700 hover:border-amber-500 cursor-pointer whitespace-nowrap py-2 px-1 font-medium text-sm"
//                 }
//               >
//                 Settings
//               </NavLink> */}
//             </nav>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
function MatterNavbar({ matter, history, match }) {

  const params = useParams();

  const [categoryList, setCategoryList] = useState([]);
  const [categoriesLoaded, setCategoriesLoaded] = useState(false);

  useEffect(() => {
    if(!categoriesLoaded)
    API.settings
      .activity_categories()
      .list().then((res) => {
        setCategoryList(res);
        setCategoriesLoaded(true)
      })
  }, [categoryList]);

  if (match.params.matter_id === "add") {
    return <></>;
  }

  const solutions = [
    {
      name: "Dash",
      description: "Air Log",
      href: `${match.url}`,
      exact: true,
    },
    // {
    //   name: "Edit",
    //   description: "Edit matter",
    //   href: `${match.url}/edit`,
    //   exact: true,
    // },
    {
      name: "Debtors",
      description: "debtors",
      href: `${match.url}/debtors`,
      exact: true,
    },
    {
      name: "Debts",
      description: "debts",
      href: `${match.url}/debts`,
      exact: true,
    },
    {
      name: "Fees",
      description: "fees",
      href: `${match.url}/fees`,
      exact: true,
    },
    {
      name: "History",
      description: "history",
      href: `${match.url}/activities`,
      exact: false,
    },
    {
      name: "Tasks",
      description: "tasks",
      href: `${match.url}/tasks`,
      exact: false,
    },
    {
      name: "Shortcodes",
      description: "shortcodes",
      href: `${match.url}/shortcodes`,
      exact: false,
    },
    /*
    {
      name: "Workflow",
      description: "workflow",
      href: `${match.url}/workflow`,
      exact: false,
    }, */
  ];

  return (
    <Disclosure as="nav" className="bg-menuPrimary sticky-item-sub-nav">
      {({ open }) => (
        <>
          <div className="mx-auto px-2 border-b border-menuPrimary">
            <div className="flex items-center justify-between h-12">
              <div className="flex items-center">
                <div className="hidden md:block">
                  <div className=" flex items-center justify-center text-center space-x-4">
                    <div
                      // onClick={() =>
                      //   history.push(`/matters/${matter._id}/debtors`)
                      // }
                      className="hidden cursor-pointer font-medium lg:flex gap-2 antialiased text-md px-2 py-2 justify-center items-center text-menuPrimaryTitle tracking-wider"
                    >
                      <span>Matter Menu {matter.display_number}</span>
                    </div>
                    {solutions
                      .filter((s) => {
                        return s.hide !== true;
                      })
                      .map((item, itemIdx) => (
                        // itemIdx === 0 ? (
                        <Fragment key={itemIdx}>
                          {item.external ? (
                            <a
                              href={item.href}
                              target="_blank"
                              rel="noreferrer"
                              alt={item.description}
                            >
                              {item.name}
                            </a>
                          ) : (
                            <NavLink
                              exact={item.exact}
                              to={item.href}
                              activeClassName="bg-menuActive text-menuPrimaryText underline"
                              className="px-2 py-2 text-menuPrimaryText text-lg hover:bg-menuHover hover:text-menuHoverText tracking-wider"
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </NavLink>
                          )}
                        </Fragment>
                      ))}
                      <span className="isolate absolute right-0 inline-flex rounded-md shadow-sm">
                      <button
                            type="button"
                            className={"has-tooltip relative inline-flex items-center rounded-l-md border border-activityHover bg-activity px-4 py-2 text-sm font-medium text-activityText hover:bg-activityHover hover:text-activityTextHover focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-activityHover"}
                            onClick={() => {
                                // setTimeModal(true);
                              } 
                            }
                          >
                            <div
                            className="h-7 w-7"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                            </div>
                            <span class="tooltip text-black rounded shadow-lg p-1 bg-gray-100 -mb-20">
                              Time Log
                            </span>
                          </button>
                        {categoryList && categoryList.filter(e => e.menu_show == true).map((cat, index) => {

                          let 
                            className= "has-tooltip relative -ml-px inline-flex items-center border border-activityHover bg-activity px-4 py-2 text-sm font-medium text-activityText hover:bg-activityHover focus:z-10 focus:border-activityHover focus:outline-none focus:ring-1 focus:ring-activityHover hover:text-activityTextHover";

                          return <button
                            type="button"
                            className={className}
                            onClick={() =>
                              history.push(`/matters/${params.matter_id}/activities/add/${cat._id}`)
                            }
                          >
                            <div
                            className="h-7 w-7"
                            dangerouslySetInnerHTML={{ __html: cat.icon }}
                            ></div>
                            <span class="tooltip text-black rounded shadow-lg p-1 bg-gray-100 -mb-20">
                              {cat.name}
                            </span>
                          </button>;
                        })}
                        <Menu
                          as="div"
                          className="relative inline-block text-left w-full"
                        >
                          <Menu.Button className="h-12 relative -ml-px inline-flex items-center rounded-r-md border border-activityHover bg-activity px-4 py-2 text-sm font-medium text-activityText hover:bg-activityHover hover:text-activityTextHover focus:z-10 focus:border-activityHover focus:outline-none focus:ring-1 focus:ring-activityHover">
                            <PlusIcon
                              className="-ml-1 mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            Create
                          </Menu.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 top-10 z-50 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                {categoryList && categoryList.filter(e => !e.menu_show || e.menu_show == false).map((cat, index) => {
                                  return <Menu.Item>
                                    <div
                                      onClick={() => history.push(`/matters/${params.matter_id}/activities/add/${cat._id}`)}
                                      className={
                                        "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                      }
                                    > <div
                                        className="h-5 w-5 inline-block"
                                        dangerouslySetInnerHTML={{ __html: cat.icon }}
                                        ></div>
                                      {" "}{cat.name}
                                    </div>
                                  </Menu.Item>
                                  }
                                )}
                                <Menu.Item>
                                  <div
                                    onClick={() => history.push(`/matters/${params.matter_id}/activities/add`)}
                                    className={
                                      "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                                    }
                                  >
                                    <PlusIcon
                                      className="h-5 w-5 inline-block"
                                      aria-hidden="true"
                                    />
                                    New Activity
                                  </div>
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </span>
                  </div>
                </div>
              </div>
              <div className="hidden sm:flex gap-2 antialiased text-sm ml-auto px-2 py-2 justify-center items-center text-gray-400 tracking-wide">
                {matter.name}{" "}
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="bg-gray-200 inline-flex items-center justify-between p-2 rounded-md text-primary hover:text-white hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2  focus:ring-white">
                  <div className=" flex gap-2">
                    <span className="text-black">Matter: {matter.display_number}</span>
                  </div>
                  <span className="sr-only text-black">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6 text-black" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon
                      className="block h-6 w-6 text-black"
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {solutions
                .filter((s) => {
                  return s.hide !== true;
                })
                .map((item, itemIdx) => (
                  <Fragment key={itemIdx}>
                    <NavLink
                      exact
                      to={item.href}
                      activeClassName="bg-gray-50 "
                      className="text-black hover:bg-gray-300  block px-3 py-2 rounded-md text-base font-medium"
                      aria-current={item.current ? "page" : undefined}
                    >
                      {item.name}
                    </NavLink>
                  </Fragment>
                ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
export default ChainedContext(MatterNavbar, [
  [
    MatterReceiver,
    (matter) => {
      return { matter };
    },
  ],
]);
