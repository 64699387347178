import React, { Fragment, useEffect, useState, useCallback } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import Segment from "../../../components/Segment";
import { Dialog, Menu, Transition } from "@headlessui/react";
import DatePicker from "react-datepicker";
import Loader from "../../../components/Loader";
import dayjs from "dayjs";
import {
  CheckIcon,
  PencilIcon,
  XIcon,
  ClockIcon,
  PaperClipIcon,
  DocumentSearchIcon, DocumentTextIcon,
  ArrowDownIcon, DocumentIcon
} from "@heroicons/react/solid";
import DataField from "../../../components/DataField";
import _appConfig from "../../../_appConfig";
import HtmlEditor from "../../../components/widgets/HtmlEditor";
import { Button, Input } from "../../../components/Forms";

export default function View() {
  const history = useHistory();
  const params = useParams();
  const match = useRouteMatch();

  const [data, setData] = useState({});

  const [mountLoading, setMountLoading] = useState(true);

  const [documentTemplates, setDocumentTemplates] = useState([]);
  const [documentTemplatesTypes, setDocumentTemplatesTypes] = useState([]);
  const [documentTemplatesType, setDocumentTemplatesType] = useState(null);
  const [documentTemplatesTypeParent, setDocumentTemplatesTypeParent] = useState(null);
  const [documentAreaType, setDocumentAreaType] = useState(null);
  const [documentContent, setDocumentContent] = useState("");
  const [documentHeader, setDocumentHeader] = useState("");
  const [dateVerbose, setDateVerbose] = useState("");
  const [documentFooter, setDocumentFooter] = useState("");
  const [openDocumentModal, setOpenDocumentModal] = useState(null);
  const [generatingDocument, setGeneratingDocument] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  const headerTemplates = useCallback(() => {
    return API.settings.letter_headers().list();
  }, []);

  const footerTemplates = useCallback(() => {
    return API.settings.letter_headers().list(); // TODO bring back just the templates for foorer
  }, []);

  useEffect(() => {
    if (mountLoading) {

      API.settings.doc_types().list().then((types) => {
        setDocumentTemplatesTypes(types);
        API.settings.doc_templates().list().then((res) => {
          setDocumentTemplates(res);
        });
      }) 

      API.matters.activities
        .get(params.matter_id, params.activity_id)
        .then((res) => {
          setData(res);
          setMountLoading(false);
        })
        .catch((e) => CommonFunctions.handleError(e));
    }
  }, [mountLoading, params]);

  const handleSaveData = (data, field) => {
    setData(a => {
      return {
        ...a,
        [field]: data
      }
    })
  }

  const previewContent = () => {

    let html = documentHeader + "<hr/>";
    html += documentContent + "<hr/>";
    html += documentFooter + "<hr/>";

    return html;

  }

  const GeneratingDocumentModal = () => { 
    return(
      <Transition.Root show={generatingDocument} as={Fragment}>
      <Dialog as="div" className="relative z-90" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-50">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="mt-3 text-center w-full ">
                      <Dialog.Title as="h1" className="text-lg font-medium text-gray-900 text-center ">
                        Generating Document
                      </Dialog.Title>
                      <div className="mt-5">
                        <h1><Loader/></h1>
                        <p className="text-lg font-medium text-gray-900 text-center">
                          Please wait whilst the document is being generated.
                        </p>
                      </div>
                    </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    );
  }

  const documentModal = () => { 

    let template = documentTemplates.filter(e => e._id === openDocumentModal);

    return(
      <Transition.Root show={openDocumentModal != null && !generatingDocument} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpenDocumentModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full mr-10 ml-10">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary sm:mx-0 sm:h-10 sm:w-10">
                      <DocumentIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full ">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        {template[0] && (template[0].document_type == "simple" ? "Document: " : "Form: ")}{template[0] && template[0].name}
                      </Dialog.Title>
                      <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                        <p className={`text-sm text-gray-500 ${template[0] && (template[0].document_type == "simple" ? "sm:col-span-2" : "sm:col-span-3")}`}>
                          {template[0] && template[0].document_type != "form" &&
                          <>
                          <label for="dateVerbose"><strong>Date:</strong></label>
                          <DatePicker
                            name="dateVerbose"
                            onChange={(date) => 
                              setDateVerbose(date)
                            }
                            label="Date"
                            selected={dateVerbose ? new Date(dateVerbose) : ""}
                            showPopperArrow={false}
                            shouldCloseOnSelect={true}
                            dateFormat="dd-MM-yy"
                            className={`relative w-full  p-2 rounded-lg bg-gray-100 border border-gray-300`}
                          />
                          <h1 className="border-solid border-2 border-grey-600 p-2 rounded text-med font-medium leading-6 text-gray-900 mt-5 mb-2 cursor-pointer" onClick={() => setShowHeader(!showHeader)}>
                            Header: <ArrowDownIcon className={`w-4 inline ${showHeader ? "" : "rotate-180"}`} />
                            <span className="block text-xs text-slate-400">Click to {showHeader ? "Hide" : "Show"}</span>
                          </h1>
                            
                            {showHeader &&

                              <HtmlEditor
                                content={documentHeader}
                                templates={headerTemplates}
                                options={{
                                  height: 400,
                                }}
                                onContentUpdate={(d) => setDocumentHeader(d)}
                              />
                            }

                            <h1 className="border-solid border-2 border-grey-600 p-2 rounded text-med font-medium leading-6 text-gray-900 mt-5 mb-2">
                              Content:
                            </h1>

                            <HtmlEditor
                              content={documentContent}
                              options={{
                                height: 400,
                              }}
                              onContentUpdate={(d) => setDocumentContent(d)}
                            />

                            <h1 className="border-solid border-2 border-grey-600 p-2 rounded text-med font-medium leading-6 text-gray-900 mt-5 mb-2 cursor-pointer" onClick={() => setShowFooter(!showFooter)}>
                              Footer: <ArrowDownIcon className={`w-4 inline ${showFooter ? "" : "rotate-180"}`} />
                              <span className="block text-xs text-slate-400">Click to {showFooter ? "Hide" : "Show"}</span>
                            </h1>

                            {showFooter &&
                              <HtmlEditor
                                content={documentFooter}
                                templates={footerTemplates}
                                options={{
                                  height: 400,
                                }}
                                onContentUpdate={(d) => setDocumentFooter(d)}
                              />

                            }

                          </>
                            
                          }

                          {template[0] && template[0].document_type == "form" &&
                          
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-50">
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                  Field Type
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                  Field Name
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Content
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {template[0].document_fields.length ? (
                                template[0].document_fields.filter(f => f.type === "PDFTextField" || f.type === "PDFCheckBox").map((field, index) => {
          
                                  let fields = template[0].document_fields_map;
                                  let map = fields.find(f => f.field == field.name);

                                  return(
                                  <tr key={"field_" + index}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                      {field.type}
                                    </td>
                                    <td className="px-3 py-4 text-sm text-gray-500">
                                      {field.name}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 w-[700px]">
                                      {field.type == "PDFTextField" &&
                                        <Input 
                                          value={map?.content || ""}
                                          onChange={(d) => {
                                            map.content = d.target.value;
                                            handleSaveData(fields.map(a => {
                                              if(a.field !== field.name) return a;
                                              return {
                                                ...a,
                                                content: d.target.value 
                                              }
                                            }), "document_fields_map")}
                                          }
                                        
                                        />}

                                        {field.type == "PDFCheckBox" &&
                                        <Input 
                                          type="checkbox"
                                          checked={map?.content || false}
                                          onChange={(d) => {
                                            map.content = d.target.checked;
                                            handleSaveData(fields.map(a => {
                                              if(a.field !== field.name) return a;
                                              return {
                                                ...a,
                                                content: d.target.checked
                                              }
                                            }), "document_fields_map")}
                                          }
                                        /> }
                                      
                                      
                                    </td>
                                  </tr>);
                                            
                                }
                                )
                              ) : (
                                <tr>
                                  <td
                                    colSpan={3}
                                    className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                                  >
                                    There are no fields to display.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>

                          }

                          {/* <iframe width="100%" height="500px" src={_appConfig.api_server + openDocumentFile} /> */}
                          {/* <DocViewer  pluginRenderers={DocViewerRenderers} documents={{uri: _appConfig.api_server + openDocumentFile}} /> */}
                        </p>  

                        {template[0] && template[0].document_type != "form" &&
                          <p className="text-sm text-gray-500 sm:col-span-1">
                            <h1 className="text-med font-medium leading-6 text-gray-900 mb-2">Email Preview:</h1>
                            <div style={{maxHeight: "550px"}} className="mt-5 overflow-y-scroll sun-editor-editable" dangerouslySetInnerHTML={{__html: previewContent()}}/>
                          </p>}

                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {/*  
                  <button
                    
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpenDocumentModal(null)}
                  >
                    Email Document
                  </button>*/}
                  <button
                    disabled={generatingDocument}
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setGeneratingDocument(true);
                      API.matters.activities.documentGenerate(params.matter_id, params.activity_id, {
                        template:openDocumentModal,
                        document_fields_map: data.document_fields_map,
                        content: documentContent,
                        header_content: documentHeader,
                        footer_content: '<div style="width: 100%;">'+ documentFooter + "</div>",
                        date_verbose: dateVerbose
                      }).then((res) => {
                        setGeneratingDocument(false);
                        if(res.success) {
                          setOpenDocumentModal(null);
                          window.open(
                            _appConfig.api_server + res.file,
                            "_blank",
                            "noopener"
                          );
                          setMountLoading(true);
                          // API.matters.activities.update(params.matter_id, params.activity_id, {document: res.file_id}).then(() => { setMountLoading(true); });
                          /*
                          API.matters.tasks
                          .updateStatus(params.matter_id, params.task_id, {document: res.file_id})
                          .then(() => setMountLoading(true))
                          .catch((e) => CommonFunctions.handleError(e))*/
                        }
                      })
                    }}
                  >
                    {generatingDocument ? <>
                      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Processing...
                    </> : "Generate Document."}
                    
                  </button>
                  {/*  
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setOpenDocumentModal(false)
                      window.open(
                        _appConfig.api_server + openDocumentFile,
                        "_blank",
                        "noopener"
                      )
                    }}
                  >
                    Download Document
                  </button>*/}
                  <Button
                    label="Cancel"
                    colour="alert"
                    onClick={() => setOpenDocumentModal(null)}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    );
  }

  // let documentTemplatesFiltered = documentTemplates.filter(e => e.recipient && e.recipient.name == documentTemplatesType);
  let documentTemplatesFiltered = documentTemplates.filter(e => 
    e.recipient && 
    Array.isArray(e.recipient) &&
    (e.recipient.some(recipient => recipient.label === documentTemplatesType) || e.recipient.some(recipient => recipient === documentTemplatesType)) &&
    !e.archived &&
    !e.global_template
  );

  let globalTemplates = documentTemplates.filter(e => 
    e.global_template
  );

  return (
    <Segment>
      {GeneratingDocumentModal()}
        {documentModal()}
      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
            <h3 className="leading-6 font-medium text-xl text-gray-900 flex justify-between">
              <span className="my-auto">View Activity.</span>
              <span className="isolate inline-flex rounded-md shadow-sm">
              <>
              <div className="mb-4 flex px-3">
                <Menu
                  as="div"
                  className="relative inline-block text-left w-full"
                >
                  <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                    <DocumentSearchIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    Find Document 
                  </Menu.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          <div
                            onClick={() => setDocumentAreaType("law")}
                            className={
                              "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                            }
                          >
                            Area of Law Templates
                          </div>
                        </Menu.Item>
                        <Menu.Item>
                          <div
                            onClick={() => setDocumentAreaType("global")}
                            className={
                              "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                            }
                          >
                            Global Templates
                          </div>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>

              {documentAreaType === "law" && 

              <div className="mb-4 flex px-3">
                  <Menu
                    as="div"
                    className="relative inline-block text-left w-full"
                  >
                    <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                      <DocumentSearchIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Area of Law
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {documentTemplatesTypes.filter(e => e.archived != true).map((template) => {
                            return <Menu.Item>
                            <div
                              onClick={() => setDocumentTemplatesTypeParent(template)}
                              className={
                                "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                              }
                            >
                              {template.name} ({template.shortcode})
                            </div>
                          </Menu.Item>
                          })}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>}

                {documentAreaType === "law" && documentTemplatesTypeParent && <div className="mb-4 flex px-3">
                  <Menu
                    as="div"
                    className="relative inline-block text-left w-full"
                  >
                    <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                      <DocumentSearchIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      {documentTemplatesTypeParent.name}
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            <div
                              onClick={() => {
                                setDocumentTemplatesType(documentTemplatesTypeParent.name) }}
                              className={
                                "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                              }
                            >
                              {documentTemplatesTypeParent.name} ({documentTemplatesTypeParent.shortcode})
                            </div>
                          {documentTemplatesTypes.filter(e => e.archived != true && e.parent && e.parent._id == documentTemplatesTypeParent._id ).map((template) => {
                            return <Menu.Item>
                            <div
                              onClick={() => {
                                setDocumentTemplatesType(template.name) }}
                              className={
                                "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                              }
                            >
                              {template.name} ({template.shortcode})
                            </div>
                          </Menu.Item>
                          })}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>}

              {documentTemplatesType && documentAreaType === "law" ?
                <div className="mb-4 flex px-3">
                  <Menu
                    as="div"
                    className="relative inline-block text-left w-full"
                  >
                    <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                      <DocumentTextIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      {documentTemplatesType} Template:
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {documentTemplatesFiltered.map((template) => {
                            return <Menu.Item>
                            <div
                              onClick={() => {
                                setOpenDocumentModal(template._id)
                                setDocumentContent(template.content);
                                setDocumentHeader(template.header_content);
                                setDocumentFooter(template.footer_content);
                                setData({
                                  ...data,
                                  document_fields_map: template.document_fields
                                })
                                /*
                              API.matters.tasks
                                .updateStatus(params.matter_id, params.task_id, {sendEmailTemplate: template.code})
                                .then(() => setMountLoading(true))
                              .catch((e) => CommonFunctions.handleError(e))}*/ }}
                              className={
                                "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                              }
                            >
                              {template.name}
                            </div>
                          </Menu.Item>
                          })}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div> : documentAreaType === "global" &&
                <div className="mb-4 flex px-3">
                  <Menu
                    as="div"
                    className="relative inline-block text-left w-full"
                  >
                    <Menu.Button className="flex w-full justify-center rounded-md border border-gray-300 hover:bg-light_gray bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50  ">
                      <DocumentTextIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Global Template:
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 top-10 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {globalTemplates.map((template) => {
                            return <Menu.Item>
                            <div
                              onClick={() => {
                                setOpenDocumentModal(template._id)
                                setDocumentContent(template.content);
                                setDocumentHeader(template.header_content);
                                setDocumentFooter(template.footer_content);
                                setData({
                                  ...data,
                                  document_fields_map: template.document_fields
                                })
                                /*
                              API.matters.tasks
                                .updateStatus(params.matter_id, params.task_id, {sendEmailTemplate: template.code})
                                .then(() => setMountLoading(true))
                              .catch((e) => CommonFunctions.handleError(e))}*/ }}
                              className={
                                "text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                              }
                            >
                              {template.name}
                            </div>
                          </Menu.Item>
                          })}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div> }
                </>
                <div className="mb-4 flex px-3">
                  <Button
                    onClick={() => history.push(`${match.url}/edit`)}
                    colour="warning"
                    icon={PencilIcon}
                    label="Edit"
                  />
                </div>
                <div className="mb-4 flex px-3">
                  <Button
                    onClick={() =>
                      API.matters.activities
                        .updateStatus(params.matter_id, params.activity_id)
                        .then(() => setMountLoading(true))
                        .catch((e) => CommonFunctions.handleError(e))
                    }
                    colour={data.complete ? "alert" : "positive"}
                    icon={data.complete ? XIcon : CheckIcon}
                    label={data.complete ? "Mark Incomplete" : "Mark Complete"}
                  />
                </div>
              </span>
            </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Matter Activity, created:{" "}
            <ClockIcon className="w-3 h-3 inline-block" />
            {dayjs(data.createdAt).format("DD/MM/YYYY HH:mm:ss")}
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Fee Earner</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.assigned_name}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Due Date</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.due_date
                  ? dayjs(data.due_date).format("DD/MM/YYYY")
                  : "No Due Date"}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {`${data.complete ? "Complete" : "Incomplete"} ${
                  data.complete
                    ? `(${
                        data.complete_date
                          ? dayjs(data.complete_date).format("DD/MM/YYYY")
                          : ""
                      })`
                    : ""
                }`}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Category</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.category && (
                  <>
                    <div
                      className="h-5 w-5 mx-auto inline-block"
                      dangerouslySetInnerHTML={{
                        __html: data.category.icon,
                      }}
                    ></div>{" "}
                    {data.category.name}
                  </>
                )}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Time</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {`${data.time} ${data.time === 1 ? "Minute" : "Minutes"} ${
                  data.isBillable ? "(Billable)" : "(Non-billable)"
                }`}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Cost</dt>
              <dd className="mt-1 text-sm text-gray-900">{`
                    £${(
                      (data.time ? data.time : 0) *
                      (data.currentRate ? data.currentRate / 60 : 0)
                    ).toFixed(2)}`}</dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900">{data.description}</dd>
            </div>
            {data.files &&
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Files</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                    <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200">
                      {data.files.map((file) => {
                        return <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                        <div className="flex w-0 flex-1 items-center">
                          <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                          <span className="ml-2 w-0 flex-1 truncate">{file.filename}</span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500" onClick={() => {
                            
                             API.settings
                             .getFileLink(
                                file._id
                             )
                             .then((res) => {
                               window.open(
                                 _appConfig.api_server + res,
                                 "_blank"
                               );
                             }); 
                          }}>
                            View
                          </a>
                        </div>
                      </li>
                      })}
                      
                    </ul>
                     
                    </dd>
                  </div> }
          </dl>
        </div>
      </div>
    </Segment>
  );
}
