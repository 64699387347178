import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import API from "../../../api";
import CommonFunctions from "../../../CommonFunctions";
import Segment from "../../../components/Segment";
import { Button } from "../../../components/Forms";
import Loader from "../../../components/Loader";
import dayjs from "dayjs";
import { CheckIcon, PencilIcon, XIcon } from "@heroicons/react/solid";
import DataField from "../../../components/DataField";

export default function View() {
  const history = useHistory();
  const params = useParams();
  const match = useRouteMatch();

  const [data, setData] = useState({});

  const [mountLoading, setMountLoading] = useState(true);

  useEffect(() => {
    if (mountLoading) {
      API.prospective_contact.activities
        .get(params.contact_id, params.activity_id)
        .then((res) => {
          setData(res);
          setMountLoading(false);
        })
        .catch((e) => CommonFunctions.handleError(e));
    }
  }, [mountLoading, params]);

  return (
    <div className="mt-4">
      <Segment>
        <div className="">
          <h3 className="leading-6 font-medium text-xl text-gray-900 flex justify-between">
            <span className="my-auto">View Activity</span>
            <Button
              colour={data.complete ? "alert" : "positive"}
              label={data.complete ? "Mark Incomplete" : "Mark Completed"}
              onClick={() =>
                API.prospective_contact.activities
                  .updateStatus(params.contact_id, params.activity_id)
                  // .then(() => setMountLoading(true))
                  .then(() => history.push(`/prospective-contacts/${params.matter_id}/activities`))
                  .catch((e) => CommonFunctions.handleError(e))
              }
              className="ml-auto text-lg"
              icon={data.complete ? XIcon : CheckIcon}
            />
            <Button
              colour="action"
              label="Edit"
              onClick={() => history.push(`${match.url}/edit`)}
              className="ml-4 text-lg"
              icon={PencilIcon}
            />
          </h3>
          <div className="w-full border-t border-gray-300 my-2" />
          {mountLoading ? (
            <Loader />
          ) : (
            <>
              <div className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Details</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.description}</dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Fee Earner</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{data.assigned_name}</dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Due Date</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{dayjs(data.due_date).format("DD/MM/YYYY")}</dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Status</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{`${data.complete ? "Complete" : "Incomplete"} ${
                    data.complete
                      ? `(${
                          data.complete_date
                            ? dayjs(data.complete_date).format("DD/MM/YYYY")
                            : ""
                        })`
                      : ""
                  }`}</dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Time</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{`${data.time} ${
                    data.time === 1 ? "Minute" : "Minutes"
                  } ${data.isBillable ? "(Billable)" : "(Non-billable)"}`}</dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">Cost</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {`
                    £${(
                      (data.time ? data.time : 0) *
                      (data.currentRate ? data.currentRate / 60 : 0)
                    ).toFixed(2)}`}
                    </dd>
                  </div>
                </dl>
              </div>
              </div>
            </>
          )}
        </div>
      </Segment>
    </div>
  );
}
